import "../../styles/DeviceRegistration/DeviceRegistration.css";
import "../../styles/DeviceRegistration/DeviceInfo.css";
import "../../styles/General.css";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { FORM_REQUIRED, BACKEND_URL, INDONESIAN_LOCALE } from "../../config";
import { fetchHelper } from "../../lib/fetch";
import dayjs from "dayjs";

import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Space,
  DatePicker,
  Button,
  message,
  Skeleton,
  Tooltip,
} from "antd";

const { Option } = Select;

export function DeviceInfo(props) {
  const {
    form,
    refreshForm,
    onFinish,
    savedDeviceName, // savedDeviceName is the saved draft device name in db
    setSavedDeviceName,
    deviceId, // id of the savedDeviceName
    setDeviceId,
    countryCode,
    setCountryCode,
    deviceFuelSelected,
    setDeviceFuelSelected,
    deviceTechnologyList,
    setDeviceTechnologyList,
    allDeviceTechnologyList,
    setAllDeviceTechnologyList,
    deviceInfoDraftSaved,
    setDeviceInfoDraftSaved,
    commissioningDate,
    setCommissioningDate,
    lastRecContractDate,
    setLastRecContractDate,
  } = props;

  const language = localStorage.getItem("language");

  // username of user currently logged in, to be passed to backend to retrieve organisation user id
  const currentUser = JSON.parse(sessionStorage.getItem("loginData"))?.username;

  const [deviceFuelList, setDeviceFuelList] = useState([]); // device fuel list retrieved from database

  const [deviceOwnershipList, setDeviceOwnershipList] = useState([]); // device ownership list retrieved from database
  const [deviceOwnershipSelection, setDeviceOwnershipSelection] = useState(); // device ownership selected

  // effective registration date for REC - calculated using commissioning date and Last Issuance Date
  const [effectiveRegistrationDate, setEffectiveRegistrationDate] =
    useState(null);

  // this is used to check whether the company is the device owner
  const [isDeviceOwner, setIsDeviceOwner] = useState(true);

  // this is used to check whether the user is the captive consumer or not
  const [isCaptiveConsumer, setIsCaptiveConsumer] = useState(false);

  // this country list is used for country selection
  const [countryList, setCountryList] = useState([]);

  // this state list is used for country selection
  const [stateList, setStateList] = useState([]);

  const [loading, setLoading] = useState(true);

  // capacity unit list for solar device fuel
  let capacityUnitSolarList = [
    {
      label: "kWac",
      value: "kWac",
    },
    {
      label: "MWac",
      value: "MWac",
    },
  ];

  // capacity unit list for other device fuel
  let capacityUnitOtherList = [
    {
      label: "kW",
      value: "kW",
    },
    {
      label: "MW",
      value: "MW",
    },
  ];

  // initial capacity unit list
  const [capacityUnitList, setCapacityUnitList] = useState([
    {
      label: "kW",
      value: "kW",
    },
    {
      label: "MW",
      value: "MW",
    },
  ]);

  // country api from online source
  var config = {
    cUrl: "https://api.countrystatecity.in/v1/countries",
    ckey: "M1dwVXhSTlh3QmQycFBkQXp0S053NFdLSnhZWThUeDV6dUhsQm9VYQ==",
  };

  const loadCountries = async () => {
    setLoading(true);
    try {
      const response = await fetch(config.cUrl, {
        headers: {
          "X-CSCAPI-KEY": config.ckey,
        },
      });
      const data = await response.json();
      setCountryList(data);
      setLoading(false);
    } catch (error) {
      console.error("Error loading countries:", error);
    }
  };

  const loadStates = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${config.cUrl}/${countryCode}/states`, {
        headers: {
          "X-CSCAPI-KEY": config.ckey,
        },
      });
      const data = await response.json();
      setStateList(data);
      setLoading(false);
    } catch (error) {
      console.error("Error loading states:", error);
    }
  };

  /** This function is to get device fuel from backend and set device fuel list */
  function getDeviceFuelSelection() {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        language: language,
      }),
    };

    const cb = (data) => {
      setDeviceFuelList(data.deviceFuelData);
    };

    fetchHelper(
      BACKEND_URL + "/getDeviceFuelSelection",
      cb,
      setLoading,
      "Get Device Fuel Selection",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** This function is to get device technology from backend and set device technology list */
  function getDeviceTechnologySelection() {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        language: language,
      }),
    };

    const cb = (data) => {
      setAllDeviceTechnologyList(data.deviceTechnologyData);
    };

    fetchHelper(
      BACKEND_URL + "/getDeviceTechnologySelection",
      cb,
      setLoading,
      "Get Device Technology Selection",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** This function is to get device ownership from backend and set device ownership list */
  function getDeviceOwnershipSelection() {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        language: language,
      }),
    };

    const cb = (data) => {
      setDeviceOwnershipList(data.deviceOwnershipData);
      setDeviceOwnershipSelection();
    };

    fetchHelper(
      BACKEND_URL + "/getDeviceOwnershipSelection",
      cb,
      setLoading,
      "Get Device Ownership Selection",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** This function is to get effective registration date from backend and set effective registration date */
  function getEffectiveRegistrationDate() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        CommissioningDate: form.getFieldValue("Commissioning Date")
          ? form.getFieldValue("Commissioning Date").format("YYYY-MM-DD")
          : "",
        LastRecContractDate: form.getFieldValue("Last Issuance Date")
          ? form.getFieldValue("Last Issuance Date").format("YYYY-MM-DD")
          : "",
      }),
    };

    const cb = (data) => {
      setEffectiveRegistrationDate(data.effectiveDate);
      form.setFieldValue("Effective Registration Date", data.effectiveDate); // this form item is hidden and used to pass the date to supporting documents page so that effective registration date can be inserted in template
    };

    fetchHelper(
      BACKEND_URL + "/getEffectiveRegistrationDate",
      cb,
      () => {},
      "Get Effective Registration Date",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** This function is to generate device code from backend and set device code */
  function generateDeviceCode() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        device_name: form.getFieldValue("Device Name") // if form.getFieldValue("Device Name") is truthy (i.e. not undefined, null, 0, false, or an empty string), pass the value to backend; otherwise, pass an empty string
          ? form.getFieldValue("Device Name")
          : "",
        device_capacity_value: form.getFieldValue("Capacity")["Capacity Value"]
          ? form.getFieldValue("Capacity")["Capacity Value"]
          : "",
        device_capacity_unit: form.getFieldValue("Capacity")["Capacity Unit"]
          ? form.getFieldValue("Capacity")["Capacity Unit"]
          : "",
        current_user: currentUser,
      }),
    };

    const cb = (data) => {
      form.setFieldValue("Device Code", data.device_code);
    };

    fetchHelper(
      BACKEND_URL + "/generateDeviceCode",
      cb,
      () => {},
      "Generate Device Code",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  // check whether the input device name is unique or not (i.e., not in database)
  function checkDeviceNameExists(value) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ DeviceName: value }),
    };

    const cb = (data) => {
      if (!data.is_unique) {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Nama perangkat sudah ada. Silakan gunakan nama perangkat lain."
            : "Device name already exists. Please use another device name."
        );
      } else {
        updateDeviceName();
      }
    };

    // only check whether device name is unique when the input value does not same as the saved draft device name
    if (value !== undefined) {
      if (savedDeviceName !== value) {
        fetchHelper(
          BACKEND_URL + "/checkUniqueDeviceName",
          cb,
          () => {},
          "Check Unique Device Name",
          () => {},
          () => {},
          requestOptions,
          true
        );
      } else {
        checkDeviceNameEmpty();
      }
    } else {
      message.error(
        language === INDONESIAN_LOCALE
          ? "Nama perangkat harus diisi"
          : "You must make sure device name is not empty in order to save your draft"
      );
    }
  }

  /** This function is to check whether device name contains value of not.
   * If it does not, show error.
   * If it does, call saveDraft function to save draft in database.
   */
  function checkDeviceNameEmpty() {
    if (
      form.getFieldValue("Device Name") === undefined ||
      form.getFieldValue("Device Name") === ""
    ) {
      message.error(
        language === INDONESIAN_LOCALE
          ? "Nama perangkat harus diisi"
          : "You must make sure device name is not empty in order to save your draft"
      );
    } else {
      saveDraft();
    }
  }

  /** This function is to save draft in database */
  function saveDraft() {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        device_name: form.getFieldValue("Device Name") // if form.getFieldValue("Device Name") is truthy (i.e. not undefined, null, 0, false, or an empty string), pass the value to backend; otherwise, pass an empty string
          ? form.getFieldValue("Device Name")
          : "",
        device_address: form.getFieldValue("Device Address")
          ? form.getFieldValue("Device Address")
          : "",
        city: form.getFieldValue("City") ? form.getFieldValue("City") : "",
        postcode: form.getFieldValue("Postcode")
          ? form.getFieldValue("Postcode")
          : "",
        state: form.getFieldValue("State") ? form.getFieldValue("State") : "",
        country: form.getFieldValue("Country")
          ? form.getFieldValue("Country")
          : "",
        device_location_longitude: form.getFieldValue(
          "Device Location Longitude"
        )
          ? form.getFieldValue("Device Location Longitude")
          : "",
        device_location_latitude: form.getFieldValue("Device Location Latitude")
          ? form.getFieldValue("Device Location Latitude")
          : "",
        device_fuel: form.getFieldValue("Device Fuel")
          ? form.getFieldValue("Device Fuel")
          : "",
        device_technology: form.getFieldValue("Device Technology")
          ? form.getFieldValue("Device Technology")
          : "",
        device_capacity_in_watt: form.getFieldValue("Capacity")
          ? form.getFieldValue("Capacity")["Capacity Unit"] &&
            form.getFieldValue("Capacity")["Capacity Value"]
            ? form.getFieldValue("Capacity")["Capacity Unit"] == "kW" ||
              form.getFieldValue("Capacity")["Capacity Unit"] == "kWac"
              ? parseFloat(form.getFieldValue("Capacity")["Capacity Value"]) *
                1000
              : parseFloat(form.getFieldValue("Capacity")["Capacity Value"]) *
                1000000
            : ""
          : "",
        device_ownership: form.getFieldValue("Device Ownership")
          ? form.getFieldValue("Device Ownership")
          : "",
        device_commissioning_date: form.getFieldValue("Commissioning Date")
          ? form.getFieldValue("Commissioning Date").format("YYYY-MM-DD")
          : "",
        device_last_issuance_date: form.getFieldValue("Last Issuance Date")
          ? form.getFieldValue("Last Issuance Date").format("YYYY-MM-DD")
          : "",
        device_effective_registration_date: form.getFieldValue(
          "Effective Registration Date"
        )
          ? form.getFieldValue("Effective Registration Date")
          : "",
        is_device_owner: form.getFieldValue("Is Device Owner")
          ? form.getFieldValue("Is Device Owner")
          : "",
        device_owner: form.getFieldValue("Device Owner")
          ? form.getFieldValue("Device Owner")
          : "",
        is_captive_consumer: form.getFieldValue("Is Captive Consumer")
          ? form.getFieldValue("Is Captive Consumer")
          : "",
        captive_consumer: form.getFieldValue("Captive Consumer")
          ? form.getFieldValue("Captive Consumer")
          : "",
        username: currentUser,
      }),
    };
    const cb = (data) => {
      if (data.status) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Draf telah disimpan"
            : "Draft has been saved"
        );
        getDeviceInfo(data.device_id);
        setDeviceInfoDraftSaved(true);
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal menyimpan draf"
            : "Failed to save draft"
        );
      }
    };
    fetchHelper(
      BACKEND_URL + "/saveDeviceInfoDraft",
      cb,
      setLoading,
      "Save Device Info Draft",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getDeviceInfo(DeviceId) {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        DeviceId: DeviceId,
      }),
    };

    const cb = (data) => {
      if ("error" in data) {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal mengambil info perangkat"
            : data.error
        );
      } else {
        if ("Commissioning Date" in data) {
          if (data["Commissioning Date"] != "") {
            data["Commissioning Date"] = dayjs(data["Commissioning Date"]);
            setCommissioningDate(data["Commissioning Date"]);
          } else {
            data["Commissioning Date"] = undefined;
            setCommissioningDate(null);
          }
        }

        if ("Last Issuance Date" in data) {
          if (data["Last Issuance Date"] != "") {
            data["Last Issuance Date"] = dayjs(data["Last Issuance Date"]);
            setLastRecContractDate(data["Last Issuance Date"]);
          } else {
            data["Last Issuance Date"] = undefined;
            setLastRecContractDate(null);
          }
        }

        setSavedDeviceName(data["Device Name"]); // savedDeviceName is the saved draft device name in db
        setDeviceFuelSelected(data["Device Fuel"]);
        form.setFieldsValue(data);

        // set isDeviceOwner state to display or hide Device Owner column when retrieve device info
        if (data["Is Device Owner"] === "false") {
          setIsDeviceOwner(false);
        }

        // set isCaptiveConsumer state to display or hide Captive Consumer when retrieve device info column
        if (data["Is Captive Consumer"] === "true") {
          setIsCaptiveConsumer(true);
        }

        // set capacity unit list if device fuel is solar
        if (data["Device Fuel"] === 1) {
          setCapacityUnitList(capacityUnitSolarList);
        }
      }
    };

    fetchHelper(
      BACKEND_URL + "/getDeviceInfo",
      cb,
      setLoading,
      "Get Device Info",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function updateDeviceName() {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        device_id: deviceId,
        device_name: form.getFieldValue("Device Name") // if form.getFieldValue("Device Name") is truthy (i.e. not undefined, null, 0, false, or an empty string), pass the value to backend; otherwise, pass an empty string
          ? form.getFieldValue("Device Name").toUpperCase()
          : "",
      }),
    };

    const cb = (data) => {
      checkDeviceNameEmpty();
    };

    // device id = 0 means it's a new device that user is going to register
    // device id != 0 means device id has been saved as draft
    if (form.getFieldValue("Device Name") !== undefined && deviceId !== 0) {
      if (form.getFieldValue("Device Name").toUpperCase() !== savedDeviceName) {
        // setSavedDeviceName(form.getFieldValue("Device Name").toUpperCase());
        fetchHelper(
          BACKEND_URL + "/updateDeviceName",
          cb,
          setLoading,
          "Update Device Name",
          () => {},
          () => {},
          requestOptions,
          true
        );
      } else {
        checkDeviceNameEmpty();
      }
    } else {
      checkDeviceNameEmpty();
    }
  }

  // this useEffect is to get device fuel, device technology, device ownership and country selection at the beginning
  useEffect(() => {
    getDeviceFuelSelection();
    getDeviceTechnologySelection();
    getDeviceOwnershipSelection();
    loadCountries();
  }, []);

  // this useEffect is used to set device technology list based on selected device fuel
  // it is used at the beginning which means it will set device technology list after getDeviceInfo function is called and deviceFuelSelected is set
  useEffect(() => {
    setDeviceTechnologyList(
      allDeviceTechnologyList.filter(
        (item) => item.device_fuel_value === deviceFuelSelected
      )
    );
  }, [deviceFuelSelected, allDeviceTechnologyList]);

  // this useEffect is used to set state list when country code changes and decide whether to disable state selection or not
  // if country has not been selected, state selection will be disabled
  // if country has been selected, state selection will be enabled
  useEffect(() => {
    loadStates();
  }, [countryCode]);

  useEffect(() => {
    var queryString = window.location.search;
    var queryStringWithoutQuestionMark = queryString.substring(1); // devide id (after clicking view draft)

    // refreshForm is used as just want to call getDeviceInfo function at the beginning (only once throughout device registration),
    // this is so as refreshForm will be set to false in DeviceRegistration.js after this
    // means that after this, when navigate between previous and next page, getDeviceInfo function will not be called again during same device registration
    if (queryStringWithoutQuestionMark.length != 0 && refreshForm === false) {
      getDeviceInfo(queryStringWithoutQuestionMark);
      setDeviceId(queryStringWithoutQuestionMark);
      setDeviceInfoDraftSaved(true);
    }
  }, []);

  useEffect(() => {
    if (form.getFieldValue("Is Device Owner") !== undefined) {
      // to tackle "Unexpected end of JSON input" error if form.getFieldValue("Is Device Owner") is empty when getDeviceInfo function is called
      if (form.getFieldValue("Is Device Owner") !== "") {
        setIsDeviceOwner(JSON.parse(form.getFieldValue("Is Device Owner")));
      }
    }

    if (form.getFieldValue("Is Captive Consumer") !== undefined) {
      // to tackle "Unexpected end of JSON input" error if form.getFieldValue("Is Captive Consumer") is empty when getDeviceInfo function is called
      if (form.getFieldValue("Is Captive Consumer") !== "") {
        setIsCaptiveConsumer(
          JSON.parse(form.getFieldValue("Is Captive Consumer"))
        );
      }
    }

    if (form.getFieldValue("Device Fuel") !== undefined) {
      if (form.getFieldValue("Device Fuel") === 1) {
        setCapacityUnitList(capacityUnitSolarList);
      }
    }
  }, []);

  // this useEffect is to call function to calculate effective registration date when commissioningDate or lastRecContractDate changes
  useEffect(() => {
    getEffectiveRegistrationDate();
  }, [commissioningDate, lastRecContractDate]);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      className="device-registration-form-container"
      layout="vertical"
      scrollToFirstError
      autoComplete="off" // !important -> so that previously entered (and cleared) value is not remembered
      onChange={() => {
        setDeviceInfoDraftSaved(false); // when user changes something, disable 'Next' button
      }}
    >
      <div className="device-registration-outer-container">
        <div className="device-registration-content-container">
          <Row className="device-registration-content-container-row-layout">
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <h3 className="device-registration-h3-style">
                {language === INDONESIAN_LOCALE
                  ? "Daftarkan Perangkat Energi Terbarukan Baru"
                  : "Register New Renewable Energy Device"}
              </h3>
              <div className="device-registration-horizontal-line"></div>
              <h2 className="device-registration-h2-style">
                {language === INDONESIAN_LOCALE
                  ? "Informasi Perangkat"
                  : "Device Information"}
              </h2>
              <br />

              <div
                style={{
                  display: loading ? "none" : "block",
                }}
              >
                <Form.Item
                  className="device-registration-long-form-item-style"
                  label={
                    language === INDONESIAN_LOCALE
                      ? "Nama Perangkat"
                      : "Device Name"
                  }
                  name="Device Name"
                  tooltip={{
                    title:
                      language === INDONESIAN_LOCALE
                        ? "Silakan masukkan nama perangkat anda."
                        : "Please enter the device name.",
                    icon: <InfoCircleOutlined />,
                  }}
                  validateTrigger="onBlur" // validate after finishing input
                  rules={[
                    {
                      required: FORM_REQUIRED,
                      message:
                        language === INDONESIAN_LOCALE
                          ? "Silakan masukkan nama perangkat anda."
                          : "Please input your device name.",
                    },
                    {
                      pattern: /^[a-zA-Z0-9\s]+$/,
                      message:
                        language === INDONESIAN_LOCALE
                          ? "Anda hanya boleh masukkan karakter alfanumerik dan spasi."
                          : "You can only input alphanumeric characters and spaces.",
                    },
                  ]}
                  onChange={() => {
                    setDeviceInfoDraftSaved(false); // when user changes device name, force them to save draft before clicking 'Next' button
                  }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  className="device-registration-long-form-item-style"
                  label={language === INDONESIAN_LOCALE ? "Alamat" : "Address"}
                  name="Device Address"
                  tooltip={{
                    title:
                      language === INDONESIAN_LOCALE
                        ? "Silakan masukkan alamat perangkat anda."
                        : "Please enter the address for this device.",
                    icon: <InfoCircleOutlined />,
                  }}
                  rules={[
                    {
                      required: FORM_REQUIRED,
                      message:
                        language === INDONESIAN_LOCALE
                          ? "Silakan masukkan alamat perangkat anda."
                          : "Please input the address for your device.",
                    },
                  ]}
                  validateTrigger="onBlur" // validate after finishing input
                >
                  <Input />
                </Form.Item>
                <Row
                  className="device-registration-1-row-layout"
                  gutter={{
                    xs: 0,
                    sm: 0,
                    md: 0,
                    lg: 10,
                    xl: 10,
                    xxl: 10,
                  }}
                >
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="device-registration-1-column-layout"
                  >
                    <Form.Item
                      className="device-registration-1-short-form-item-style"
                      label={language === INDONESIAN_LOCALE ? "Kota" : "City"}
                      name="City"
                      tooltip={{
                        title:
                          language === INDONESIAN_LOCALE
                            ? "Silakan masukkan kota perangkat anda."
                            : "Please enter the city for this device.",
                        icon: <InfoCircleOutlined />,
                      }}
                      rules={[
                        {
                          required: FORM_REQUIRED,
                          message:
                            language === INDONESIAN_LOCALE
                              ? "Silakan masukkan kota perangkat anda."
                              : "Please input the city for your device.",
                        },
                      ]}
                      validateTrigger="onBlur" // validate after finishing input
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="device-registration-1-column-layout"
                  >
                    <Form.Item
                      className="device-registration-1-short-form-item-style"
                      label={
                        language === INDONESIAN_LOCALE ? "Kode Pos" : "Postcode"
                      }
                      name="Postcode"
                      tooltip={{
                        title:
                          language === INDONESIAN_LOCALE
                            ? "Silakan masukkan poskod perangkat anda."
                            : "Please enter the postcode for this device.",
                        icon: <InfoCircleOutlined />,
                      }}
                      rules={[
                        {
                          required: FORM_REQUIRED,
                          message:
                            language === INDONESIAN_LOCALE
                              ? "Silakan masukkan poskod perangkat anda."
                              : "Please input the postcode for your device.",
                        },
                      ]}
                      validateTrigger="onBlur" // validate after finishing input
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  className="device-registration-1-row-layout"
                  gutter={{
                    xs: 0,
                    sm: 0,
                    md: 0,
                    lg: 10,
                    xl: 10,
                    xxl: 10,
                  }}
                >
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="device-registration-1-column-layout"
                  >
                    <Form.Item
                      className="device-registration-1-short-form-item-style"
                      label={
                        language === INDONESIAN_LOCALE ? "Provinsi" : "State"
                      }
                      name="State"
                      tooltip={{
                        title:
                          language === INDONESIAN_LOCALE
                            ? "Silakan pilih negeri perangkat anda."
                            : "Please select the state for this device.",
                        icon: <InfoCircleOutlined />,
                      }}
                      rules={[
                        {
                          required: FORM_REQUIRED,
                          message:
                            language === INDONESIAN_LOCALE
                              ? "Silakan pilih negeri perangkat anda."
                              : "Please select the state for your device.",
                        },
                      ]}
                      validateTrigger="onBlur" // validate after finishing input
                    >
                      <Select
                        showSearch={true}
                        options={stateList.map((state) => ({
                          label: state.name,
                          value: state.name,
                          key: state.iso2,
                        }))}
                        onChange={() => {
                          setDeviceInfoDraftSaved(false); // when user changes something, disable 'Next' button
                        }}
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="device-registration-1-column-layout"
                  >
                    <Form.Item
                      className="device-registration-1-short-form-item-style"
                      label={
                        language === INDONESIAN_LOCALE ? "Negara" : "Country"
                      }
                      name="Country"
                      initialValue="Malaysia"
                      tooltip={{
                        title:
                          language === INDONESIAN_LOCALE
                            ? "Silakan pilih negeri perangkat anda."
                            : "Please select the country for this device.",
                        icon: <InfoCircleOutlined />,
                      }}
                      rules={[
                        {
                          required: FORM_REQUIRED,
                          message:
                            language === INDONESIAN_LOCALE
                              ? "Silakan pilih negeri perangkat anda."
                              : "Please select the country for your device.",
                        },
                      ]}
                      validateTrigger="onBlur" // validate after finishing input
                    >
                      <Select
                        showSearch={true}
                        options={countryList.map((country) => ({
                          label: country.name,
                          value: country.name,
                          key: country.iso2,
                        }))}
                        onChange={(_, value) => {
                          // _ is value itself, value is label, value, key
                          setCountryCode(value["key"]);
                          form.setFieldValue("State", null);
                          setDeviceInfoDraftSaved(false); // when user changes something, disable 'Next' button
                        }}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  className="device-registration-1-row-layout"
                  gutter={{
                    xs: 0,
                    sm: 0,
                    md: 0,
                    lg: 10,
                    xl: 10,
                    xxl: 10,
                  }}
                >
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="device-registration-1-column-layout"
                  >
                    <Form.Item
                      className="device-registration-1-short-form-item-style"
                      label={
                        language === INDONESIAN_LOCALE
                          ? "Lintang Lokasi Perangkat"
                          : "Latitude of Device Location"
                      }
                      name="Device Location Latitude"
                      tooltip={{
                        title:
                          language === INDONESIAN_LOCALE
                            ? "Silakan input lantang lokasi perangkat anda."
                            : "Please input the latitude of your device location.",
                        icon: <InfoCircleOutlined />,
                      }}
                      rules={[
                        {
                          required: FORM_REQUIRED,
                          message:
                            language === INDONESIAN_LOCALE
                              ? "Silakan input lantang lokasi perangkat anda."
                              : "Please input the latitude of your device location.",
                        },
                      ]}
                      validateTrigger="onBlur" // validate after finishing input
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="device-registration-1-column-layout"
                  >
                    <Form.Item
                      className="device-registration-1-short-form-item-style"
                      label={
                        language === INDONESIAN_LOCALE
                          ? "Bujur Lokasi Perangkat"
                          : "Longitude of Device Location"
                      }
                      name="Device Location Longitude"
                      tooltip={{
                        title:
                          language === INDONESIAN_LOCALE
                            ? "Silakan input bujur lokasi perangkat anda."
                            : "Please input the longitude of your device location.",
                        icon: <InfoCircleOutlined />,
                      }}
                      rules={[
                        {
                          required: FORM_REQUIRED,
                          message:
                            language === INDONESIAN_LOCALE
                              ? "Silakan input bujur lokasi perangkat anda."
                              : "Please input the longitude of your device location.",
                        },
                      ]}
                      validateTrigger="onBlur" // validate after finishing input
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  className="device-registration-1-row-layout"
                  gutter={{
                    xs: 0,
                    sm: 0,
                    md: 0,
                    lg: 10,
                    xl: 10,
                    xxl: 10,
                  }}
                >
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="device-registration-1-column-layout"
                  >
                    <Form.Item
                      className="device-registration-1-short-form-item-style"
                      label={
                        language === INDONESIAN_LOCALE
                          ? "Jenis Bahan Bakar Perangkat"
                          : "Device Fuel"
                      }
                      name="Device Fuel"
                      tooltip={{
                        title:
                          language === INDONESIAN_LOCALE
                            ? "Silakan pilih jenis bahan bakar perangkat anda."
                            : "Please select the fuel type used by this device.",
                        icon: <InfoCircleOutlined />,
                      }}
                      rules={[
                        {
                          required: FORM_REQUIRED,
                          message:
                            language === INDONESIAN_LOCALE
                              ? "Silakan pilih jenis bahan bakar perangkat anda."
                              : "Please select the fuel type used by your device.",
                        },
                      ]}
                      validateTrigger="onBlur" // validate after finishing input
                    >
                      <Select
                        options={deviceFuelList}
                        onChange={(value, option) => {
                          form.setFieldValue("Device Technology", null);
                          setDeviceFuelSelected(value);
                          setDeviceInfoDraftSaved(false); // when user changes something, disable 'Next' button

                          if (value == 1) {
                            form.setFieldValue("Capacity", {
                              "Capacity Value":
                                form.getFieldValue("Capacity")[
                                  "Capacity Value"
                                ],
                              "Capacity Unit": "kWac",
                            });
                            setCapacityUnitList(capacityUnitSolarList);
                          } else {
                            form.setFieldValue("Capacity", {
                              "Capacity Value":
                                form.getFieldValue("Capacity")[
                                  "Capacity Value"
                                ],
                              "Capacity Unit": "kW",
                            });
                            setCapacityUnitList(capacityUnitOtherList);
                          }
                        }}
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="device-registration-1-column-layout"
                  >
                    <Form.Item
                      className="device-registration-1-short-form-item-style"
                      label={
                        language === INDONESIAN_LOCALE
                          ? "Jenis Teknologi Perangkat"
                          : "Device Technology"
                      }
                      name="Device Technology"
                      tooltip={{
                        title:
                          language === INDONESIAN_LOCALE
                            ? "Silakan pilih jenis teknologi perangkat anda."
                            : "Please select the specific technology the device uses. Please note that you need to select device fuel first before selecting device technology.",
                        icon: <InfoCircleOutlined />,
                      }}
                      rules={[
                        {
                          required: FORM_REQUIRED,
                          message:
                            language === INDONESIAN_LOCALE
                              ? "Silakan pilihenis teknologi perangkat anda."
                              : "Please select the specific technology your device uses.",
                        },
                      ]}
                      validateTrigger="onBlur" // validate after finishing input
                    >
                      <Select
                        options={deviceTechnologyList}
                        onChange={() => {
                          setDeviceInfoDraftSaved(false); // when user changes something, disable 'Next' button
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  className="device-registration-1-row-layout"
                  gutter={{
                    xs: 0,
                    sm: 0,
                    md: 0,
                    lg: 10,
                    xl: 10,
                    xxl: 10,
                  }}
                >
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="device-registration-1-column-layout"
                  >
                    <Form.Item
                      className="device-registration-1-short-form-item-style device-registration-1-short-form-item-left-item-style"
                      tooltip={{
                        title:
                          language === INDONESIAN_LOCALE
                            ? "Silakan pilih kapasitas perangkat anda."
                            : "Please enter the device's capacity.",
                        icon: <InfoCircleOutlined />,
                      }}
                      label={
                        <>
                          {/* hardcode asterisk as it does not show up */}
                          <span style={{ color: "red" }}>*&nbsp;</span>
                          <span>
                            {language === INDONESIAN_LOCALE
                              ? "Kapasitas"
                              : "Capacity"}
                          </span>
                        </>
                      }
                    >
                      <Space.Compact style={{ width: "100%", height: "0px" }}>
                        <Form.Item
                          style={{ width: "80%" }}
                          name={["Capacity", "Capacity Value"]}
                          rules={[
                            {
                              required: FORM_REQUIRED,
                              message:
                                language === INDONESIAN_LOCALE
                                  ? "Silakan pilih teknologi spesifik yang digunakan perangkat Anda."
                                  : "Please input your device's capacity.",
                            },
                          ]}
                          validateTrigger="onBlur" // validate after finishing input
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={["Capacity", "Capacity Unit"]}
                          style={{ width: "20%" }}
                          initialValue="kW"
                          rules={[
                            {
                              required: FORM_REQUIRED,
                              message:
                                language === INDONESIAN_LOCALE
                                  ? "Silakan pilih satuan kapasitas perangkat anda."
                                  : "Please select your device's capacity unit.",
                            },
                          ]}
                          validateTrigger="onBlur" // validate after finishing input
                        >
                          <Select
                            options={capacityUnitList}
                            onChange={() => {
                              setDeviceInfoDraftSaved(false); // when user changes something, disable 'Next' button
                            }}
                          />
                        </Form.Item>
                      </Space.Compact>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="device-registration-1-column-layout"
                  >
                    <Form.Item
                      className="device-registration-1-short-form-item-style "
                      tooltip={{
                        title:
                          language === INDONESIAN_LOCALE
                            ? "Silakan pilih pemilik perangkat anda."
                            : "Please specify your solar energy system ownership.",
                        icon: <InfoCircleOutlined />,
                      }}
                      label={
                        language === INDONESIAN_LOCALE
                          ? "Pemilik Perangkat"
                          : "Device Ownership"
                      }
                      name="Device Ownership"
                      rules={[
                        {
                          required: FORM_REQUIRED,
                          message:
                            language === INDONESIAN_LOCALE
                              ? "Silakan pilih pemilik perangkat anda."
                              : "Please select your solar energy system ownership.",
                        },
                      ]}
                      validateTrigger="onBlur" // validate after finishing input
                    >
                      <Select
                        style={{
                          display: deviceOwnershipList == [] ? "none" : "block",
                        }}
                        value={deviceOwnershipSelection}
                        options={deviceOwnershipList}
                        onChange={(value, option) => {
                          setDeviceOwnershipSelection(value);
                          setDeviceInfoDraftSaved(false);
                        }}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  className="device-registration-1-row-layout"
                  gutter={{
                    xs: 0,
                    sm: 0,
                    md: 0,
                    lg: 10,
                    xl: 10,
                    xxl: 10,
                  }}
                >
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="device-registration-1-column-layout"
                  >
                    <Form.Item
                      className="device-registration-1-short-form-item-style device-registration-1-short-form-item-left-item-style"
                      tooltip={{
                        title:
                          language === INDONESIAN_LOCALE
                            ? "Silakan pilih tanggal pengoperasian perangkat."
                            : "Please select the date the device was commissioned. The date must be in the past.",
                        icon: <InfoCircleOutlined />,
                      }}
                      label={
                        language === INDONESIAN_LOCALE
                          ? "Tanggal Pengoperasian"
                          : "Commissioning Date"
                      }
                      name="Commissioning Date"
                      rules={[
                        {
                          required: FORM_REQUIRED,
                          message:
                            language === INDONESIAN_LOCALE
                              ? "Silakan pilih tanggal pengoperasian perangkat."
                              : "Please select the date your device was commissioned.",
                        },
                      ]}
                      validateTrigger="onBlur" // validate after finishing input
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          setCommissioningDate(value);
                          setDeviceInfoDraftSaved(false);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="device-registration-1-column-layout"
                  >
                    <Form.Item
                      className="device-registration-1-short-form-item-style "
                      tooltip={{
                        title:
                          language === INDONESIAN_LOCALE
                            ? "Silakan pilih tanggal terakhir penerbitan (jika ada)."
                            : "If you have registered your device before, please select the last issuance date of the device.",
                        icon: <InfoCircleOutlined />,
                      }}
                      label={
                        language === INDONESIAN_LOCALE
                          ? "Tanggal Penerbitan Terakhir (jika ada)"
                          : "Last Issuance Date (if any)"
                      }
                      name="Last Issuance Date"
                      validateTrigger="onBlur" // validate after finishing input
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          setLastRecContractDate(value);
                          setDeviceInfoDraftSaved(false);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  className="device-registration-1-row-layout"
                  gutter={{
                    xs: 0,
                    sm: 0,
                    md: 0,
                    lg: 10,
                    xl: 10,
                    xxl: 10,
                  }}
                >
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="device-registration-1-column-layout"
                  >
                    <Form.Item
                      className="device-registration-1-short-form-item-style device-registration-1-short-form-item-left-item-style"
                      tooltip={{
                        title:
                          language === INDONESIAN_LOCALE
                            ? "Silakan pilih 'Ya' jika perangkat ini milik perusahaan anda."
                            : "Please select 'Yes' if the device is owned by your company. Otherwise, please choose 'No'.",
                        icon: <InfoCircleOutlined />,
                      }}
                      rules={[
                        {
                          required: FORM_REQUIRED,
                          message:
                            language === INDONESIAN_LOCALE
                              ? "Silakan pilih 'Ya' jika perangkat ini milik perusahaan anda."
                              : "Please select either 'Yes' or 'No'",
                        },
                      ]}
                      label={
                        language === INDONESIAN_LOCALE
                          ? "Apakah perangkat ini milik perusahaan anda?"
                          : "Is the device owned by your company?"
                      }
                      name="Is Device Owner"
                      validateTrigger="onBlur" // validate after finishing input
                    >
                      <Select
                        onChange={(value) => {
                          setIsDeviceOwner(JSON.parse(value));
                          setDeviceInfoDraftSaved(false);
                        }}
                      >
                        <Option value="true">
                          {language === INDONESIAN_LOCALE ? "Ya" : "Yes"}
                        </Option>
                        <Option value="false">
                          {language === INDONESIAN_LOCALE ? "Tidak" : "No"}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="device-registration-1-column-layout"
                  >
                    <Form.Item
                      className="device-registration-1-short-form-item-style device-registration-1-short-form-item-left-item-style"
                      tooltip={{
                        title:
                          language === INDONESIAN_LOCALE
                            ? "Silakan pilih jenis penggunaan jika perangkat ini milik per businesses anda."
                            : "Please select how the renewable energy generation is used.",
                        icon: <InfoCircleOutlined />,
                      }}
                      rules={[
                        {
                          required: FORM_REQUIRED,
                          message:
                            language === INDONESIAN_LOCALE
                              ? "Silakan pilih jenis penggunaan' jika perangkat ini milik per businesses anda."
                              : "Please select how your renewable energy generation is used.",
                        },
                      ]}
                      label={
                        language === INDONESIAN_LOCALE
                          ? "Jenis Penggunaan"
                          : "The renewable energy generation is"
                      }
                      name="Is Captive Consumer"
                      validateTrigger="onBlur" // validate after finishing input
                    >
                      <Select
                        onChange={(value) => {
                          setIsCaptiveConsumer(JSON.parse(value));
                          setDeviceInfoDraftSaved(false);
                        }}
                      >
                        <Option value="true">
                          {language === INDONESIAN_LOCALE
                            ? "dikonsumsi oleh konsumen"
                            : "consumed by a consumer"}
                        </Option>
                        <Option value="false">
                          {language === INDONESIAN_LOCALE
                            ? "menyuplai ke Jaringan PLN"
                            : "feed into the grid"}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  className="device-registration-1-row-layout"
                  gutter={{
                    xs: 0,
                    sm: 0,
                    md: 0,
                    lg: 10,
                    xl: 10,
                    xxl: 10,
                  }}
                >
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="device-registration-1-column-layout"
                  >
                    <Form.Item
                      className="device-registration-1-short-form-item-style "
                      tooltip={{
                        title:
                          language === INDONESIAN_LOCALE
                            ? "Silakan masukkan nama pemilik perangkat jika perangkat ini milik per businesses anda."
                            : "Please input the name of the device owner.",
                        icon: <InfoCircleOutlined />,
                      }}
                      label={
                        language === INDONESIAN_LOCALE
                          ? "Pemilik Perangkat"
                          : "Device Owner"
                      }
                      name="Device Owner"
                      style={{ display: !isDeviceOwner ? "block" : "none" }}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              getFieldValue("Is Device Owner") !== undefined
                            ) {
                              if (
                                getFieldValue("Is Device Owner") === "false"
                              ) {
                                if (value === undefined || value === "") {
                                  return Promise.reject(
                                    new Error(
                                      "You must enter device owner if you select 'No' in 'Is the device owned by your company?' column."
                                    )
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              }
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                      validateTrigger="onBlur" // validate after finishing input
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="device-registration-1-column-layout"
                  >
                    <Form.Item
                      className="device-registration-1-short-form-item-style "
                      tooltip={{
                        title:
                          language === INDONESIAN_LOCALE
                            ? "Silakan masukkan entitas yang mengonsumsi perangkat jika perangkat ini milik per businesses anda."
                            : "Please input the entity who consumes the generation.",
                        icon: <InfoCircleOutlined />,
                      }}
                      label={
                        language === INDONESIAN_LOCALE
                          ? "Pengguna Konsumen"
                          : "The entity who consume the generation"
                      }
                      name="Captive Consumer"
                      style={{ display: isCaptiveConsumer ? "block" : "none" }}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              getFieldValue("Is Captive Consumer") !== undefined
                            ) {
                              if (
                                getFieldValue("Is Captive Consumer") === "true"
                              ) {
                                if (value === undefined || value === "") {
                                  return Promise.reject(
                                    new Error(
                                      "You must enter the entity who consumes the generation if you select 'consumed by a consumer' in 'The renewable energy generation is' column."
                                    )
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              }
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                      validateTrigger="onBlur" // validate after finishing input
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  name="Effective Registration Date"
                  hidden={true}
                ></Form.Item>
                <Form.Item name="Device Code" hidden={true}></Form.Item>
                <Form.Item>
                  <Space className="device-registration-btn-layout">
                    <Button
                      className="device-registration-save-draft-btn-style"
                      onClick={() => {
                        checkDeviceNameExists(
                          form.getFieldValue("Device Name")
                        );
                      }}
                    >
                      {language === INDONESIAN_LOCALE
                        ? "Simpan Draf"
                        : "Save Draft"}
                    </Button>
                    <Tooltip
                      title={
                        !deviceInfoDraftSaved
                          ? language === INDONESIAN_LOCALE
                            ? "Anda harus mengklik tombol 'Simpan Draf' sebelum mengklik tombol 'Berikutnya'."
                            : "You must click 'Save Draft' button before clicking 'Next' button."
                          : ""
                      }
                    >
                      <Button
                        className="device-registration-next-btn-style"
                        htmlType="submit"
                        onClick={() => {
                          generateDeviceCode();
                        }}
                        disabled={!deviceInfoDraftSaved} // disable 'Next' button if draft has not been saved
                      >
                        {language === INDONESIAN_LOCALE ? "Berikutnya" : "Next"}
                      </Button>
                    </Tooltip>
                  </Space>
                </Form.Item>
              </div>

              {/* Display loading when any of the files have not been uploaded or fetched from backend */}
              <div
                style={{
                  display: loading ? "block" : "none",
                }}
              >
                <Skeleton
                  active={true}
                  paragraph={{ rows: 10 }}
                  style={{ padding: "2rem", maxWidth: "70%", margin: "auto" }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Form>
  );
}
