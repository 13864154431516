import "../../styles/DeviceManagement.css";
import { useState, useEffect, useRef } from "react";
import { BACKEND_URL, FORM_REQUIRED } from "../../config";
import { fetchHelper } from "../../lib/fetch";
import { Pie } from "@antv/g2plot";

export function PieChart(props) {
  const { containerName, data } = props;
  const graph = useRef(null);

  useEffect(() => {
    // clean charting
    if (document.getElementById(containerName) != null) {
      document.getElementById(containerName).innerHTML = "";
    }

    const piePlot = new Pie(containerName, {
      data,
      angleField: "value",
      colorField: "type",
      radius: 1,
      innerRadius: 0.6,
      color: ({ type }) => {
        if (type === "Transacted" || type === "Ditransaksikan") {
          return "l(0) 0:#54528f 1:#a9a7dc";
        } else if (type === "Remaining" || type === "Sisa") {
          return "l(0) 0:#51BF9E 1:#D5E7D8";
        }
      },
      // label: {
      //   type: "inner",
      //   offset: "-50%",
      //   autoRotate: false,
      //   style: { textAlign: "center", fontSize: 14, fill: "black" },
      //   // show label as percentage
      //   formatter: (datum) =>
      //     // datum.value ? `${datum.value.toFixed(6)}` : "0.000000", // show label as value in 6dp
      //     {
      //       if (datum.value) {
      //         const total = data.reduce((sum, item) => sum + item.value, 0);
      //         const percent = (datum.value / total) * 100;
      //         return `${percent.toFixed(2)}%`;
      //       } else {
      //         return "0.00%";
      //       }
      //     },
      // },
      label: false, // do not show label
      statistic: {
        title: {
          offsetY: -8,
          // offsetY: 110,
          style: {
            fontSize: 15,
          },
        },
        content: {
          offsetY: -4,
          // offsetY: 114,
          style: {
            fontSize: 20,
          },
          // show central label as percentage
          formatter: (datum, data) => {
            if (datum) {
              if (datum.value) {
                const total = data.reduce((sum, item) => sum + item.value, 0);
                const percent = (datum.value / total) * 100;
                return `${percent.toFixed(2)}%`;
              } else {
                return "0.00%";
              }
            } else {
              return "100.00%";
            }
          },
        },
        // show central label as value in 6dp
        // formatter: (datum, data) => {
        //   if (datum) {
        //     if (datum.value) {
        //       return datum.value.toFixed(6);
        //     } else {
        //       return "0.000000";
        //     }
        //   } else {
        //     const total = data.reduce((sum, item) => sum + item.value, 0);
        //     return total.toFixed(6);
        //   }
        // },
        // },
      },
      // make central statistics interactive
      interactions: [
        { type: "element-selected" },
        { type: "element-active" },
        {
          type: "pie-statistic-active",
          cfg: {
            start: [
              { trigger: "element:mouseenter", action: "pie-statistic:change" },
              {
                trigger: "legend-item:mouseenter",
                action: "pie-statistic:change",
              },
            ],
            end: [
              { trigger: "element:mouseleave", action: "pie-statistic:reset" },
              {
                trigger: "legend-item:mouseleave",
                action: "pie-statistic:reset",
              },
            ],
          },
        },
      ],
    });

    piePlot.render();
    graph.current = piePlot;

    return () => {
      graph.current.destroy();
    };
  }, [containerName, data]);

  return <div style={{ width: "260px", height: "150px" }} id={containerName} />;
}
