import "../../styles/SignUp.css";
import Logo from "../../assets/REVEAI.png";
import { useNavigate } from "react-router-dom";
import { Card, Button, Tooltip } from "antd";
import { INDONESIAN_LOCALE } from "../../config";

function SignUp() {
  let navigate = useNavigate();

  const language = localStorage.getItem("language");

  return (
    <div className="signup-card-container">
      <Card
        className="signup-card"
        bodyStyle={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <div className="logo-image-div">
          <img src={Logo} style={{ width: "90%" }} alt="logo"></img>
        </div>
        <span className="signup-type-selection-title">
          {language === INDONESIAN_LOCALE
            ? `Aset Energi Terbarukan dimiliki oleh`
            : `The Renewable Energy Asset is owned by`}
        </span>
        <Tooltip title="Individual sign up is not available at the moment.">
          <Button
            className="signup-selection-button"
            type="primary"
            block
            onClick={() => navigate("individual", { replace: true })}
            disabled
          >
            {language === INDONESIAN_LOCALE ? "Individu" : "Individual"}
          </Button>
        </Tooltip>
        <Button
          className="signup-selection-button"
          type="primary"
          block
          onClick={() => navigate("organisation", { replace: true })}
        >
          {language === INDONESIAN_LOCALE ? "Organisasi" : "Organisation"}
        </Button>
      </Card>
    </div>
  );
}

export default SignUp;
