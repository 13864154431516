import "../../styles/DeviceRegistration/DeviceRegistration.css";
import "../../styles/DeviceRegistration/SARE.css";
import "../../styles/General.css";

import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Space,
  DatePicker,
  Button,
  Card,
  message,
  Upload,
  Tooltip,
  Skeleton,
  Flex,
} from "antd";

import { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { BACKEND_URL, INDONESIAN_LOCALE } from "../../config";
import { fetchHelper } from "../../lib/fetch";
import { InfoCircleOutlined } from "@ant-design/icons";

// export function SARE({ form, onFinish = () => {}, onPrevClick = () => {} }) {
export function SARE(props) {
  const { form, onFinish, onPrevClick, deviceSareData, deviceInfoData } = props;

  const language = localStorage.getItem("language");

  const [loadingSare, setLoadingSare] = useState(true);

  const [sareBackendFileFist, setSareBackendFileFist] = useState([]);
  const [uploadingSareFile, setUploadingSareFile] = useState(null);

  // username of user currently logged in, to be passed to backend to retrieve organisation id
  const currentUser = JSON.parse(sessionStorage.getItem("loginData"))?.username;

  // ---------------------------- Upload Letter of Declaration related functions -----------------------------------

  const sareFileList = sareBackendFileFist.map((element) => {
    return {
      uid: element.ID,
      name: element.Name,
      status: element.error ? "error" : "done",
      url: element.URL,
    };
  });
  if (uploadingSareFile !== null) {
    // To show upload progress
    sareFileList.unshift(uploadingSareFile);
  }

  function getSareFileList() {
    let params = {
      current_user: currentUser,
      device_name: deviceInfoData["Device Name"],
    };

    let url_param = new URLSearchParams(params);

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setSareBackendFileFist(data);
    };

    fetchHelper(
      `${BACKEND_URL}/sareUpload?${url_param}`,
      cb,
      setLoadingSare,
      "Sare Upload",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function uploadSareFile(file) {
    setLoadingSare(true);
    let forms = new FormData();

    forms.append("file", file);
    forms.append("session_username", currentUser);
    forms.append("device_name", deviceInfoData["Device Name"]);

    // Use XmlHttpRequest to be able to show progress
    // The alternative would be to use axios
    const xhr = new XMLHttpRequest();
    xhr.open("POST", BACKEND_URL + "/sareUpload");

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        const percent = (e.loaded / e.total) * 100;
        setUploadingSareFile({
          name: file.name,
          uid: -1,
          status: "uploading",
          percent: percent,
          url: "",
        });
      }
    };

    xhr.onload = function () {
      if (xhr.status === 200) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil memasukkan data"
            : "Successfully inserted data"
        );
        setUploadingSareFile(null);
        getSareFileList();
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal memasukkan data. Tipe file tidak didukung"
            : "Failed to insert data. File type is not supported."
        );
        setUploadingSareFile(null);
        setLoadingSare(false);
      }
    };

    xhr.send(forms);
  }

  const customSareRequest = async ({ file, onSuccess, onError }) => {
    try {
      // Your custom logic for uploading the file
      const response = uploadSareFile(file);

      // Check for successful upload
      if (response.status === 200) {
        onSuccess();
      } else {
        onError("Upload failed");
      }
    } catch (error) {
      onError(error.message || "Upload failed");
    }
  };

  // ------------------------- Delete function for all files -----------------------------------

  function deleteFile(file) {
    // set loading when file is being uploaded
    setLoadingSare(true);

    fetch(BACKEND_URL + "/supportingDocumentUpload/" + file.uid, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    }).then((response) => {
      if (response.status === 200) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil menghapus data"
            : "Successfully deleted data"
        );
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal menghapus data"
            : "Failed to delete data"
        );
      }
      getSareFileList();
    });
  }

  // this useEffect is to ensure values of form items retained when next or previous button is clicked
  useEffect(() => {
    form.setFieldsValue(deviceSareData);
    getSareFileList();
  }, []);

  return (
    <Form
      form={form}
      onFinish={() => {
        if (sareFileList.length === 0) {
          message.error(
            language === INDONESIAN_LOCALE
              ? "Silakan unggah tagihan SARE Anda"
              : "Please upload your SARE bill"
          );
        } else {
          onFinish();
        }
      }}
      layout="vertical"
      scrollToFirstError
      autoComplete="off"
    >
      <div className="device-registration-outer-container">
        <div className="device-registration-content-container">
          <Row className="device-registration-content-container-row-layout">
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <h3 className="device-registration-h3-style">
                {language === INDONESIAN_LOCALE
                  ? "Daftarkan Perangkat Energi Terbarukan Baru"
                  : "Register New Renewable Energy Device"}
              </h3>
              <div className="device-registration-horizontal-line"></div>
              <h2 className="device-registration-h2-style">SARE</h2>
              <br />

              {/* Display the uploading form items when all the files have been uploaded or fetched from backend */}
              <div
                style={{
                  display: !loadingSare ? "block" : "none",
                }}
              >
                <div className="device-sare-row-layout">
                  <div className="device-registration-2-wording-container">
                    <Flex gap="4px">
                      <h4>
                        {language === INDONESIAN_LOCALE
                          ? `Tagihan SARE Terbaru `
                          : `Latest SARE Bill `}
                      </h4>
                      <Tooltip
                        title={
                          language === INDONESIAN_LOCALE
                            ? "Anda hanya dapat mengunggah maksimal 5 lembar tagihan. Jika Anda melebihi batas dan ingin mengunggah tagihan lain, harap hapus tagihan lama, baru Anda dapat mengunggah tagihan baru."
                            : "You can only upload a maximum of 5 bills. If you exceed the limit and want to upload another bill, please delete the old one then only you can upload the new one."
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Flex>
                  </div>

                  <div className="device-sare-upload-btn-container">
                    <Upload
                      name="file"
                      className="device-sare-upload-btn"
                      fileList={sareFileList}
                      customRequest={customSareRequest}
                      onRemove={deleteFile}
                      onChange={(info) => {
                        if (info.file.status !== "uploading") {
                          console.log(info.file, info.fileList);
                        }
                        if (info.file.status === "done") {
                          message.success(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file berhasil diunggah`
                              : `${info.file.name} file uploaded successfully`
                          );
                        } else if (info.file.status === "error") {
                          message.error(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file gagal diunggah`
                              : `${info.file.name} file upload failed.`
                          );
                        }
                      }}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        disabled={
                          uploadingSareFile !== null ||
                          sareFileList.length === 5
                        }
                      >
                        {language === INDONESIAN_LOCALE
                          ? "Klik untuk Unggah"
                          : "Click to Upload"}
                      </Button>
                    </Upload>
                  </div>
                </div>
              </div>

              {/* Display loading when any of the files have not been uploaded or fetched from backend */}
              <div
                style={{
                  display: loadingSare ? "block" : "none",
                }}
              >
                <Skeleton
                  active={true}
                  paragraph={{ rows: 10 }}
                  style={{ padding: "2rem", maxWidth: "70%", margin: "auto" }}
                />
              </div>

              <Form.Item>
                <Space className="device-registration-btn-layout">
                  <Button
                    className="device-registration-prev-btn-style"
                    onClick={onPrevClick}
                  >
                    {language === INDONESIAN_LOCALE ? "Sebelumnya" : "Previous"}
                  </Button>
                  {/* <Button className="device-registration-delete-draft-btn-style">
                    Delete Draft
                  </Button>
                  <Button className="device-registration-save-draft-btn-style">
                    Save Draft
                  </Button> */}
                  <Button
                    className="device-registration-next-btn-style"
                    htmlType="submit"
                  >
                    {language === INDONESIAN_LOCALE ? "Dikirimkan" : "Submit"}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    </Form>
  );
}
