import { Form, Button, Input } from "antd";
import { FORM_REQUIRED, BACKEND_URL, INDONESIAN_LOCALE } from "../../config";

const checkUsernameExists = async (rule, value, callback) => {
  try {
    const response = await fetch(BACKEND_URL + "/checkUniqueUsername", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ Username: value }),
    });

    const data = await response.json();

    if (!data.is_unique) {
      return Promise.reject(
        new Error(
          "Username already exists. Please choose a different username."
        )
      );
    } else {
      return Promise.resolve();
    }
  } catch (error) {
    callback(error.message);
  }
};

export function UserNamePasswordForm({
  form,
  onFinish = () => {},
  onPrevClick = () => {},
}) {
  const language = localStorage.getItem("language");

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      name="registerInfo"
      scrollToFirstError
      autoComplete="off"
    >
      <Form.Item
        name="Username"
        label={language === INDONESIAN_LOCALE ? "Nama Pengguna" : "Username"}
        validateTrigger="onBlur"
        rules={[
          {
            required: FORM_REQUIRED,
            message:
              language === INDONESIAN_LOCALE
                ? "Silakan masukkan nama pengguna Anda."
                : "Please enter your username .",
          },
          {
            pattern: /^\S*$/,
            message:
              language === INDONESIAN_LOCALE
                ? "Tidak boleh ada spasi di dalam nama pengguna Anda"
                : "You cannot put space in your username",
          },
          {
            validator: checkUsernameExists,
          },
        ]}
      >
        <Input
          placeholder={
            language === INDONESIAN_LOCALE
              ? "Masukkan nama pengguna Anda"
              : "input your username"
          }
          onInput={(e) => (e.target.value = e.target.value.toLowerCase())}
        />
      </Form.Item>

      <Form.Item
        name="Password"
        label={language === INDONESIAN_LOCALE ? "Kata Sandi" : "Password"}
        rules={[
          {
            required: FORM_REQUIRED,
            message:
              language === INDONESIAN_LOCALE
                ? "Silakan masukkan kata sandi Anda."
                : "Please input your password.",
          },
          {
            pattern:
              /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/,
            message:
              language === INDONESIAN_LOCALE
                ? "Kata sandi Anda harus mengandung 1 angka, 1 huruf kecil, 1 huruf besar, 1 karakter khusus, tidak ada spasi dan harus 8-16 karakter"
                : "Your password must contain 1 digit, 1 lowercase letter, 1 uppercase letter, 1 special character, no space and it must be 8-16 characters long",
          },
        ]}
      >
        <Input.Password
          autoComplete="new-password"
          placeholder={
            language === INDONESIAN_LOCALE
              ? "Masukkan kata sandi Anda"
              : "input your password"
          }
        />
      </Form.Item>

      <Form.Item
        name="Confirm Password"
        label={
          language === INDONESIAN_LOCALE
            ? "Konfirmasi Kata Sandi"
            : "Confirm Password"
        }
        dependencies={["Password"]}
        rules={[
          {
            required: FORM_REQUIRED,
            message:
              language === INDONESIAN_LOCALE
                ? "Silakan konfirmasi kata sandi Anda."
                : "Please confirm your password.",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("Password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(
                  language === INDONESIAN_LOCALE
                    ? "Kata sandi Anda tidak cocok"
                    : "Passwords do not match."
                )
              );
            },
          }),
        ]}
      >
        <Input.Password
          placeholder={
            language === INDONESIAN_LOCALE
              ? "Masukkan kata sandi Anda satu kali lagi"
              : "input your password again"
          }
        />
      </Form.Item>

      <Form.Item style={{ textAlign: "right" }}>
        <Button
          style={{ background: "white", color: "black", marginRight: "5px" }}
          onClick={onPrevClick}
        >
          {language === INDONESIAN_LOCALE ? "Sebelumnya" : "Previous"}
        </Button>
        <Button
          style={{ background: "white", color: "black" }}
          htmlType="submit"
        >
          {language === INDONESIAN_LOCALE ? "Berikutnya" : "Next"}
        </Button>
      </Form.Item>
    </Form>
  );
}
