import "../styles/HomePage.css";
import "../styles/General.css";

import {
  Button,
  Card,
  message,
  Popconfirm,
  Table,
  Tooltip,
  Space,
  Input,
} from "antd";
import { BACKEND_URL, INDONESIAN_LOCALE } from "../config";
import { useState, useEffect, useRef } from "react";
import { fetchHelper } from "../lib/fetch";
import { createSearchParams, useNavigate } from "react-router-dom";

import {
  FileAddOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";

function HomePage() {
  const navigate = useNavigate();
  const [yourAssetList, setYourAssetList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const language = localStorage.getItem("language");

  const current_user = JSON.parse(
    sessionStorage.getItem("loginData")
  )?.username;

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search asset name`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: language === INDONESIAN_LOCALE ? "Nama Aset" : "Asset Name",
      dataIndex: "asset_name",
      key: "asset_name",
      fixed: "left",
      ...getColumnSearchProps("asset_name"),
    },
    {
      title:
        language === INDONESIAN_LOCALE ? "Pembaruan Terakhir" : "Last Update",
      dataIndex: "last_update",
      key: "last_update",
    },
    {
      title:
        language === INDONESIAN_LOCALE ? "Status Perangkat" : "Device Status",
      dataIndex: "device_status",
      key: "device_status",
    },
    {
      title:
        language === INDONESIAN_LOCALE
          ? "Tanggal Penerbitan Terakhir"
          : "Last Issuance Date",
      dataIndex: "last_issuance_date",
      key: "last_issuance_date",
    },
    {
      title: language === INDONESIAN_LOCALE ? "Jenis Skema" : "Scheme Type",
      dataIndex: "scheme_type",
      key: "scheme_type",
    },
    {
      title: language === INDONESIAN_LOCALE ? "Tindakan" : "Action",
      dataIndex: "action",
      key: "action",
      render: (_, data) => {
        return (
          <div className="table-action-col-container">
            <Tooltip
              title={
                language === INDONESIAN_LOCALE ? "Edit Draf" : "Edit Draft"
              }
            >
              <Button
                disabled={
                  data.device_status != "Draf" &&
                  data.device_status != "Ditolak" &&
                  data.device_status != "Draft" &&
                  data.device_status != "Rejected" &&
                  current_user != "openre"
                    ? true
                    : false
                }
                onClick={() => {
                  navigate(
                    "/deviceregistration" + "?" + data.device_id.toString()
                  );
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>

            <Popconfirm
              title="Delete Draft"
              description={"Are you sure to delete this draft?"}
              onConfirm={() => {
                deleteDraft(data);
              }}
              okText={language === INDONESIAN_LOCALE ? "Ya" : "Yes"}
              cancelText={language === INDONESIAN_LOCALE ? "Tidak" : "No"}
            >
              <Tooltip
                title={
                  language === INDONESIAN_LOCALE ? "Hapus Draf" : "Delete Draft"
                }
              >
                <Button
                  disabled={
                    data.device_status != "Draf" &&
                    data.device_status != "Ditolak" &&
                    data.device_status != "Draft" &&
                    data.device_status != "Rejected" &&
                    current_user != "openre"
                      ? true
                      : false
                  }
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>

            <Tooltip
              title={
                language === INDONESIAN_LOCALE
                  ? "Lihat Detail"
                  : "View Device Details"
              }
            >
              <Button
                disabled={
                  data.device_status != "Approved" &&
                  data.device_status != "Disetujui"
                }
                onClick={() => {
                  navigate({
                    pathname: "/devicemanagement",
                    search: createSearchParams({
                      device_id: data.device_id,
                    }).toString(),
                  });
                }}
              >
                <EyeOutlined />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: language === INDONESIAN_LOCALE ? "Catatan Admin" : "Admin Notes",
      dataIndex: "admin_note",
      key: "admin_note",
    },
  ];

  function getYourAsset() {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        language: language,
      }),
    };

    const cb = (data) => {
      if ("error" in data) {
        message.error(data.error);
      } else {
        setYourAssetList(data.your_asset_list);
      }
    };

    fetchHelper(
      BACKEND_URL + "/getYourAsset",
      cb,
      setLoading,
      "Get Your Asset List",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function deleteDraft(row_data) {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ device_name: row_data.asset_name }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        getYourAsset();
      }
    };

    fetchHelper(
      BACKEND_URL + "/home/deleteHomePageDraft",
      cb,
      setLoading,
      "Delete Home Page Draft",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    getYourAsset();
  }, []);

  return (
    <>
      <Card id="home-page">
        <div className="greeting">
          <h2>
            {language == INDONESIAN_LOCALE ? "Selamat Datang" : "Welcome!"}
          </h2>
        </div>

        <div className="btn-container">
          <Button
            className="registration-btn"
            onClick={() => {
              navigate("/deviceregistration");
            }}
          >
            <FileAddOutlined className="registration-icon" />
            {language == INDONESIAN_LOCALE
              ? "Daftar Perangkat Baru"
              : "Register New Device"}
          </Button>
        </div>
      </Card>

      <Card id="home-page" style={{ height: "600px" }}>
        <div className="greeting">
          <h2>{language == INDONESIAN_LOCALE ? "Aset Anda" : "Your Asset"}</h2>
        </div>
        <div>
          <Table
            size="midddle"
            scroll={{ x: 1800, y: 700 }}
            columns={columns}
            dataSource={yourAssetList}
            loading={loading}
          />
        </div>
      </Card>
    </>
  );
}

export default HomePage;
