import { Steps, Grid, Button } from 'antd';
import { useState, useEffect } from 'react';

export function CustomSteps({current, items}){

    const { useBreakpoint } = Grid;

    const screens = useBreakpoint();
    const [isBroken, setIsBroken] = useState(true);
    useEffect(()=>{
        setIsBroken(screens.xs);
    }, [screens]);

    return(
        <div style={{ minWidth: '12ch'}}>
            <Steps
                current={current}
                items={items}
                labelPlacement="vertical"
                direction={isBroken? 'vertical': 'horizontal'}
            />
        </div>
    )
}

export function StepNavigation({
    steps,
    current, 
    setCurrent,
    finishCallback,
    finishLabel='Done',
}){

    const next = () => {
        setCurrent(current + 1);
    };
      const prev = () => {
        setCurrent(current - 1);
    };

    return(
        <>
        {current > 0 && (
            <Button
                style={{
                margin: '0 8px',
                }}
                onClick={() => prev()}
            >
                Previous
            </Button>
        )}
        {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()} style={{backgroundColor: 'var(--theme-dark-blue)'}}>
                Next
            </Button>
        )}
        {current === steps.length - 1 && (
            <Button type="primary" onClick={finishCallback} style={{backgroundColor: 'var(--theme-dark-blue)'}}>
                {finishLabel}
            </Button>
        )}
        </>
    )
}