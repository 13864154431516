import "../styles/InternalOrganisationManagement.css";
import "../styles/General.css";

import ErrorPage from "./ErrorPage";

import { Card, Table, Switch } from "antd";
import { BACKEND_URL } from "../config";
import { useState, useEffect } from "react";
import { fetchHelper } from "../lib/fetch";

function InternalOrganisationManagement() {
  const current_login_status = JSON.parse(
    sessionStorage.getItem("loginData")
  )?.login_status;

  const current_user = JSON.parse(
    sessionStorage.getItem("loginData")
  )?.username;

  const [loading, setLoading] = useState(true);
  const [columnList, setColumnList] = useState([]);
  const [tableData, setTableData] = useState([]);

  /** API to change deal structure */
  const changeDealStructure = (organisation_id, current_deal_structure) => {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        organisation_id: organisation_id,
        current_deal_structure: current_deal_structure,
      }),
    };

    const cb = (data) => {
      getData();
    };

    fetchHelper(
      BACKEND_URL + "/internalOrganisationManagement/changeDealStructure",
      cb,
      setLoading,
      "Change Deal Structure",
      () => {},
      () => {},
      requestOptions,
      true
    );
  };

  /** API to get table data from database */
  const getData = () => {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };

    const cb = (data) => {
      // Map over the column data and add the render function to the desired columns
      const updated_columns = data.column_data.map((column) => {
        if (column.dataIndex === "deal_structure") {
          // Add the render function for the 'deal_structure' column
          return {
            ...column,
            render: (_, record) => (
              <Switch
                value={record.deal_structure ? record.deal_structure : false}
                onChange={() => {
                  changeDealStructure(record.key, record.deal_structure);
                }}
              />
            ),
          };
        } else {
          // For other columns, return them as they are
          return column;
        }
      });

      setColumnList(updated_columns);
      setTableData(data.table_data);
    };

    fetchHelper(
      BACKEND_URL +
        "/internalOrganisationManagement/getInternalOrganisationManagementData",
      cb,
      setLoading,
      "Get Internal Organisation Management Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {current_user != "openre" ? <ErrorPage /> : ""}
      <div
        style={{
          display:
            current_login_status === true && current_user === "openre"
              ? "block"
              : "none",
        }}
      >
        <Card
          id="internal-organisation-management"
          title={
            <div>
              <h3>Internal Organisation Management</h3>
            </div>
          }
        >
          <div>
            <Table
              size="midddle"
              scroll={{ x: 2000, y: 700 }}
              columns={columnList}
              dataSource={tableData}
              loading={loading}
            />
          </div>
        </Card>
      </div>
    </>
  );
}

export default InternalOrganisationManagement;
