import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import "./styles/index.css";

import LanguageSelection from "./pages/language_selection/LanguageSelection";
import Login from "./pages/Login";
import SignUp from "./pages/signup/SignUp";
import IndividualSignUp from "./pages/signup/IndividualSignUp";
import OrganisationSignUp from "./pages/signup/OrganisationSignUp";
import EmailVerification from "./pages/signup/EmailVerfication";
import DeviceRegistration from "./pages/DeviceRegistration";
import DeviceRegistrationConfirmation from "./pages/DeviceRegistrationConfirmation";
import HomePage from "./pages/HomePage";
import InternalDeviceManagement from "./pages/InternalDeviceManagement";
import InternalOrganisationManagement from "./pages/InternalOrganisationManagement";
import DeviceManagement from "./pages/DeviceManagement";

import AssetLayout from "./layout/AssetLayout";
import LandingPage from "./pages/LoadingPage";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/language_selection" element={<LanguageSelection />}></Route>

      <Route path="/login" element={<Login />}></Route>

      <Route path="/signup">
        <Route index element={<SignUp />} />
        <Route path="individual" element={<IndividualSignUp />} />
        <Route path="organisation" element={<OrganisationSignUp />} />
        <Route path="verification" element={<EmailVerification />} />
      </Route>

      <Route path="/" element={<AssetLayout />}>
        <Route index element={<Navigate to="/language_selection" />} />
        <Route path="home" element={<HomePage />} />
        <Route path="deviceregistration" element={<DeviceRegistration />} />
        <Route
          path="deviceregistrationconfirmation"
          element={<DeviceRegistrationConfirmation />}
        />
        <Route
          path="internaldevicemanagement"
          element={<InternalDeviceManagement />}
        />
        <Route
          path="internalorganisationmanagement"
          element={<InternalOrganisationManagement />}
        />
        <Route path="DeviceManagement" element={<DeviceManagement />} />
        <Route path="other" element={<LandingPage />} />
      </Route>

      <Route path="*" element={<Navigate to="/language_selection" />} />
    </Routes>
  </BrowserRouter>
);
