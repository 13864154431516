import "../../styles/SignUp.css";
import Logo from "../../assets/REVEAI.png";
import { BACKEND_URL, INDONESIAN_LOCALE } from "../../config";
import { CustomSteps } from "../../components/Steps";
import { OrganisationInfoForm } from "../../components/signup/OrganisationInfoForm";
import { OrganisationUserInfoForm } from "../../components/signup/OrganisationUserInfoForm";
import { UserNamePasswordForm } from "../../components/signup/UserNamePasswordForm";
import { SignUpConfirmation } from "../../components/signup/SignUpConfirmation";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Form, message } from "antd";
import { fetchHelper } from "../../lib/fetch";

function OrganisationSignUp() {
  const language = localStorage.getItem("language");

  const navigate = useNavigate();

  const [current, setCurrent] = useState(0);

  const [organisationInfoForm] = Form.useForm();
  const [organisationUserInfoForm] = Form.useForm();
  const [organistaionUserNamePasswordForm] = Form.useForm();

  const [organisationInfoData, setOrganisationInfoData] = useState();
  const [organisationUserInfoData, setOrganisationUserInfoData] = useState();
  const [loginDetailsData, setLoginDetailsData] = useState();

  // this country code is used to set state selection list
  // default country code will be Malaysia (MY)
  const [countryCode, setCountryCode] = useState("MY");

  const steps = [
    {
      title:
        language === INDONESIAN_LOCALE
          ? "Informasi Organisasi"
          : "Organisation Info",
      content: (
        <OrganisationInfoForm
          form={organisationInfoForm}
          value={organisationInfoData}
          onFinish={(values) => {
            setOrganisationInfoData(values);
            setCurrent((prev) => prev + 1);
          }}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
        />
      ),
    },
    {
      title:
        language === INDONESIAN_LOCALE
          ? "Informasi Kontak Utama"
          : "Primary Contact Info",
      content: (
        <OrganisationUserInfoForm
          form={organisationUserInfoForm}
          value={organisationUserInfoData}
          onFinish={(values) => {
            setOrganisationUserInfoData(values);
            setCurrent((prev) => prev + 1);
          }}
          onPrevClick={() => setCurrent((prev) => prev - 1)}
        />
      ),
    },
    {
      title: language === INDONESIAN_LOCALE ? "Detail Login" : "Login Details",
      content: (
        <UserNamePasswordForm
          form={organistaionUserNamePasswordForm}
          value={loginDetailsData}
          onFinish={(values) => {
            setLoginDetailsData(values);
            setCurrent((prev) => prev + 1);
          }}
          onPrevClick={() => setCurrent((prev) => prev - 1)}
        />
      ),
    },
    {
      title: language === INDONESIAN_LOCALE ? "Konfirmasi" : "Confirmation",
      content: (
        <SignUpConfirmation
          data={[
            {
              title:
                language === INDONESIAN_LOCALE
                  ? "Informasi Organisasi"
                  : "Organisation Info",
              value: organisationInfoData,
            },
            {
              title:
                language === INDONESIAN_LOCALE
                  ? "Informasi Kontak Utama"
                  : "Primary Contact Info",
              value: organisationUserInfoData,
            },
            {
              title:
                language === INDONESIAN_LOCALE
                  ? "Detail Login"
                  : "Login Details",
              value: loginDetailsData,
            },
          ]}
          onConfirmClick={() => {
            confirmAddOrganisationInfoModel();
            insertEmailMessage();
          }}
          onPrevClick={() => setCurrent((prev) => prev - 1)}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  function confirmAddOrganisationInfoModel() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        AddressLine1: organisationInfoData["Address Line 1"]
          ? organisationInfoData["Address Line 1"]
          : "",
        AddressLine2: organisationInfoData["Address Line 2"]
          ? organisationInfoData["Address Line 2"]
          : "",
        City: organisationInfoData["City"] ? organisationInfoData["City"] : "",
        Country: organisationInfoData["Country"]
          ? organisationInfoData["Country"]
          : "",
        OrganisationName: organisationInfoData["Organisation Name"]
          ? organisationInfoData["Organisation Name"]
          : "",
        OrganisationRegistrationNo: organisationInfoData[
          "Organisation Registration Number"
        ]
          ? organisationInfoData["Organisation Registration Number"]
          : "",
        Postcode: organisationInfoData["Postcode"]
          ? organisationInfoData["Postcode"]
          : "",
        State: organisationInfoData["State"]
          ? organisationInfoData["State"]
          : "",

        Email: organisationUserInfoData["Email"]
          ? organisationUserInfoData["Email"]
          : "",
        FirstName: organisationUserInfoData["First Name"]
          ? organisationUserInfoData["First Name"]
          : "",
        LastName: organisationUserInfoData["Last Name"]
          ? organisationUserInfoData["Last Name"]
          : "",
        MobileNo: organisationUserInfoData["Mobile No"]
          ? organisationUserInfoData["Mobile No"]
          : "",
        Position: organisationUserInfoData["Position"]
          ? organisationUserInfoData["Position"]
          : "",
        DesignationId: organisationUserInfoData["Designation Id"]
          ? organisationUserInfoData["Designation Id"]
          : "",

        Username: loginDetailsData["Username"]
          ? loginDetailsData["Username"]
          : "",
        Password: loginDetailsData["Password"]
          ? loginDetailsData["Password"]
          : "",
      }),
    };

    const cb = (data) => {
      if (data.status) {
        navigate("/signup/verification", { replace: true });
      } else {
        message.error(data.error);
      }
    };

    fetchHelper(
      BACKEND_URL + "/addOrganisationInfo",
      cb,
      () => {},
      "Add Organisation Info",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /* API to insert required info to EmailMessage table */
  const insertEmailMessage = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        Email: organisationUserInfoData["Email"],
        FirstName: organisationUserInfoData["First Name"],
        LastName: organisationUserInfoData["Last Name"],
      }),
    };

    const cb = () => {};

    fetchHelper(
      BACKEND_URL + "/insertEmailMessage",
      cb,
      () => {},
      "Insert Email Message",
      () => {},
      () => {},
      requestOptions,
      true
    );
  };

  return (
    <div className="signup-content-grid">
      <div className="logo-image-div signup-top-margin">
        <img src={Logo} style={{ width: "60%" }} alt="signup-logo"></img>
      </div>

      <div className="signup-step-container">
        <CustomSteps current={current} items={items} />
      </div>

      <div className="signup-form-container signup-bottom-margin">
        {steps[current].content}
      </div>
    </div>
  );
}

export default OrganisationSignUp;
