import "../styles/AssetLayout.css";
import {
  App,
  Layout,
  Menu,
  message,
  Select,
  Button,
  ConfigProvider,
  Grid,
} from "antd";
import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  AppstoreOutlined,
  FolderOutlined,
  DesktopOutlined,
  PieChartOutlined,
  FileTextOutlined,
  MenuOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import Logo from "../assets/REVEAI.png";
import LandingPage from "../pages/LoadingPage";
import { BACKEND_URL, INDONESIAN_LOCALE } from "../config";
import { fetchHelper } from "../lib/fetch";
import idID from "antd/es/locale/id_ID";
import enGB from "antd/es/locale/en_GB";

const VERBOSE = false;
/**
 * TODO
 */

const { Header, Sider, Content } = Layout;
const LOGOUT_URL = BACKEND_URL + "/logoutUser";
const checkLogin_url = BACKEND_URL + "/checkUser";

const { useBreakpoint } = Grid;

/**
 * Defines the Layout of the page
 * - sets header and sider
 * - check user login status when transitioning between pages
 */
function AssetLayout() {
  const language = localStorage.getItem("language");

  const navigate = useNavigate();

  const current_login_status = JSON.parse(
    sessionStorage.getItem("loginData")
  )?.login_status;

  const current_user = JSON.parse(
    sessionStorage.getItem("loginData")
  )?.username;

  // get selected based on url pathname
  // to show selected state in (header) menu
  let menuSelectedKey = window.location.pathname;

  const [siderOnClick, setSiderOnClick] = useState(false);

  // to show loading page instead of actual page when checking login status
  const [userLoginChecking, setUserLoginChecking] = useState(true);

  // setup using data from session storage
  // STANDARDIZE selecteddSite = ID : Integer
  const [selectedSite, _setSelectedSite] = useState();
  const setSelectedSite = (site) => {
    // console.log(typeof site);
    sessionStorage.setItem("selectedSite", site);
    _setSelectedSite(site);
  };
  const [loginData, setLoginData] = useState({
    carbonAccounting: false,
    userType: 999,
    sites: [],
  });

  const header_menu = [
    {
      key: "/home",
      label: language === INDONESIAN_LOCALE ? "Beranda" : "Home",
    },
    {
      key: "/deviceregistration",
      label:
        language === INDONESIAN_LOCALE
          ? "Daftarkan Perangkat Baru"
          : "Register New Device",
    },
    {
      key: "/devicemanagement",
      label:
        language === INDONESIAN_LOCALE
          ? "Manajemen Perangkat"
          : "Device Management",
    },
    // {
    //   label: <div>i-RECs</div>,
    //   children: [
    //     {
    //       label: "Overview",
    //       key: "/irecsoverview",
    //     },
    //     {
    //       label: "View",
    //       key: "/irecsview",
    //     },
    //   ],
    // },
    {
      key: "/internaldevicemanagement",
      label: "Internal Device Management", // this page will only be shown if username is "openre" and login status is true
    },
    {
      key: "/internalorganisationmanagement",
      label: "Internal Organisation Management", // this page will only be shown if username is "openre" and login status is true
    },
  ];

  // ############## FOR GENERAL BREAKPOINTS  #############################
  const screens = useBreakpoint();
  const [isBroken, setIsBroken] = useState(true);
  useEffect(() => {
    setIsBroken(screens.xs);
    setIsCollapse(screens.xs);
  }, [screens]);
  // #####################################################################

  // to control side in mobile mode
  const [isCollapse, setIsCollapse] = useState(true);

  // const onBreakpointChange = (broken) => {
  //   setIsCollapse(broken); // collapse sider when entering mobile mode
  // };

  const onCollapse = (broken) => {
    setIsCollapse(broken);
    setSiderOnClick(!broken);
  };

  const onSiteSelect = (site) => {
    setSelectedSite(site);
  };

  const logout = () => {
    //show a message to indicate it is logging out
    message.loading({
      content: "Logging Out",
      key: "updatable",
    });

    const fetchSucessCallback = (data) => {
      message.success({
        content: "Logged Out. Redirecting in 3s.",
        key: "updatable",
        duration: 3,
      });

      const loginData = JSON.parse(sessionStorage.getItem("loginData"));

      const newLoginData = {
        ...loginData,
        login_status: false,
      };

      // console.log(loginData);
      sessionStorage.setItem("loginData", JSON.stringify(newLoginData));
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    };

    const setLoading = () => {};

    fetchHelper(LOGOUT_URL, fetchSucessCallback, setLoading, "Logout");
  };

  /**
   * call backend to check if user is logged in
   * @param {*} callback function to call upon success (mostly for navigation in this context)
   */
  const checkUserLogin = (callback) => {
    // setUserLoginChecking(false);
    callback();

    setUserLoginChecking(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    fetch(checkLogin_url, requestOptions)
      .then((response) => {
        // console.log(response);
        if (response.status == 200) {
          setUserLoginChecking(false);
          callback();
        } else {
          if (loginData) {
            sessionStorage.setItem(
              "loginData",
              JSON.stringify({
                ...loginData,
                login_status: false,
              })
            );
          }
          navigate("/language_selection");
        }
      })
      .catch((e) => {
        navigate("/language_selection");
        // console.log(e);
      });
  };

  useEffect(() => {
    // if (VERBOSE) console.log("Use Effect");

    checkUserLogin(() => {});

    const loginData = JSON.parse(sessionStorage.getItem("loginData"));

    // if (VERBOSE) console.log(loginData);

    // if (!loginData || !loginData.login_status) {
    //   navigate("/login"); // fallback to login if data is missing
    // } else {
    //   setLoginData({
    //     carbonAccounting: loginData.carbon_accounting,
    //     userType: loginData.login_type,
    //     sites: loginData.site_data.map((site) => ({
    //       value: site.siteID,
    //       label: site.longName,
    //     })),
    //   });
    // }

    if (parseInt(sessionStorage.getItem("selectedSite"))) {
      setSelectedSite(parseInt(sessionStorage.getItem("selectedSite")));
    }
  }, []);

  return (
    <>
      <ConfigProvider
        locale={language === INDONESIAN_LOCALE ? idID : enGB}
        theme={{
          components: {
            Segmented: {
              itemHoverColor: "#1890ff",
              itemSelectedColor: "#ffffff",
              itemSelectedBg: "#01063B",
            },
          },
        }}
      >
        <App>
          <Layout id="asset-layout" style={{ width: "auto" }}>
            <Header style={{ background: "white" }}>
              <div id="header-container">
                <img src={Logo} className="header-logo" alt="reveai logo" />
                <Menu
                  className="header-menu"
                  overflowedIndicator={
                    isBroken ? <MenuOutlined /> : <EllipsisOutlined />
                  } // overwrite Ellipsis into Menu icon in Mobile mode
                  theme="light"
                  mode="horizontal"
                  selectedKeys={[menuSelectedKey]} // to show selected state visually (underline when selected)
                  items={
                    current_login_status === true && current_user === "openre"
                      ? header_menu
                      : header_menu.filter(
                          (item) =>
                            item.label != "Internal Device Management" &&
                            item.label != "Internal Organisation Management"
                        )
                  }
                  onClick={(value) => {
                    navigate(value.key);
                    // checkUserLogin(() => navigate(value.key));
                  }}
                />
                <Button
                  className="header-logout-button"
                  type="text"
                  onClick={() => {
                    // checkUserLogin(()=>logout());
                    logout();
                  }}
                >
                  {language === INDONESIAN_LOCALE ? "Keluar" : "Logout"}
                </Button>
              </div>
            </Header>
            <Content style={{}}>
              {/* {!userLoginChecking ? <LandingPage /> : <Outlet />} */}
              {siderOnClick ? <LandingPage /> : <Outlet />}
            </Content>
          </Layout>
        </App>
      </ConfigProvider>
    </>
  );
}

export default AssetLayout;
