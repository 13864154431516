import "../styles/InternalDeviceManagement.css";
import "../styles/General.css";

import ErrorPage from "./ErrorPage";
import moment from "moment";

import {
  Button,
  Card,
  Table,
  Switch,
  Tooltip,
  Modal,
  Input,
  Form,
  message,
  Popconfirm,
} from "antd";
import { BACKEND_URL, FORM_REQUIRED } from "../config";
import { useState, useEffect } from "react";
import { fetchHelper } from "../lib/fetch";
import {
  VerticalAlignBottomOutlined,
  WarningFilled,
  CheckCircleFilled,
  CommentOutlined,
  EyeOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { createSearchParams, useNavigate } from "react-router-dom";

function InternalDeviceManagement() {
  const navigate = useNavigate();

  const current_login_status = JSON.parse(
    sessionStorage.getItem("loginData")
  )?.login_status;

  const current_user = JSON.parse(
    sessionStorage.getItem("loginData")
  )?.username;

  const [loading, setLoading] = useState(true);

  const [deviceSelectedRows, setDeviceSelectedRows] = useState([]);
  const [deviceSelectedRowKeys, setDeviceSelectedRowKeys] = useState([]);

  const [internalDeviceManagementData, setInternalDeviceManagementData] =
    useState([]);

  // filter list for organisation name
  const [organisationFilter, setOrganisationFilter] = useState([]);

  // add / edit data modal related functions and states
  const [addNoteForm] = Form.useForm();
  const [visibleModal, setVisibleModal] = useState(null);
  const handleAddNoteModalOpen = (key) => {
    setVisibleModal(key);
  };
  const handleAddNoteModalCancel = () => {
    setVisibleModal(null);
  };

  const columns = [
    {
      title: "Organisation Name",
      dataIndex: "organisation_name",
      key: "organisation_name",
      fixed: "left",
      filters: organisationFilter,
      onFilter: (value, record) => record.organisation_name === value,
      render: (_, record) => {
        let icon = <WarningFilled style={{ color: "#E5DE00" }} />; // yellow

        if (record.deal_structure) {
          icon = <CheckCircleFilled style={{ color: "#00A86D" }} />; // green
        }

        return (
          <div className="organisation-name-icon">
            <p>{record.organisation_name}</p>
            {icon}
          </div>
        );
      },
    },
    {
      title: "Device Name",
      dataIndex: "device_name",
      key: "device_name",
      fixed: "left",
    },
    {
      title: "Device Code",
      dataIndex: "device_code",
      key: "device_code",
    },
    {
      title: "Sign Up Date",
      dataIndex: "sign_up_date",
      key: "sign_up_date",
    },
    {
      title: "Submission Status",
      dataIndex: "submission_status",
      key: "submission_status",
    },
    {
      title: "Submission Last Update",
      dataIndex: "submission_last_update",
      key: "submission_last_update",
    },
    {
      title: "Registered?", // indicator whether device has been registered
      dataIndex: "registration_status",
      key: "registration_status",
      render: (_, record) => (
        <Switch
          value={
            record.registration_status ? record.registration_status : false
          }
          onChange={() => {
            changeRegistrationStatus(record.key, record.registration_status);
          }}
        />
      ),
    },
    {
      title: "Registration Date",
      dataIndex: "registration_date",
      key: "registration_date",
    },
    {
      title: "Scheme Type",
      dataIndex: "scheme_type",
      key: "scheme_type",
    },
    {
      title: "Note to User",
      dataIndex: "admin_note",
      key: "admin_note",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <div className="table-action-col-container">
            <Tooltip title="Download">
              <Button
                onClick={() => {
                  downloadEachDeviceInfo(record.key);
                }}
              >
                <VerticalAlignBottomOutlined />
              </Button>
            </Tooltip>

            <Tooltip title="Add / Edit Data">
              <Button>
                <CommentOutlined
                  onClick={() => {
                    handleAddNoteModalOpen(record.key);
                    addNoteForm.setFieldValue(
                      "AdminNote",
                      // extractWordsBetweenQuotes(record.admin_note)
                      record.admin_note
                    );
                    addNoteForm.setFieldValue("SchemeType", record.scheme_type);
                  }}
                />
              </Button>
            </Tooltip>

            <Modal
              title="Add / Edit Data"
              open={visibleModal === record.key}
              onCancel={handleAddNoteModalCancel}
              footer={null}
            >
              <Form
                form={addNoteForm}
                name="add-note-form"
                onFinish={(values) => addNote(values, record)}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  label="Approve / Reject Note"
                  name="AdminNote"
                  tooltip="Take note that you cannot type apostrophes in your note."
                  rules={[
                    {
                      required: FORM_REQUIRED,
                      message: "Please input your note!",
                    },
                    {
                      validator: (_, value) =>
                        value && value.includes("'")
                          ? Promise.reject(
                              new Error(
                                "You cannot type apostrophes in your note"
                              )
                            )
                          : Promise.resolve(),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Scheme Type"
                  name="SchemeType"
                  tooltip="Take note that you cannot type apostrophes in scheme type."
                  rules={[
                    {
                      required: FORM_REQUIRED,
                      message: "Please input scheme type!",
                    },
                    {
                      validator: (_, value) =>
                        value && value.includes("'")
                          ? Promise.reject(
                              new Error(
                                "You cannot type apostrophes in your note"
                              )
                            )
                          : Promise.resolve(),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item>
                  <div style={{ textAlign: "center" }}>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Modal>

            <Tooltip title="View Device Details">
              <Button
                disabled={record.submission_status != "Approved"}
                onClick={() => {
                  console.log(record);
                  navigate({
                    pathname: "/devicemanagement",
                    search: createSearchParams({
                      device_id: record.key,
                    }).toString(),
                  });
                }}
              >
                <EyeOutlined />
              </Button>
            </Tooltip>

            <Tooltip title="Reject">
              <Popconfirm
                title="Reject REC Documents Submission"
                description={
                  "Are you sure to reject the submission and send email to user? Remember to add / change note to user."
                }
                onConfirm={() => {
                  sendRejectEmail(record);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button>
                  <CloseCircleOutlined style={{ color: "red" }} />
                </Button>
              </Popconfirm>
            </Tooltip>

            <Tooltip title="Approve">
              <Popconfirm
                title="Approve REC Documents Submission"
                description={
                  "Are you sure to approve the submission and send email to user? Remember to add / change note to user."
                }
                onConfirm={() => {
                  sendApproveEmail(record);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button>
                  <CheckCircleOutlined style={{ color: "green" }} />
                </Button>
              </Popconfirm>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  // Function to find the first and last occurrence of double quotes and extract words
  function extractWordsBetweenQuotes(str) {
    if (!str) {
      return "";
    }

    const firstQuoteIndex = str.indexOf('"');
    const lastQuoteIndex = str.lastIndexOf('"');

    if (
      firstQuoteIndex === -1 ||
      lastQuoteIndex === -1 ||
      firstQuoteIndex === lastQuoteIndex
    ) {
      return ""; // No double quotes found or only one quote found
    }

    // Extract the substring between the first and last quotes
    const wordsBetweenQuotes = str
      .slice(firstQuoteIndex + 1, lastQuoteIndex)
      .trim();

    return wordsBetweenQuotes;
  }

  const deviceRowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDeviceSelectedRowKeys(selectedRowKeys);
      setDeviceSelectedRows(selectedRows);
      console.log(selectedRowKeys);
      console.log(selectedRows);
    },
    getCheckboxProps: (record) => ({
      // specify the name of the checkbox input element rendered for each row,
      // can be useful when handling form submissions or performing operations based on the data associated with the checkboxes
      name: record.device_name,
    }),
  };

  /** API to get organisations and set organisation filter */
  const getOrganisationFilter = () => {
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };

    const cb = (data) => {
      setOrganisationFilter(data.organisation_filter);
    };

    fetchHelper(
      BACKEND_URL + "/internalDeviceManagement/getOrganisationFilter",
      cb,
      () => {},
      "Get Organisation Filter",
      () => {},
      () => {},
      requestOptions,
      true
    );
  };

  /** API to get internal device management table data from database */
  const getInternalDeviceManagementData = () => {
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };

    const cb = (data) => {
      setInternalDeviceManagementData(data.internal_device_management_data);
    };

    fetchHelper(
      BACKEND_URL + "/internalDeviceManagement/getInternalDeviceManagementData",
      cb,
      setLoading,
      "Get Internal Device Management Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  };

  /** API to change registration status */
  const changeRegistrationStatus = (device_id, current_registration_status) => {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        device_id: device_id,
        current_registration_status: current_registration_status,
      }),
    };

    const cb = (data) => {
      getInternalDeviceManagementData();
    };

    fetchHelper(
      BACKEND_URL + "/internalDeviceManagement/changeRegistrationStatus",
      cb,
      setLoading,
      "Change Registration Status",
      () => {},
      () => {},
      requestOptions,
      true
    );
  };

  function downloadEachDeviceInfo(device_id) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        device_id: device_id,
      }),
    };
    fetch(
      BACKEND_URL + "/internalDeviceManagement/downloadEachDeviceInfo",
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "IndividualDeviceInfo_" + moment().format("YYYYMMDDThhmm") + ".zip"
        );
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  }

  function downloadSelectedDeviceInfo() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        selected_device_rows: deviceSelectedRows,
      }),
    };
    fetch(
      BACKEND_URL + "/internalDeviceManagement/downloadSelectedDeviceInfo",
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "SelectedDeviceInfo_" + moment().format("YYYYMMDDThhmm") + ".zip"
        );
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  }

  const addNote = (form_values, table_record) => {
    setLoading(true);
    setVisibleModal(null);

    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        device_id: table_record.key,
        admin_note: form_values.AdminNote,
        scheme_type: form_values.SchemeType,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success("Note added successfully.");
        addNoteForm.resetFields();
        getInternalDeviceManagementData();
      }
    };

    fetchHelper(
      BACKEND_URL + "/internalDeviceManagement/addNote",
      cb,
      setLoading,
      "Internal Device Management Add Note",
      () => {},
      () => {},
      requestOptions,
      true
    );
  };

  const sendRejectEmail = (table_record) => {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        device_id: table_record.key,
        organisation_id: table_record.organisation_id,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        getInternalDeviceManagementData();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/internalDeviceManagement/sendRejectEmail",
      cb,
      setLoading,
      "Internal Device Management Send Reject Email",
      () => {},
      () => {},
      requestOptions,
      true
    );
  };

  const sendApproveEmail = (table_record) => {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        device_id: table_record.key,
        organisation_id: table_record.organisation_id,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        getInternalDeviceManagementData();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/internalDeviceManagement/sendApproveEmail",
      cb,
      setLoading,
      "Internal Device Management Send Approve Email",
      () => {},
      () => {},
      requestOptions,
      true
    );
  };

  useEffect(() => {
    getInternalDeviceManagementData();
    getOrganisationFilter();
  }, []);

  return (
    <>
      {current_user != "openre" ? <ErrorPage /> : ""}
      <div
        style={{
          display:
            current_login_status === true && current_user === "openre"
              ? "block"
              : "none",
        }}
      >
        <Card
          id="internal-device-management"
          title={
            <div className="title-heading">
              <h3>Internal Device Management</h3>
              <Button
                onClick={() => {
                  downloadSelectedDeviceInfo();
                }}
              >
                Download Selected Device
              </Button>
            </div>
          }
        >
          <div>
            <Table
              rowSelection={{
                type: "checkbox",
                ...deviceRowSelection,
              }}
              size="midddle"
              scroll={{ x: 2000, y: 700 }}
              columns={columns}
              dataSource={internalDeviceManagementData}
              loading={loading}
            />
          </div>
        </Card>
      </div>
    </>
  );
}

export default InternalDeviceManagement;
