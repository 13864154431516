import "../../styles/DeviceManagement.css";
import dayjs from "dayjs";
import { useState, useEffect, useRef } from "react";
import { BACKEND_URL, FORM_REQUIRED } from "../../config";
import { fetchHelper } from "../../lib/fetch";

import {
  Form,
  Button,
  Input,
  Space,
  DatePicker,
  InputNumber,
  message,
} from "antd";

export function NewTransactionForm(props) {
  const {
    selectedRecordId,
    transactionForm,
    isTransactionModalOpen,
    setIsTransactionModalOpen,
    mode,
    initialValues,
    getTransactionHistoryTableData,
    getRecRecordTableData,
    getPieChartData,
    getRecInventory,
  } = props;

  const currentDate = dayjs().format("YYYY-MM-DD");

  function addNewTransaction(rec_transaction) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        rec_record_id: selectedRecordId,
        transacted_rec: rec_transaction.TransactedRec,
        unit_price: rec_transaction.Transaction.UnitPrice,
        currency: rec_transaction.Transaction.Currency,
        notes: rec_transaction.Notes,
        transaction_date: rec_transaction.TransactionDate.format("YYYY-MM-DD"),
        mode: mode,
      }),
    };
    const cb = (data) => {
      if (data.status === true) {
        message.success(data.message);
        transactionForm.resetFields();
        setIsTransactionModalOpen(false);
        getRecRecordTableData();
        getPieChartData();
        getRecInventory();
      } else {
        message.error(data.message);
      }
    };
    fetchHelper(
      BACKEND_URL + "/device_management/addNewTransaction",
      cb,
      () => {},
      "Add New Transaction",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function editTransaction(rec_transaction) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        rec_transaction_id: initialValues[0].ID,
        transacted_rec: rec_transaction.TransactedRec,
        unit_price: rec_transaction.Transaction.UnitPrice,
        currency: rec_transaction.Transaction.Currency,
        notes: rec_transaction.Notes,
        transaction_date: rec_transaction.TransactionDate.format("YYYY-MM-DD"),
        mode: mode,
      }),
    };
    const cb = (data) => {
      if (data.status === true) {
        message.success(data.message);
        transactionForm.resetFields();
        setIsTransactionModalOpen(false);
        getTransactionHistoryTableData();
        getRecRecordTableData();
        getPieChartData();
        getRecInventory();
      } else {
        message.error(data.message);
      }
    };
    fetchHelper(
      BACKEND_URL + "/device_management/editTransaction",
      cb,
      () => {},
      "Edit Transaction Form",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  // this useEffect is to set the default transaction date
  // setting defaultValue = {dayjs(currentDate)} inside DatePicker does not really work
  // (when click 'Submit' button, there will be error saying "Please select the date!")
  useEffect(() => {
    if (mode === "add") {
      transactionForm.setFieldValue("TransactionDate", dayjs(currentDate));
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "edit") {
      if (initialValues.length) {
        initialValues[0].TransactionDate = dayjs(
          initialValues[0].TransactionDate
        );
        transactionForm.setFieldValue(
          ["Transaction", "UnitPrice"],
          initialValues[0].UnitPrice
        );
        transactionForm.setFieldValue(
          ["Transaction", "Currency"],
          initialValues[0].Currency
        );
        transactionForm.setFieldsValue(initialValues[0]);
      }
    }
  }, [mode, initialValues]);

  return (
    <Form
      form={transactionForm}
      name="rec-transaction-form"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
      }}
      autoComplete="off"
      onFinish={(values) => {
        if (mode === "add") {
          addNewTransaction(values);
        } else {
          editTransaction(values);
        }
      }}
    >
      <Form.Item
        label="RECs To Be Transacted"
        name="TransactedRec"
        rules={[
          {
            required: true,
            message: "Please input how many RECs you want to transact!",
          },
        ]}
      >
        <InputNumber addonAfter="MWh" style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        label={
          <>
            {/* hardcode asterisk as it does not show up */}
            <span style={{ color: "red" }}>*&nbsp;</span>
            <span>Price</span>
          </>
        }
        style={{
          margin: "0px",
        }} /* hardcode margin so that the space does not look weird due to Space.Compact */
      >
        <Space.Compact style={{ width: "100%" }}>
          <Form.Item
            className="form-item-with-error"
            name={["Transaction", "UnitPrice"]}
            style={{ width: "70%" }}
            rules={[
              {
                required: FORM_REQUIRED,
                message: "Please input the price!",
              },
            ]}
            validateTrigger="onBlur" // validate after finishing input
          >
            <Input placeholder="price" />
          </Form.Item>
          <Form.Item
            name={["Transaction", "Currency"]}
            style={{ width: "30%" }}
            className="form-item-with-error"
            rules={[
              {
                required: FORM_REQUIRED,
                message: "Please input the currency!",
              },
            ]}
            validateTrigger="onBlur" // validate after finishing input
          >
            <Input placeholder="currency" />
          </Form.Item>
        </Space.Compact>
      </Form.Item>

      <Form.Item
        label="Date"
        name="TransactionDate"
        rules={[
          {
            required: true,
            message: "Please select the date!",
          },
        ]}
      >
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item label="Notes" name="Notes">
        <Input style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
