import { Button, Divider } from "antd";
import { INDONESIAN_LOCALE } from "../../config";

const mappingDict = {
  "Organisation Name": "Nama Organisasi",
  "Organisation Registration Number": "Nomor Induk Berusaha",
  "Address Line 1": "Alamat Organisasi Baris 1",
  "Address Line 2": "Alamat Organisasi Baris 2",
  City: "Kota",
  Postcode: "Kode Pos",
  State: "Provinsi",
  Country: "Negara",
  Designation: "Sebutan",
  "First Name": "Nama Depan",
  "Last Name": "Nama Belakang",
  Position: "Jabatan",
  Email: "Email",
  "Mobile No": "Nomor Telepon",
  Username: "Nama Pengguna",
  Password: "Kata Sandi",
  "Confirm Password": "Konfirmasi Kata Sandi",
};

function mapValues(data, mappingDict, language) {
  if (language === INDONESIAN_LOCALE) {
    return data.map((section) => {
      const mappedValue = Object.entries(section.value).reduce(
        (acc, [key, value]) => {
          const mappedKey = mappingDict[key] || key; // Use mapped key if exists, else keep original key
          acc[mappedKey] = value;
          return acc;
        },
        {}
      );

      return {
        ...section,
        value: mappedValue,
      };
    });
  } else {
    return data; // Return original data if the language is not `id_ID`
  }
}

export function SignUpConfirmation({ data, onConfirmClick, onPrevClick }) {
  const language = localStorage.getItem("language");

  const mappedData = mapValues(data, mappingDict, language);

  return (
    <>
      {mappedData.map((item, _) =>
        item ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontWeight: "var(--title-font-weight)",
                fontSize: "var(--title-font-size)",
              }}
            >
              {item.title}
            </span>
            <Divider style={{ margin: "5px" }} />
            {Object.entries(item.value)
              .filter(
                ([key, value]) =>
                  key != "Designation Id" && key != "Nationality Id"
              )
              .map(([key, value]) => {
                if (
                  key
                    .toLowerCase()
                    .includes("confirm password".toLowerCase()) ||
                  key
                    .toLowerCase()
                    .includes("konfirmasi kata sandi".toLowerCase())
                )
                  return <></>;

                if (
                  value &&
                  (key.toLowerCase().includes("password".toLowerCase()) ||
                    key.toLowerCase().includes("kata sandi".toLowerCase()))
                )
                  value = "*".repeat(value.length);

                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "2px 0",
                      maxWidth: "100%",
                    }}
                  >
                    <div style={{ maxWidth: "50%" }}>{key}</div>
                    <div style={{ maxWidth: "50%", wordWrap: "break-word" }}>
                      {value ?? "-"}
                    </div>
                  </div>
                );
              })}
            <div style={{ padding: "20px" }}></div>
          </div>
        ) : (
          <></>
        )
      )}

      <div style={{ textAlign: "right" }}>
        <Button
          style={{ background: "white", color: "black", marginRight: "5px" }}
          onClick={onPrevClick}
        >
          {language === INDONESIAN_LOCALE ? "Sebelumnya" : "Previous"}
        </Button>
        <Button
          style={{ background: "var(--theme-dark-blue)", color: "white" }}
          onClick={onConfirmClick}
        >
          {language === INDONESIAN_LOCALE ? "Konfirmasi" : "Confirm"}
        </Button>
      </div>
    </>
  );
}
