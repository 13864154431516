import "../styles/ErrorPage.css";
import "../styles/DeviceManagement.css";
import { AssetGeneralInformation } from "../components/device_management/AssetGeneralInformation";
import { UserIssueRequestForm } from "../components/device_management/UserIssueRequestForm";
import { AdminIssueRequestForm } from "../components/device_management/AdminIssueRequestForm";
import { RecRecordTable } from "../components/device_management/RecRecordTable";
import { useState, useEffect } from "react";
import { BACKEND_URL, INDONESIAN_LOCALE } from "../config";
import { fetchHelper } from "../lib/fetch";
import { Form, Button, Select, Card, Tooltip, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

function DeviceManagement() {
  const queryParameters = new URLSearchParams(window.location.search);

  const language = localStorage.getItem("language");

  const current_login_status = JSON.parse(
    sessionStorage.getItem("loginData")
  )?.login_status;

  const current_user = JSON.parse(
    sessionStorage.getItem("loginData")
  )?.username;

  const [loading, setLoading] = useState(false);

  // device selection related states
  const [deviceList, setDeviceList] = useState([]);
  const [deviceSelect, setDeviceSelect] = useState(null);

  // user add issue request modal related functions and states
  const [userAddIssueRequestForm] = Form.useForm();
  const [isUserAddIssueModalOpen, setIsUserAddIssueModalOpen] = useState(false);

  // admin add issue request modal related functions and states
  const [adminAddIssueRequestForm] = Form.useForm();
  const [isAdminAddIssueModalOpen, setIsAdminAddIssueModalOpen] =
    useState(false);

  // rec record table related states
  const [recRecordTableData, setRecRecordTableData] = useState([]);

  const [year, setYear] = useState(dayjs().year());

  const [pieChartData, setPieChartData] = useState([]);
  const [recInventoryData, setRecInventoryData] = useState([]);

  // filter list for year
  const [yearFilter, setYearFilter] = useState([]);

  /** API to get year and set year filter */
  const getYearFilter = () => {
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        device_id: deviceSelect,
      }),
    };

    const cb = (data) => {
      setYearFilter(data.year_filter);
    };

    fetchHelper(
      BACKEND_URL + "/device_management/getYearFilter",
      cb,
      () => {},
      "Get Year Filter",
      () => {},
      () => {},
      requestOptions,
      true
    );
  };

  function checkAuthorise(query_device_id) {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        query_device_id: query_device_id,
        username: current_user,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setDeviceSelect(data.device_id);
      }
    };

    fetchHelper(
      BACKEND_URL + "/device_management/checkAuthorise",
      cb,
      setLoading,
      "Check Authorise",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getDeviceSelection() {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ username: current_user }),
    };

    const cb = (data) => {
      if (data.status) {
        setDeviceList(data.device_data);

        if (
          data.device_data.length !== 0 &&
          queryParameters.get("device_id") === null
        ) {
          setDeviceSelect(data.device_data[0]["value"]);
        }
      }
    };

    fetchHelper(
      BACKEND_URL + "/device_management/getDeviceSelection",
      cb,
      setLoading,
      "Get Device Selection",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getRecRecordTableData() {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ device_id: deviceSelect, language: language }),
    };

    const cb = (data) => {
      if (data.status) {
        setRecRecordTableData(data.rec_record_data);
      }
    };

    fetchHelper(
      BACKEND_URL + "/device_management/getRecRecordTableData",
      cb,
      setLoading,
      "Get Rec Record Table Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getRecInventory() {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        device_id: deviceSelect,
        year: year,
      }),
    };

    const cb = (data) => {
      setRecInventoryData(data.rec_inventory_data);
    };

    fetchHelper(
      BACKEND_URL + "/device_management/getRecInventory",
      cb,
      setLoading,
      "Get Rec Inventory",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getPieChartData() {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        device_id: deviceSelect,
        year: year,
        language: language,
      }),
    };

    const cb = (data) => {
      setPieChartData(data.pie_chart_data);
    };

    fetchHelper(
      BACKEND_URL + "/device_management/getPieChartData",
      cb,
      setLoading,
      "Get Pie Chart Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    if (deviceSelect !== null) {
      getRecRecordTableData();
      getYearFilter();
    }
  }, [deviceSelect]);

  useEffect(() => {
    getDeviceSelection();
    if (queryParameters.get("device_id") !== null) {
      checkAuthorise(parseInt(queryParameters.get("device_id")));
    }
  }, []);

  return (
    <>
      <div id="device-management">
        <Card
          id="asset-information"
          title={
            language === INDONESIAN_LOCALE
              ? "Informasi Aset"
              : "Asset Information"
          }
          hoverable={true}
          extra={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p>
                {language === INDONESIAN_LOCALE
                  ? `Nama Perangkat:`
                  : `Device Name:`}
                &nbsp;
              </p>
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                value={deviceSelect}
                options={deviceList}
                onChange={(value) => {
                  setDeviceSelect(value);
                }}
                placeholder={
                  language === INDONESIAN_LOCALE
                    ? "Pilih Perangkat"
                    : "Select Device"
                }
              ></Select>
            </div>
          }
        >
          <AssetGeneralInformation
            deviceSelect={deviceSelect}
            getPieChartData={getPieChartData}
            getRecInventory={getRecInventory}
            year={year}
            setYear={setYear}
            pieChartData={pieChartData}
            setPieChartData={setPieChartData}
            recInventoryData={recInventoryData}
            setRecInventoryData={setRecInventoryData}
          />
        </Card>

        <Card
          id="i-rec-management"
          title={
            language === INDONESIAN_LOCALE
              ? "Manajemen I-REC"
              : "I-RECs Management"
          }
          extra={
            <Tooltip
              title={
                language === INDONESIAN_LOCALE
                  ? "Tambah Permintaan"
                  : "Add Issue Request"
              }
            >
              <Button
                onClick={() => {
                  current_login_status === true && current_user !== "openre"
                    ? setIsUserAddIssueModalOpen(true)
                    : setIsAdminAddIssueModalOpen(true);
                }}
              >
                <PlusOutlined />
              </Button>
            </Tooltip>
          }
          hoverable={true}
        >
          <RecRecordTable
            deviceSelect={deviceSelect}
            recRecordTableData={recRecordTableData}
            loading={loading}
            setLoading={setLoading}
            getRecRecordTableData={getRecRecordTableData}
            getPieChartData={getPieChartData}
            getRecInventory={getRecInventory}
            yearFilter={yearFilter}
            getYearFilter={getYearFilter}
          />
        </Card>

        <Modal
          title={
            language === INDONESIAN_LOCALE
              ? "Tambah Permintaan"
              : "Add Issue Request"
          }
          open={isUserAddIssueModalOpen}
          onCancel={() => {
            setIsUserAddIssueModalOpen(false);
            userAddIssueRequestForm.resetFields();
          }}
          footer={null}
          closable={false}
          maskClosable={false}
        >
          <UserIssueRequestForm
            userIssueRequestForm={userAddIssueRequestForm}
            deviceSelect={deviceSelect}
            mode="add"
            isUserIssueModalOpen={isUserAddIssueModalOpen}
            setIsUserIssueRequestModalOpen={setIsUserAddIssueModalOpen}
            getRecRecordTableData={getRecRecordTableData}
            getPieChartData={getPieChartData}
            getRecInventory={getRecInventory}
            selectedRecordId={0}
            getYearFilter={getYearFilter}
          />
        </Modal>

        <Modal
          title={
            language === INDONESIAN_LOCALE
              ? "Tambah Permintaan"
              : "Add Issue Request"
          }
          open={isAdminAddIssueModalOpen}
          onCancel={() => {
            setIsAdminAddIssueModalOpen(false);
            adminAddIssueRequestForm.resetFields();
          }}
          footer={null}
        >
          <AdminIssueRequestForm
            adminIssueRequestForm={adminAddIssueRequestForm}
            deviceSelect={deviceSelect}
            mode="add"
            isAdminIssueModalOpen={isAdminAddIssueModalOpen}
            setIsAdminIssueRequestModalOpen={setIsAdminAddIssueModalOpen}
            getRecRecordTableData={getRecRecordTableData}
            getPieChartData={getPieChartData}
            getRecInventory={getRecInventory}
            getYearFilter={getYearFilter}
          />
        </Modal>
      </div>
    </>
  );
}

export default DeviceManagement;
