import "../../styles/DeviceManagement.css";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { NewTransactionForm } from "./NewTransactionForm";
import { Button, Table, Popconfirm, Tooltip, Modal, Form, message } from "antd";
import { useState } from "react";
import { BACKEND_URL, INDONESIAN_LOCALE } from "../../config";
import { fetchHelper } from "../../lib/fetch";

export function TransactionHistoryTable(props) {
  const {
    data,
    loading,
    setLoading,
    getTransactionHistoryTableData,
    getRecRecordTableData,
    getPieChartData,
    getRecInventory,
  } = props;

  const language = localStorage.getItem("language");

  const current_login_status = JSON.parse(
    sessionStorage.getItem("loginData")
  )?.login_status;

  const current_user = JSON.parse(
    sessionStorage.getItem("loginData")
  )?.username;

  const [isEditTransactionModalOpen, setIsEditTransactionModalOpen] =
    useState(false);

  const [formInitialValues, setFormInitialValues] = useState({});

  const [editTransactionForm] = Form.useForm();

  const columns = [
    {
      title: "Date",
      dataIndex: "TransactionDate",
      key: "TransactionDate",
    },
    {
      title: "REC Transacted",
      dataIndex: "TransactedRec",
      key: "TransactedRec",
    },
    {
      title: "Unit Price",
      dataIndex: "UnitPrice",
      key: "UnitPrice",
    },
    {
      title: "Total Price",
      dataIndex: "TotalPrice",
      key: "TotalPrice",
    },
    {
      title: "Notes",
      dataIndex: "Notes",
      key: "Notes",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (_, record) => {
        return (
          <>
            <Modal
              open={isEditTransactionModalOpen}
              onCancel={() => {
                setIsEditTransactionModalOpen(false);
              }}
              footer={null}
              title="Edit Transaction"
            >
              <NewTransactionForm
                transactionForm={editTransactionForm}
                isTransactionModalOpen={isEditTransactionModalOpen}
                setIsTransactionModalOpen={setIsEditTransactionModalOpen}
                initialValues={formInitialValues}
                mode="edit"
                getTransactionHistoryTableData={getTransactionHistoryTableData}
                getRecRecordTableData={getRecRecordTableData}
                getPieChartData={getPieChartData}
                getRecInventory={getRecInventory}
              />
            </Modal>

            <div className="table-action-col-container">
              <Tooltip title="Edit">
                <Button
                  onClick={() => {
                    setIsEditTransactionModalOpen(true);
                    getTransactionFormData(record);
                  }}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>

              <Tooltip title="Delete">
                <Popconfirm
                  title="Delete Transaction"
                  description={"Are you sure to delete this transaction?"}
                  onConfirm={() => {
                    deleteTransaction(record);
                  }}
                  okText={language === INDONESIAN_LOCALE ? "Ya" : "Yes"}
                  cancelText={language === INDONESIAN_LOCALE ? "Tidak" : "No"}
                >
                  <Button>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </Tooltip>
            </div>
          </>
        );
      },
    },
  ];

  // Conditionally exclude the "Action" column if the current user is not "openre"
  const filtered_columns =
    current_user === "openre" && current_login_status === true
      ? columns
      : columns.filter((column) => column.key !== "Action");

  function getTransactionFormData(row_record) {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        rec_transaction_id: row_record.ID,
      }),
    };
    const cb = (data) => {
      if (data.status === true) {
        setFormInitialValues(data.rec_transaction_data);
      } else {
        message.error("Fail to get rec transaction form data.");
      }
    };
    fetchHelper(
      BACKEND_URL + "/device_management/getTransactionFormData",
      cb,
      setLoading,
      "Get Transation Form Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function deleteTransaction(rec_transaction) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        rec_transaction_id: rec_transaction.ID,
      }),
    };
    const cb = (data) => {
      if (data.status === true) {
        message.success(data.message);
        getTransactionHistoryTableData();
        getRecRecordTableData();
        getPieChartData();
        getRecInventory();
      } else {
        message.error(data.message);
      }
    };
    fetchHelper(
      BACKEND_URL + "/device_management/deleteTransaction",
      cb,
      () => {},
      "Delete Transaction",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  return (
    <Table
      loading={loading}
      columns={filtered_columns}
      dataSource={data}
      scroll={{ y: 300 }}
    ></Table>
  );
}
