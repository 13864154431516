import "../../styles/DeviceRegistration/DeviceRegistration.css";
import "../../styles/DeviceRegistration/AnalyticPlatform.css";
import "../../styles/General.css";

import { Form, Input, Row, Col, Space, Button, message } from "antd";

import { useEffect } from "react";
import { FORM_REQUIRED, BACKEND_URL, INDONESIAN_LOCALE } from "../../config";
import { InfoCircleOutlined } from "@ant-design/icons";

import { fetchHelper } from "../../lib/fetch";

export function AnalyticPlatform(props) {
  const {
    form,
    onFinish,
    onPrevClick,
    evidence,
    deviceReveaiAnalyticPlatformData,
    deviceInfoData,
  } = props;

  const language = localStorage.getItem("language");

  // username of user currently logged in, to be passed to backend to retrieve organisation user id
  const currentUser = JSON.parse(sessionStorage.getItem("loginData"))?.username;

  /** This function is to check whether device name contains value of not.
   * If it does not, show error.
   * If it does, call saveDraft function to save draft in database.
   */
  function checkDeviceNameEmpty() {
    if (
      deviceInfoData["Device Name"] === undefined ||
      deviceInfoData["Device Name"] === ""
    ) {
      message.error(
        language === INDONESIAN_LOCALE
          ? "Anda harus memastikan nama perangkat di halaman Informasi Perangkat tidak kosong untuk menyimpan draf Anda"
          : "You must make sure device name in Device Information page is not empty in order to save your draft"
      );
    } else {
      saveDraft();
    }
  }

  /** This function is to save draft in database */
  function saveDraft() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        reveai_username: form.getFieldValue("Username") // if form.getFieldValue("Username") is truthy (i.e. not undefined, null, 0, false, or an empty string), pass the value to backend; otherwise, pass an empty string
          ? form.getFieldValue("Username")
          : "",
        reveai_device_id: form.getFieldValue("Device ID")
          ? form.getFieldValue("Device ID")
          : "",
        username: currentUser,
        device_name: deviceInfoData["Device Name"]
          ? deviceInfoData["Device Name"]
          : "",
      }),
    };
    const cb = (data) => {
      if (data.status) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Draf telah disimpan"
            : "Draft has been saved"
        );
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal menyimpan draf"
            : "Failed to save draft"
        );
      }
    };
    fetchHelper(
      BACKEND_URL + "/saveReveaiAnalyticsPlatformDraft",
      cb,
      () => {},
      "Save Reveai Analytics Platform Draft",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getAnalyticsPlatform(DeviceId) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        DeviceId: DeviceId,
        EvidenceSource: evidence["Metering Source"],
      }),
    };

    const cb = (data) => {
      if ("error" in data) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Gagal mendapat data platform analitik REVEAI"
            : data.error
        );
      } else {
        form.setFieldsValue(data);
      }
    };

    fetchHelper(
      BACKEND_URL + "/getAnalyticsPlatform",
      cb,
      () => {},
      "Get Analytics Platform",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    var queryString = window.location.search;
    var queryStringWithoutQuestionMark = queryString.substring(1);

    if (
      queryStringWithoutQuestionMark.length != 0 &&
      props.refreshForm === false
    ) {
      getAnalyticsPlatform(queryStringWithoutQuestionMark);
    }
  }, []);

  // this useEffect is to ensure values of form items retained when next or previous button is clicked
  useEffect(() => {
    form.setFieldsValue(deviceReveaiAnalyticPlatformData);
  }, []);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      className="device-registration-form-container"
      layout="vertical"
      scrollToFirstError
      autoComplete="off"
    >
      <div className="device-registration-outer-container">
        <div className="device-registration-content-container">
          <Row className="device-registration-content-container-row-layout">
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <h3 className="device-registration-h3-style">
                {language === INDONESIAN_LOCALE
                  ? "Daftarkan Perangkat Energi Terbarukan Baru"
                  : "Register New Renewable Energy Device"}
              </h3>
              <div className="device-registration-horizontal-line"></div>
              <h2 className="device-registration-h2-style">
                {language === INDONESIAN_LOCALE
                  ? "Platform Analitik REVEAI"
                  : "REVEAI Analytics Platform"}
              </h2>
              <br />

              <Form.Item
                className="device-registration-long-form-item-style"
                label={
                  language === INDONESIAN_LOCALE ? "Nama Pengguna" : "Username"
                }
                name="Username"
                tooltip={{
                  title:
                    language === INDONESIAN_LOCALE
                      ? "Silakan masukkan nama pengguna Anda untuk platform analitik REVEAI."
                      : "Please input your username for REVEAI analytics platform.",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: FORM_REQUIRED,
                    message:
                      language === INDONESIAN_LOCALE
                        ? "Silakan masukkan nama pengguna Anda untuk platform analitik REVEAI."
                        : "Please input your username for REVEAI analytics platform.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="device-registration-long-form-item-style device-registration-4-margin-bottom"
                label={
                  language === INDONESIAN_LOCALE ? "ID Perangkat" : "Device ID"
                }
                name="Device ID"
                tooltip={{
                  title:
                    language === INDONESIAN_LOCALE
                      ? "Silakan masukkan ID perangkat Anda untuk platform analitik REVEAI."
                      : "Please input your device ID for REVEAI analytics platform.",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: FORM_REQUIRED,
                    message:
                      language === INDONESIAN_LOCALE
                        ? "Silakan masukkan ID perangkat Anda untuk platform analitik REVEAI."
                        : "Please input your device ID for REVEAI analytics platform.",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item>
                <Space className="device-registration-btn-layout">
                  <Button
                    className="device-registration-prev-btn-style"
                    onClick={onPrevClick}
                  >
                    {language === INDONESIAN_LOCALE ? "Sebelumnya" : "Previous"}
                  </Button>
                  {/* <Button className="device-registration-delete-draft-btn-style">
                    Delete Draft
                  </Button> */}
                  <Button
                    className="device-registration-save-draft-btn-style"
                    onClick={checkDeviceNameEmpty}
                  >
                    {language === INDONESIAN_LOCALE
                      ? "Simpan Draf"
                      : "Save Draft"}
                  </Button>
                  <Button
                    className="device-registration-next-btn-style"
                    htmlType="submit"
                  >
                    {language === INDONESIAN_LOCALE ? "Dikirimkan" : "Submit"}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    </Form>
  );
}
