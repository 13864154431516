import "../../styles/DeviceRegistration/DeviceRegistration.css";
import "../../styles/DeviceRegistration/SupportingDocuments.css";
import "../../styles/General.css";

import {
  Form,
  Row,
  Col,
  Space,
  Button,
  message,
  Upload,
  Tooltip,
  Skeleton,
  Flex,
} from "antd";

import { useState, useEffect } from "react";
import { BACKEND_URL, INDONESIAN_LOCALE } from "../../config";
import { fetchHelper } from "../../lib/fetch";

import { UploadOutlined } from "@ant-design/icons";
import { InfoCircleOutlined } from "@ant-design/icons";

import SampleSareBill from "../../assets/SampleSareBill.jpeg";
import SampleSareContract from "../../assets/SampleSareContract.jpeg";

export function SupportingDocumentsPPA(props) {
  const { form, onFinish, onPrevClick, deviceInfoData } = props;

  const language = localStorage.getItem("language");

  const [loadingPpaDoc, setLoadingPpaDoc] = useState(true);
  const [loadingSsmCert, setLoadingSsmCert] = useState(true);
  const [loadingPpaSareBill, setLoadingPpaSareBill] = useState(true);
  const [loadingPpaSareContract, setLoadingPpaSareContract] = useState(true);
  const [loadingPpaSareMeterPhoto, setLoadingPpaSareMeterPhoto] =
    useState(true);

  const [ppaDocBackendFileList, setPpaDocBackendFileList] = useState([]);
  const [uploadingPpaDocFile, setUploadingPpaDocFile] = useState(null);

  const [ssmCertBackendFileList, setSsmCertBackendFileList] = useState([]);
  const [uploadingSsmCertFile, setSsmCertFile] = useState(null);

  const [ppaSareBillBackendFileList, setPpaSareBillBackendFileList] = useState(
    []
  );
  const [uploadingPpaSareBillFile, setPpaSareBillFile] = useState(null);

  const [ppaSareContractBackendFileList, setPpaSareContractBackendFileList] =
    useState([]);
  const [uploadingPpaSareContractFile, setPpaSareContractFile] = useState(null);

  const [
    ppaSareMeterPhotoBackendFileList,
    setPpaSareMeterPhotoBackendFileList,
  ] = useState([]);
  const [uploadingPpaSareMeterPhotoFile, setPpaSareMeterPhotoFile] =
    useState(null);

  // username of user currently logged in, to be passed to backend to retrieve organisation id
  const currentUser = JSON.parse(sessionStorage.getItem("loginData"))?.username;

  // ---------------------------- Upload PPA Doc related functions -----------------------------------

  const ppaDocFileList = ppaDocBackendFileList.map((element) => {
    return {
      uid: element.ID,
      name: element.Name,
      status: element.error ? "error" : "done",
      url: element.URL,
    };
  });
  if (uploadingPpaDocFile !== null) {
    // To show upload progress
    ppaDocFileList.unshift(uploadingPpaDocFile);
  }

  function getPpaDocFileList() {
    let params = {
      current_user: currentUser,
      device_name: deviceInfoData["Device Name"],
    };

    let url_param = new URLSearchParams(params);

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setPpaDocBackendFileList(data);
    };

    fetchHelper(
      `${BACKEND_URL}/ppaDocUpload?${url_param}`,
      cb,
      setLoadingPpaDoc,
      "PPA Document Upload",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function uploadPpaDocFile(file) {
    setLoadingPpaDoc(true);
    let forms = new FormData();

    forms.append("file", file);
    forms.append("session_username", currentUser);
    forms.append("device_name", deviceInfoData["Device Name"]);

    // Use XmlHttpRequest to be able to show progress
    // The alternative would be to use axios
    const xhr = new XMLHttpRequest();
    xhr.open("POST", BACKEND_URL + "/ppaDocUpload");

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        const percent = (e.loaded / e.total) * 100;
        setUploadingPpaDocFile({
          name: file.name,
          uid: -1,
          status: "uploading",
          percent: percent,
          url: "",
        });
      }
    };

    xhr.onload = function () {
      if (xhr.status === 200) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil memasukkan data"
            : "Successfully inserted data"
        );
        setUploadingPpaDocFile(null);
        getPpaDocFileList();
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal memasukkan data. Tipe file tidak didukung"
            : "Failed to insert data. File type is not supported."
        );
        setUploadingPpaDocFile(null);
        setLoadingPpaDoc(false);
      }
    };

    xhr.send(forms);
  }

  const customPpaDocRequest = async ({ file, onSuccess, onError }) => {
    try {
      // Your custom logic for uploading the file
      const response = uploadPpaDocFile(file);

      // Check for successful upload
      if (response.status === 200) {
        onSuccess();
      } else {
        onError("Upload failed");
      }
    } catch (error) {
      onError(error.message || "Upload failed");
    }
  };

  // ---------------------------- Upload SSM Doc related functions -----------------------------------

  const ssmCertFileList = ssmCertBackendFileList.map((element) => {
    return {
      uid: element.ID,
      name: element.Name,
      status: element.error ? "error" : "done",
      url: element.URL,
    };
  });
  if (uploadingSsmCertFile !== null) {
    // To show upload progress
    ssmCertFileList.unshift(uploadingSsmCertFile);
  }

  function getSsmCertFileList() {
    let params = {
      current_user: currentUser,
      device_name: deviceInfoData["Device Name"],
    };

    let url_param = new URLSearchParams(params);

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setSsmCertBackendFileList(data);
    };

    fetchHelper(
      `${BACKEND_URL}/ssmCertUpload?${url_param}`,
      cb,
      setLoadingSsmCert,
      "SSM Certificate Upload",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function uploadSsmCertFile(file) {
    setLoadingSsmCert(true);
    let forms = new FormData();

    forms.append("file", file);
    forms.append("session_username", currentUser);
    forms.append("device_name", deviceInfoData["Device Name"]);

    // Use XmlHttpRequest to be able to show progress
    // The alternative would be to use axios
    const xhr = new XMLHttpRequest();
    xhr.open("POST", BACKEND_URL + "/ssmCertUpload");

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        const percent = (e.loaded / e.total) * 100;
        setSsmCertFile({
          name: file.name,
          uid: -1,
          status: "uploading",
          percent: percent,
          url: "",
        });
      }
    };

    xhr.onload = function () {
      if (xhr.status === 200) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil memasukkan data"
            : "Successfully inserted data"
        );
        setSsmCertFile(null);
        getSsmCertFileList();
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal memasukkan data. Tipe file tidak didukung"
            : "Failed to insert data. File type is not supported."
        );
        setSsmCertFile(null);
        setLoadingSsmCert(false);
      }
    };

    xhr.send(forms);
  }

  const customSsmCertRequest = async ({ file, onSuccess, onError }) => {
    try {
      // Your custom logic for uploading the file
      const response = uploadSsmCertFile(file);

      // Check for successful upload
      if (response.status === 200) {
        onSuccess();
      } else {
        onError("Upload failed");
      }
    } catch (error) {
      onError(error.message || "Upload failed");
    }
  };

  // ---------------------------- Upload Ppa Sare Bill related functions -----------------------------------

  const ppaSareBillFileList = ppaSareBillBackendFileList.map((element) => {
    return {
      uid: element.ID,
      name: element.Name,
      status: element.error ? "error" : "done",
      url: element.URL,
    };
  });
  if (uploadingPpaSareBillFile !== null) {
    // To show upload progress
    ppaSareBillFileList.unshift(uploadingPpaSareBillFile);
  }

  function getPpaSareBillFileList() {
    let params = {
      current_user: currentUser,
      device_name: deviceInfoData["Device Name"],
    };

    let url_param = new URLSearchParams(params);

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setPpaSareBillBackendFileList(data);
    };

    fetchHelper(
      `${BACKEND_URL}/ppaSareBillUpload?${url_param}`,
      cb,
      setLoadingPpaSareBill,
      "Ppa Sare Bill Upload",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function uploadPpaSareBillFile(file) {
    setLoadingPpaSareBill(true);
    let forms = new FormData();

    forms.append("file", file);
    forms.append("session_username", currentUser);
    forms.append("device_name", deviceInfoData["Device Name"]);

    // Use XmlHttpRequest to be able to show progress
    // The alternative would be to use axios
    const xhr = new XMLHttpRequest();
    xhr.open("POST", BACKEND_URL + "/ppaSareBillUpload");

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        const percent = (e.loaded / e.total) * 100;
        setPpaSareBillFile({
          name: file.name,
          uid: -1,
          status: "uploading",
          percent: percent,
          url: "",
        });
      }
    };

    xhr.onload = function () {
      if (xhr.status === 200) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil memasukkan data"
            : "Successfully inserted data"
        );
        setPpaSareBillFile(null);
        getPpaSareBillFileList();
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal memasukkan data. Tipe file tidak didukung"
            : "Failed to insert data. File type is not supported."
        );
        setPpaSareBillFile(null);
        setLoadingPpaSareBill(false);
      }
    };

    xhr.send(forms);
  }

  const customPpaSareBillRequest = async ({ file, onSuccess, onError }) => {
    try {
      // Your custom logic for uploading the file
      const response = uploadPpaSareBillFile(file);

      // Check for successful upload
      if (response.status === 200) {
        onSuccess();
      } else {
        onError("Upload failed");
      }
    } catch (error) {
      onError(error.message || "Upload failed");
    }
  };

  // ---------------------------- Upload Ppa Sare Contract related functions -----------------------------------

  const ppaSareContractFileList = ppaSareContractBackendFileList.map(
    (element) => {
      return {
        uid: element.ID,
        name: element.Name,
        status: element.error ? "error" : "done",
        url: element.URL,
      };
    }
  );
  if (uploadingPpaSareContractFile !== null) {
    // To show upload progress
    ppaSareContractFileList.unshift(uploadingPpaSareContractFile);
  }

  function getPpaSareContractFileList() {
    let params = {
      current_user: currentUser,
      device_name: deviceInfoData["Device Name"],
    };

    let url_param = new URLSearchParams(params);

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setPpaSareContractBackendFileList(data);
    };

    fetchHelper(
      `${BACKEND_URL}/ppaSareContractUpload?${url_param}`,
      cb,
      setLoadingPpaSareContract,
      "Ppa Sare Contract Upload",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function uploadPpaSareContractFile(file) {
    setLoadingPpaSareContract(true);
    let forms = new FormData();

    forms.append("file", file);
    forms.append("session_username", currentUser);
    forms.append("device_name", deviceInfoData["Device Name"]);

    // Use XmlHttpRequest to be able to show progress
    // The alternative would be to use axios
    const xhr = new XMLHttpRequest();
    xhr.open("POST", BACKEND_URL + "/ppaSareContractUpload");

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        const percent = (e.loaded / e.total) * 100;
        setPpaSareContractFile({
          name: file.name,
          uid: -1,
          status: "uploading",
          percent: percent,
          url: "",
        });
      }
    };

    xhr.onload = function () {
      if (xhr.status === 200) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil memasukkan data"
            : "Successfully inserted data"
        );
        setPpaSareContractFile(null);
        getPpaSareContractFileList();
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal memasukkan data. Tipe file tidak didukung"
            : "Failed to insert data. File type is not supported."
        );
        setPpaSareContractFile(null);
        setLoadingPpaSareContract(false);
      }
    };

    xhr.send(forms);
  }

  const customPpaSareContractRequest = async ({ file, onSuccess, onError }) => {
    try {
      // Your custom logic for uploading the file
      const response = uploadPpaSareContractFile(file);

      // Check for successful upload
      if (response.status === 200) {
        onSuccess();
      } else {
        onError("Upload failed");
      }
    } catch (error) {
      onError(error.message || "Upload failed");
    }
  };

  // ---------------------------- Upload Ppa Sare Meter Photo related functions -----------------------------------

  const ppaSareMeterPhotoFileList = ppaSareMeterPhotoBackendFileList.map(
    (element) => {
      return {
        uid: element.ID,
        name: element.Name,
        status: element.error ? "error" : "done",
        url: element.URL,
      };
    }
  );
  if (uploadingPpaSareMeterPhotoFile !== null) {
    // To show upload progress
    ppaSareMeterPhotoFileList.unshift(uploadingPpaSareMeterPhotoFile);
  }

  function getPpaSareMeterPhotoFileList() {
    let params = {
      current_user: currentUser,
      device_name: deviceInfoData["Device Name"],
    };

    let url_param = new URLSearchParams(params);

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setPpaSareMeterPhotoBackendFileList(data);
    };

    fetchHelper(
      `${BACKEND_URL}/ppaSareMeterPhotoUpload?${url_param}`,
      cb,
      setLoadingPpaSareMeterPhoto,
      "Ppa Sare Meter Photo Upload",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function uploadPpaSareMeterPhotoFile(file) {
    setLoadingPpaSareMeterPhoto(true);
    let forms = new FormData();

    forms.append("file", file);
    forms.append("session_username", currentUser);
    forms.append("device_name", deviceInfoData["Device Name"]);

    // Use XmlHttpRequest to be able to show progress
    // The alternative would be to use axios
    const xhr = new XMLHttpRequest();
    xhr.open("POST", BACKEND_URL + "/ppaSareMeterPhotoUpload");

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        const percent = (e.loaded / e.total) * 100;
        setPpaSareMeterPhotoFile({
          name: file.name,
          uid: -1,
          status: "uploading",
          percent: percent,
          url: "",
        });
      }
    };

    xhr.onload = function () {
      if (xhr.status === 200) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil memasukkan data"
            : "Successfully inserted data"
        );
        setPpaSareMeterPhotoFile(null);
        getPpaSareMeterPhotoFileList();
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal memasukkan data. Tipe file tidak didukung"
            : "Failed to insert data. File type is not supported."
        );
        setPpaSareMeterPhotoFile(null);
        setLoadingPpaSareMeterPhoto(false);
      }
    };

    xhr.send(forms);
  }

  const customPpaSareMeterPhotoRequest = async ({
    file,
    onSuccess,
    onError,
  }) => {
    try {
      // Your custom logic for uploading the file
      const response = uploadPpaSareMeterPhotoFile(file);

      // Check for successful upload
      if (response.status === 200) {
        onSuccess();
      } else {
        onError("Upload failed");
      }
    } catch (error) {
      onError(error.message || "Upload failed");
    }
  };

  // ------------------------- Delete function for all files -----------------------------------

  function deleteFile(file) {
    // set loading when file is being uploaded
    setLoadingPpaDoc(true);
    setLoadingSsmCert(true);
    setLoadingPpaSareBill(true);
    setLoadingPpaSareContract(true);
    setLoadingPpaSareMeterPhoto(true);

    fetch(BACKEND_URL + "/supportingDocumentUpload/" + file.uid, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    }).then((response) => {
      if (response.status === 200) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil menghapus data"
            : "Successfully deleted data"
        );
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal menghapus data"
            : "Failed to delete data"
        );
      }
      getPpaDocFileList();
      getSsmCertFileList();
      getPpaSareBillFileList();
      getPpaSareContractFileList();
      getPpaSareMeterPhotoFileList();
    });
  }

  useEffect(() => {
    getPpaDocFileList();
    getSsmCertFileList();
    getPpaSareBillFileList();
    getPpaSareContractFileList();
    getPpaSareMeterPhotoFileList();
  }, []);

  return (
    <Form
      form={form}
      onFinish={() => {
        if (ppaDocFileList.length === 0) {
          message.error(
            language === INDONESIAN_LOCALE
              ? "Silahkan upload Dokumen Perjanjian Jual Beli Tenaga Listrik (PPA)"
              : "Please upload PPA Document"
          );
        }

        if (ssmCertFileList.length === 0) {
          message.error(
            language === INDONESIAN_LOCALE
              ? "Silahkan upload Sertifikat SSM"
              : "Please upload SSM Certificate"
          );
        }

        if (
          ppaSareBillFileList.length !== 0 &&
          ppaSareMeterPhotoFileList.length === 0
        ) {
          message.error(
            language === INDONESIAN_LOCALE
              ? "Silahkan upload Sertifikat SSM"
              : "Anda wajib mengunggah Foto Meteran PPA Sare jika sudah mengunggah tagihan SARE"
          );
        } else if (
          ppaDocFileList.length !== 0 &&
          ssmCertFileList.length !== 0
        ) {
          onFinish();
        }
      }}
      className="device-registration-form-container"
      layout="vertical"
      scrollToFirstError
      autoComplete="off" // !important -> so that previously entered (and cleared) value is not remembered
    >
      <div
        id="device-registration-supporting-documents"
        className="device-registration-outer-container"
      >
        <div className="device-registration-content-container">
          <Row className="device-registration-content-container-row-layout">
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <h3 className="device-registration-h3-style">
                {language === INDONESIAN_LOCALE
                  ? "Daftarkan Perangkat Energi Terbarukan Baru"
                  : "Register New Renewable Energy Device"}
              </h3>
              <div className="device-registration-horizontal-line"></div>
              <h2 className="device-registration-h2-style">
                {language === INDONESIAN_LOCALE
                  ? "Dokumen Pendukung untuk Perjanjian Jual Beli Tenaga Listrik (PPA)"
                  : `Supporting Documents for Power Purchase Agreement (PPA)`}
              </h2>
              <br />

              {/* Display the uploading form items when all the files have been uploaded or fetched from backend */}
              <div
                style={{
                  display:
                    !loadingPpaDoc &&
                    !loadingSsmCert &&
                    !loadingPpaSareBill &&
                    !loadingPpaSareContract &&
                    !loadingPpaSareMeterPhoto
                      ? "block"
                      : "none",
                }}
              >
                <div className="device-registration-2-row-layout">
                  <div className="device-registration-2-wording-container">
                    <Flex gap="4px">
                      <h4>
                        {language === INDONESIAN_LOCALE
                          ? "Dokumen Perjanjian Jual Beli Tenaga Listrik (PPA)"
                          : "PPA Document"}
                      </h4>
                      <Tooltip
                        title={
                          language === INDONESIAN_LOCALE
                            ? "Anda hanya dapat mengunggah maksimal 5 dokumen. Jika Anda melebihi batas dan ingin mengunggah file lain, harap hapus file lama, baru Anda dapat mengunggah file baru."
                            : "You can only upload a maximum of 5 documents. If you exceed the limit and want to upload another file, please delete the old one then only you can upload the new one."
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Flex>
                    <h5>
                      {language === INDONESIAN_LOCALE
                        ? "Untuk membuktikan kepemilikan atribut lingkungan"
                        : "To prove the ownership of the environmental attributes"}
                    </h5>
                  </div>
                  <div className="device-registration-2-upload-btn-container">
                    <Upload
                      name="file"
                      className="device-registration-2-upload-btn"
                      fileList={ppaDocFileList}
                      customRequest={customPpaDocRequest}
                      onRemove={deleteFile}
                      onChange={(info) => {
                        if (info.file.status !== "uploading") {
                          console.log(info.file, info.fileList);
                        }
                        if (info.file.status === "done") {
                          message.success(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file berhasil diunggah`
                              : `${info.file.name} file uploaded successfully`
                          );
                        } else if (info.file.status === "error") {
                          message.error(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file gagal diunggah`
                              : `${info.file.name} file upload failed.`
                          );
                        }
                      }}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        disabled={
                          uploadingPpaDocFile !== null ||
                          ppaDocFileList.length === 5
                        }
                      >
                        {language === INDONESIAN_LOCALE
                          ? "Klik untuk Unggah"
                          : "Click to Upload"}
                      </Button>
                    </Upload>
                  </div>
                </div>

                <div className="device-registration-2-row-layout">
                  <div className="device-registration-2-wording-container">
                    <Flex gap="4px">
                      <h4>
                        {language === INDONESIAN_LOCALE
                          ? "Sertifikat SSM"
                          : "SSM Certificate"}
                      </h4>
                      <Tooltip
                        title={
                          language === INDONESIAN_LOCALE
                            ? "Anda hanya dapat mengunggah maksimal 1 sertifikat SSM. Jika Anda ingin mengunggah file lain, harap hapus file lama, baru Anda dapat mengunggah file baru."
                            : "You can only upload a maximum of 1 SSM certificate. If you want to upload another file, please delete the old one then only you can upload the new one."
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Flex>
                    <h5>
                      {language === INDONESIAN_LOCALE
                        ? "Untuk membuktikan kepemilikan atribut lingkungan"
                        : "To prove the ownership of the environmental attributes"}
                    </h5>
                  </div>
                  <div className="device-registration-2-upload-btn-container">
                    <Upload
                      name="file"
                      className="device-registration-2-upload-btn"
                      fileList={ssmCertFileList}
                      customRequest={customSsmCertRequest}
                      onRemove={deleteFile}
                      onChange={(info) => {
                        if (info.file.status !== "uploading") {
                          console.log(info.file, info.fileList);
                        }
                        if (info.file.status === "done") {
                          message.success(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file berhasil diunggah`
                              : `${info.file.name} file uploaded successfully`
                          );
                        } else if (info.file.status === "error") {
                          message.error(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file gagal diunggah`
                              : `${info.file.name} file upload failed.`
                          );
                        }
                      }}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        disabled={
                          uploadingSsmCertFile !== null ||
                          ssmCertFileList.length === 1
                        }
                      >
                        {language === INDONESIAN_LOCALE
                          ? "Klik untuk Unggah"
                          : "Click to Upload"}
                      </Button>
                    </Upload>
                  </div>
                </div>

                <div className="device-registration-2-row-layout">
                  <div className="device-registration-2-wording-container">
                    <Flex gap="4px">
                      <h4>
                        {language === INDONESIAN_LOCALE
                          ? "Tagihan SARE (jika ada)"
                          : "SARE Bill (if available)"}
                      </h4>
                      <Tooltip
                        title={
                          <div>
                            <h5>
                              {language === INDONESIAN_LOCALE
                                ? `Untuk melihat contoh Kontrak SARE, Anda dapat mengeklik `
                                : `To have a look at the sample SARE Bill, you can
                              click `}
                              <a
                                href={SampleSareBill}
                                download="sample_sare_bill.jpeg"
                              >
                                {language === INDONESIAN_LOCALE
                                  ? "Tagihan SARE"
                                  : "SARE Bill"}
                              </a>{" "}
                              {language === INDONESIAN_LOCALE
                                ? `untuk mengunduh contoh berkas. Anda hanya dapat mengunggah maksimal 1 RUU SARE. Jika Anda ingin mengunggah berkas lain, hapus berkas lama, baru Anda dapat mengunggah berkas baru.`
                                : `to download the sample file. You can only upload a
                              maximum of 1 SARE Bill. If you want to upload
                              another file, please delete the old one then only
                              you can upload the new one.`}
                            </h5>
                          </div>
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Flex>
                  </div>
                  <div className="device-registration-2-upload-btn-container">
                    <Upload
                      name="file"
                      className="device-registration-2-upload-btn"
                      fileList={ppaSareBillFileList}
                      customRequest={customPpaSareBillRequest}
                      onRemove={deleteFile}
                      onChange={(info) => {
                        if (info.file.status !== "uploading") {
                          console.log(info.file, info.fileList);
                        }
                        if (info.file.status === "done") {
                          message.success(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file berhasil diunggah`
                              : `${info.file.name} file uploaded successfully`
                          );
                        } else if (info.file.status === "error") {
                          message.error(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file gagal diunggah`
                              : `${info.file.name} file upload failed.`
                          );
                        }
                      }}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        disabled={
                          uploadingPpaSareBillFile !== null ||
                          ppaSareBillFileList.length === 1
                        }
                      >
                        {language === INDONESIAN_LOCALE
                          ? "Klik untuk Unggah"
                          : "Click to Upload"}
                      </Button>
                    </Upload>
                  </div>
                </div>

                <div className="device-registration-2-row-layout">
                  <div className="device-registration-2-wording-container">
                    <Flex gap="4px">
                      <h4>
                        {language === INDONESIAN_LOCALE
                          ? `Kontrak SARE (jika ada)`
                          : `SARE Contract (if available)`}
                      </h4>
                      <Tooltip
                        title={
                          <div>
                            <h5>
                              {language === INDONESIAN_LOCALE
                                ? `Untuk melihat contoh Kontrak SARE, Anda dapat mengeklik `
                                : `To have a look at the sample SARE Contract, you
                              can click `}
                              <a
                                href={SampleSareContract}
                                download="sample_sare_contract.jpeg"
                              >
                                {language === INDONESIAN_LOCALE
                                  ? `Kontrak SARE`
                                  : `SARE Contract`}
                              </a>{" "}
                              {language === INDONESIAN_LOCALE
                                ? ` untuk mengunduh contoh berkas. Anda hanya dapat mengunggah maksimal 1 RUU SARE. Jika Anda ingin mengunggah berkas lain, hapus berkas lama, baru Anda dapat mengunggah berkas baru.`
                                : ` to download the sample file. You can only upload a
                              maximum of 1 SARE Contract. If you want to upload
                              another file, please delete the old one then only
                              you can upload the new one.`}
                            </h5>
                          </div>
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Flex>
                  </div>
                  <div className="device-registration-2-upload-btn-container">
                    <Upload
                      name="file"
                      className="device-registration-2-upload-btn"
                      fileList={ppaSareContractFileList}
                      customRequest={customPpaSareContractRequest}
                      onRemove={deleteFile}
                      onChange={(info) => {
                        if (info.file.status !== "uploading") {
                          console.log(info.file, info.fileList);
                        }
                        if (info.file.status === "done") {
                          message.success(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file berhasil diunggah`
                              : `${info.file.name} file uploaded successfully`
                          );
                        } else if (info.file.status === "error") {
                          message.error(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file gagal diunggah`
                              : `${info.file.name} file upload failed.`
                          );
                        }
                      }}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        disabled={
                          uploadingPpaSareContractFile !== null ||
                          ppaSareContractFileList.length === 1
                        }
                      >
                        {language === INDONESIAN_LOCALE
                          ? "Klik untuk Unggah"
                          : "Click to Upload"}
                      </Button>
                    </Upload>
                  </div>
                </div>

                <div className="device-registration-2-row-layout">
                  <div className="device-registration-2-wording-container">
                    <Flex gap={"4px"}>
                      <h4>
                        {language === INDONESIAN_LOCALE
                          ? `Foto Meter (jika Anda telah mengunggah tagihan SARE)`
                          : `Meter Photo (if you have uploaded SARE bill)`}
                      </h4>
                      <Tooltip
                        title={
                          language === INDONESIAN_LOCALE
                            ? "Anda hanya dapat mengunggah foto berukuran maksimal 1 meter. Jika Anda ingin mengunggah file lain, harap hapus file lama, baru Anda dapat mengunggah file baru."
                            : "You can only upload a maximum of 1 meter photo. If you want to upload another file, please delete the old one then only you can upload the new one."
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Flex>
                  </div>
                  <div className="device-registration-2-upload-btn-container">
                    <Upload
                      name="file"
                      className="device-registration-2-upload-btn"
                      fileList={ppaSareMeterPhotoFileList}
                      customRequest={customPpaSareMeterPhotoRequest}
                      onRemove={deleteFile}
                      onChange={(info) => {
                        if (info.file.status !== "uploading") {
                          console.log(info.file, info.fileList);
                        }
                        if (info.file.status === "done") {
                          message.success(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file berhasil diunggah`
                              : `${info.file.name} file uploaded successfully`
                          );
                        } else if (info.file.status === "error") {
                          message.error(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file gagal diunggah`
                              : `${info.file.name} file upload failed.`
                          );
                        }
                      }}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        disabled={
                          uploadingPpaSareMeterPhotoFile !== null ||
                          ppaSareMeterPhotoFileList.length === 1
                        }
                      >
                        {language === INDONESIAN_LOCALE
                          ? "Klik untuk Unggah"
                          : "Click to Upload"}
                      </Button>
                    </Upload>
                  </div>
                </div>
              </div>

              {/* Display loading when any of the files have not been uploaded or fetched from backend */}
              <div
                style={{
                  display:
                    loadingPpaDoc ||
                    loadingSsmCert ||
                    loadingPpaSareBill ||
                    loadingPpaSareContract ||
                    loadingPpaSareMeterPhoto
                      ? "block"
                      : "none",
                }}
              >
                <Skeleton
                  active={true}
                  paragraph={{ rows: 10 }}
                  style={{ padding: "2rem", maxWidth: "70%", margin: "auto" }}
                />
              </div>

              <Form.Item>
                <Space className="device-registration-btn-layout">
                  <Button
                    className="device-registration-prev-btn-style"
                    onClick={onPrevClick}
                  >
                    {language === INDONESIAN_LOCALE ? "Sebelumnya" : "Previous"}
                  </Button>
                  {/* <Button className="device-registration-delete-draft-btn-style">
                    Delete Draft
                  </Button>
                  <Button className="device-registration-save-draft-btn-style">
                    Save Draft
                  </Button> */}
                  <Button
                    className="device-registration-next-btn-style"
                    htmlType="submit"
                  >
                    {language === INDONESIAN_LOCALE ? "Berikutnya" : "Next"}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    </Form>
  );
}
