import "../styles/ErrorPage.css";

import { Form, Button, Select, Input, Card, Space, Table } from "antd";
import { useState, useEffect } from "react";

function ErrorPage() {

  return (
    <>
      <Card id="error-page">
            <h1 className="error-text-title">404</h1>
            <h2 className="error-text-page-not-found">Page Not Found</h2>
            <div style={{width: '100%'}}>
                <p className="error-text-description">The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
            </div>
      </Card>
    </>
  );
}

export default ErrorPage;
