let current_url = "";
// const port_number = ":5057"; // port number for local development
const port_number = ":5000"; // port number for cloud deployment, rmb to change package.json port to "start": "set PORT=3000 && react-scripts start" too

if (window.location.href.split(":").length === 2) {
  //frontend url has no port number
  current_url = window.location.href.split("/").slice(0, 3);
  current_url[current_url.length - 1] += port_number;
  current_url = current_url.join("/");
} else {
  current_url =
    window.location.href.split(":").slice(0, 2).join(":") + port_number;
}

export const BACKEND_URL = current_url;

export const FORM_REQUIRED = true;

export const INDONESIAN_LOCALE = "id_ID"; // ant design Indonesian
export const ENGLISH_UK_LOCALE = "en_GB"; // ant design English (United Kingdom)
