import "../../styles/DeviceManagement.css";
import { NewTransactionForm } from "./NewTransactionForm";
import { TransactionHistoryTable } from "./TransactionHistoryTable";
import { UserIssueRequestForm } from "./UserIssueRequestForm";
import { AdminIssueRequestForm } from "./AdminIssueRequestForm";
import { useState, useEffect } from "react";
import { BACKEND_URL, INDONESIAN_LOCALE } from "../../config";
import { fetchHelper } from "../../lib/fetch";

import {
  Form,
  Button,
  Table,
  Popconfirm,
  Tooltip,
  Modal,
  Tabs,
  message,
  Input,
} from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";

import moment from "moment";

export function RecRecordTable(props) {
  const {
    deviceSelect,
    recRecordTableData,
    loading,
    setLoading,
    getRecRecordTableData,
    getPieChartData,
    getRecInventory,
    yearFilter,
    getYearFilter,
  } = props;

  const language = localStorage.getItem("language");

  const current_login_status = JSON.parse(
    sessionStorage.getItem("loginData")
  )?.login_status;

  const current_user = JSON.parse(
    sessionStorage.getItem("loginData")
  )?.username;

  const [disableDownload, setDisableDownload] = useState(true);

  const [userEditIssueRequestForm] = Form.useForm();
  const [adminEditIssueRequestForm] = Form.useForm();

  const [newTransactionForm] = Form.useForm();

  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(1);

  const [isUserTransactionModalOpen, setIsUserTransactionModalOpen] =
    useState(false);

  const [isUserEditIssueRequestModalOpen, setIsUserEditIssueRequestModalOpen] =
    useState(false);
  const [
    isAdminEditIssueRequestModalOpen,
    setIsAdminEditIssueRequestModalOpen,
  ] = useState(false);

  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);

  const [formInitialValues, setFormInitialValues] = useState({});

  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const [transactionHistoryTableData, setTransactionHistoryTableData] =
    useState([]);

  // transaction tab related functions and states
  const transaction_items = [
    {
      key: "1",
      label: "New Transaction",
      children: (
        <NewTransactionForm
          selectedRecordId={selectedRecordId}
          transactionForm={newTransactionForm}
          isTransactionModalOpen={isTransactionModalOpen}
          setIsTransactionModalOpen={setIsTransactionModalOpen}
          mode="add"
          getTransactionHistoryTableData={getTransactionHistoryTableData}
          getRecRecordTableData={getRecRecordTableData}
          getPieChartData={getPieChartData}
          getRecInventory={getRecInventory}
        />
      ),
    },
    {
      key: "2",
      label: "Transaction History",
      children: (
        <TransactionHistoryTable
          data={transactionHistoryTableData}
          loading={loading}
          setLoading={setLoading}
          getTransactionHistoryTableData={getTransactionHistoryTableData}
          getRecRecordTableData={getRecRecordTableData}
          getPieChartData={getPieChartData}
          getRecInventory={getRecInventory}
        />
      ),
    },
  ];

  const columns = [
    {
      title: language === INDONESIAN_LOCALE ? "Tahun" : "Year",
      dataIndex: "Year",
      key: "Year",
      fixed: "left",
      filters: yearFilter,
      onFilter: (value, record) => record.Year === value,
    },
    {
      title: language === INDONESIAN_LOCALE ? "Tanggal Mulai" : "Start Date",
      dataIndex: "StartDate",
      key: "StartDate",
    },
    {
      title: language === INDONESIAN_LOCALE ? "Tanggal Akhir" : "End Date",
      dataIndex: "EndDate",
      key: "EndDate",
    },
    {
      title:
        language === INDONESIAN_LOCALE
          ? "Periode Produksi"
          : "Period Production",
      dataIndex: "PeriodProductionInMWh",
      key: "PeriodProduction",
    },
    {
      title:
        language === INDONESIAN_LOCALE ? "Pembaruan Terakhir" : "Last Update",
      dataIndex: "LastUpdate",
      key: "LastUpdate",
    },
    {
      title:
        language === INDONESIAN_LOCALE
          ? "I-REC yang Diterbitkan"
          : "Issued I-REC",
      dataIndex: "IssuedRec",
      key: "IssuedRec",
    },
    {
      title:
        language === INDONESIAN_LOCALE
          ? "I-REC yang Ditransaksikan"
          : "Transacted I-REC",
      dataIndex: "TransactedRec",
      key: "TransactedRec",
    },
    {
      title:
        language === INDONESIAN_LOCALE
          ? "I-REC yang Tersisa"
          : "Remaining I-REC",
      dataIndex: "RemainingRec",
      key: "RemainingRec",
    },
    {
      title: language === INDONESIAN_LOCALE ? "Status REC" : "REC Status",
      dataIndex: "RecStatus",
      key: "RecStatus",
    },
    {
      title: language === INDONESIAN_LOCALE ? "	Tindakan" : "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <>
            {current_login_status === true && current_user !== "openre" ? (
              <div className="table-action-col-container">
                <Tooltip
                  title={language === INDONESIAN_LOCALE ? "Sunting" : "Edit"}
                >
                  <Button
                    onClick={() => {
                      setIsUserEditIssueRequestModalOpen(true);
                      getIssueRequestFormData(record);
                      setSelectedRecordId(record.ID);
                    }}
                    disabled={
                      record.RecStatus === "Submitted" ||
                      record.RecStatus === "Issued" ||
                      record.RecStatus === "Dihantar" ||
                      record.RecStatus === "Diterbitkan"
                    }
                  >
                    <EditOutlined />
                  </Button>
                </Tooltip>

                <Tooltip
                  title={language === INDONESIAN_LOCALE ? "Hapus" : "Delete"}
                >
                  <Popconfirm
                    title={
                      language === INDONESIAN_LOCALE
                        ? "Hapus Permintaan"
                        : "Delete Issue Request"
                    }
                    description={
                      language === INDONESIAN_LOCALE
                        ? "Apakah anda yakin ingin menghapus permintaan ini?"
                        : "Are you sure to delete this issue request?"
                    }
                    onConfirm={() => {
                      deleteRecRecord(record);
                    }}
                    okText={language === INDONESIAN_LOCALE ? "Ya" : "Yes"}
                    cancelText={language === INDONESIAN_LOCALE ? "Tidak" : "No"}
                  >
                    <Button
                      disabled={
                        record.RecStatus === "Submitted" ||
                        record.RecStatus === "Issued" ||
                        record.RecStatus === "Dihantar" ||
                        record.RecStatus === "Diterbitkan"
                      }
                    >
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </Tooltip>

                <Tooltip
                  title={
                    language === INDONESIAN_LOCALE
                      ? "Riwayat Transaksi"
                      : "Transaction History"
                  }
                >
                  <Button
                    onClick={() => {
                      setIsUserTransactionModalOpen(true);
                      setSelectedRecordId(record.ID);
                    }}
                  >
                    <h4>$</h4>
                  </Button>
                </Tooltip>
              </div>
            ) : (
              <>
                <div className="table-action-col-container">
                  <Tooltip title="Edit">
                    <Button
                      onClick={() => {
                        setIsAdminEditIssueRequestModalOpen(true);
                        getIssueRequestFormData(record);
                      }}
                    >
                      <EditOutlined />
                    </Button>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <Popconfirm
                      title="Delete Issue Request"
                      description={"Are you sure to delete this issue request?"}
                      onConfirm={() => {
                        deleteRecRecord(record);
                      }}
                      okText={language === INDONESIAN_LOCALE ? "Ya" : "Yes"}
                      cancelText={
                        language === INDONESIAN_LOCALE ? "Tidak" : "No"
                      }
                    >
                      <Button>
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                  </Tooltip>

                  <Tooltip title="Download">
                    <Button
                      disabled={
                        disableDownload ||
                        record.RecStatus === "Draft" ||
                        record.RecStatus === "Draf"
                      }
                      onClick={() => {
                        downloadIndividualIssueRequest(record.ID);
                      }}
                    >
                      <VerticalAlignBottomOutlined />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    title={
                      record.RecStatus === "Issued" ||
                      record.RecStatus === "Diterbitkan"
                        ? language === INDONESIAN_LOCALE
                          ? "Transaksi I-REC"
                          : "Transact I-REC"
                        : language === INDONESIAN_LOCALE
                        ? "REC perlu diterbitkan terlebih dahulu sebelum bertransaksi"
                        : "REC needs to be issued first before transacting"
                    }
                  >
                    <Button
                      disabled={
                        record.RecStatus !== "Issued" &&
                        record.RecStatus !== "Diterbitkan"
                      }
                      onClick={() => {
                        setIsTransactionModalOpen(true);
                        setSelectedRecordId(record.ID);
                      }}
                    >
                      <h4>$</h4>
                    </Button>
                  </Tooltip>

                  <Tooltip title="Approve">
                    <Popconfirm
                      title="Approve Issue Request"
                      description={
                        "Are you sure to approve this issue request and send email to user?"
                      }
                      onConfirm={() => {
                        approveIssueRequest(record.ID);
                      }}
                      okText={language === INDONESIAN_LOCALE ? "Ya" : "Yes"}
                      cancelText={
                        language === INDONESIAN_LOCALE ? "Tidak" : "No"
                      }
                    >
                      <Button
                        disabled={
                          record.RecStatus === "Issued" ||
                          record.RecStatus === "Diterbitkan"
                        }
                      >
                        <CheckCircleOutlined style={{ color: "green" }} />
                      </Button>
                    </Popconfirm>
                  </Tooltip>

                  <Tooltip title="Reject">
                    <Popconfirm
                      title="Reject Issue Request"
                      description={
                        "Are you sure to reject this issue request and send email to user?"
                      }
                      onConfirm={() => {
                        setIsRejectModalOpen(true);
                        setSelectedRecordId(record.ID);
                      }}
                      okText={language === INDONESIAN_LOCALE ? "Ya" : "Yes"}
                      cancelText={
                        language === INDONESIAN_LOCALE ? "Tidak" : "No"
                      }
                    >
                      <Button
                        disabled={
                          record.RecStatus === "Issued" ||
                          record.RecStatus === "Diterbitkan"
                        }
                      >
                        <CloseCircleOutlined style={{ color: "red" }} />
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                </div>
              </>
            )}
          </>
        );
      },
    },
  ];

  function deleteRecRecord(row_data) {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ rec_record_id: row_data.ID }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        getRecRecordTableData();
        getPieChartData();
        getRecInventory();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/device_management/deleteRecRecord",
      cb,
      setLoading,
      "Delete Rec Record",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getIssueRequestFormData(row_record) {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        rec_record_id: row_record.ID,
      }),
    };
    const cb = (data) => {
      if (data.status === true) {
        setFormInitialValues(data.rec_record_data);
      } else {
        message.error("Fail to get issue request form data.");
      }
    };
    fetchHelper(
      BACKEND_URL + "/device_management/getIssueRequestFormData",
      cb,
      setLoading,
      "Get Issue Request Form Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getTransactionHistoryTableData() {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        rec_record_id: selectedRecordId,
      }),
    };
    const cb = (data) => {
      if (data.status === true) {
        setTransactionHistoryTableData(data.transaction_history_table_data);
        message.success("Get transaction history data successfully.");
      } else {
        message.error("Fail to get transaction history data.");
      }
    };
    fetchHelper(
      BACKEND_URL + "/device_management/getTransactionHistoryTableData",
      cb,
      setLoading,
      "Get Transaction History Table Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function rejectIssueRequest(form_data) {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        rec_record_id: selectedRecordId,
        admin_reject_notes: form_data.AdminRejectNotes,
        device_id: deviceSelect,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        setIsRejectModalOpen(false);
        getRecRecordTableData();
        getPieChartData();
        getRecInventory();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/device_management/rejectIssueRequest",
      cb,
      setLoading,
      "Reject Issue Request",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function approveIssueRequest(rec_record_id) {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        rec_record_id: rec_record_id,
        device_id: deviceSelect,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        getRecRecordTableData();
        getPieChartData();
        getRecInventory();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/device_management/approveIssueRequest",
      cb,
      setLoading,
      "Approve Issue Request",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function downloadIndividualIssueRequest(rec_record_id) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        rec_record_id: rec_record_id,
      }),
    };
    fetch(
      BACKEND_URL + "/device_management/downloadIndividualIssueRequest",
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "IndividualIssueRequest_" + moment().format("YYYYMMDDThhmm") + ".zip"
        );
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  }

  function disableDownloadIndividualIssueRequestButton() {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        device_id: deviceSelect,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setDisableDownload(data.disable_download);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL +
        "/device_management/disableDownloadIndividualIssueRequestButton",
      cb,
      setLoading,
      "Disable Download Individual Issue Request Button",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    if (activeTab === "2") {
      if (selectedRecordId) {
        getTransactionHistoryTableData();
      }
    }
  }, [selectedRecordId, activeTab]);

  useEffect(() => {
    if (isUserTransactionModalOpen == true) {
      if (selectedRecordId) {
        getTransactionHistoryTableData();
      }
    }
  }, [isUserTransactionModalOpen, selectedRecordId]);

  useEffect(() => {
    if (deviceSelect) {
      disableDownloadIndividualIssueRequestButton();
    }
  }, [deviceSelect]);

  return (
    <>
      <Modal
        open={isUserEditIssueRequestModalOpen}
        onCancel={() => {
          setIsUserEditIssueRequestModalOpen(false);
        }}
        footer={null}
        title="Edit Issue Request"
        closable={false}
        maskClosable={false}
      >
        <UserIssueRequestForm
          userIssueRequestForm={userEditIssueRequestForm}
          deviceSelect={deviceSelect}
          initialValues={formInitialValues}
          mode="edit"
          isUserIssueModalOpen={isUserEditIssueRequestModalOpen}
          setIsUserIssueRequestModalOpen={setIsUserEditIssueRequestModalOpen}
          getRecRecordTableData={getRecRecordTableData}
          getPieChartData={getPieChartData}
          getRecInventory={getRecInventory}
          selectedRecordId={selectedRecordId}
          getYearFilter={getYearFilter}
        />
      </Modal>

      <Modal
        open={isAdminEditIssueRequestModalOpen}
        onCancel={() => {
          setIsAdminEditIssueRequestModalOpen(false);
        }}
        footer={null}
        title="Edit Issue Request"
      >
        <AdminIssueRequestForm
          adminIssueRequestForm={adminEditIssueRequestForm}
          deviceSelect={deviceSelect}
          isAdminIssueRequestModalOpen={isAdminEditIssueRequestModalOpen}
          setIsAdminIssueRequestModalOpen={setIsAdminEditIssueRequestModalOpen}
          initialValues={formInitialValues}
          mode="edit"
          getRecRecordTableData={getRecRecordTableData}
          getPieChartData={getPieChartData}
          getRecInventory={getRecInventory}
          getYearFilter={getYearFilter}
        />
      </Modal>

      <Table
        size="midddle"
        scroll={{ x: 1800, y: "30vh" }}
        columns={columns}
        dataSource={recRecordTableData}
        loading={loading}
      />

      <Modal
        title="Transaction"
        open={isTransactionModalOpen}
        onCancel={() => {
          setIsTransactionModalOpen(false);
          newTransactionForm.resetFields();
        }}
        footer={null}
        destroyOnClose={true}
      >
        <Tabs
          items={transaction_items}
          onChange={(key) => {
            setActiveTab(key);
          }}
        />
      </Modal>

      <Modal
        title="Transaction History"
        open={isUserTransactionModalOpen}
        onCancel={() => {
          setIsUserTransactionModalOpen(false);
        }}
        footer={null}
      >
        <TransactionHistoryTable
          data={transactionHistoryTableData}
          loading={loading}
          setLoading={setLoading}
          getTransactionHistoryTableData={getTransactionHistoryTableData}
          getRecRecordTableData={getRecRecordTableData}
          getPieChartData={getPieChartData}
          getRecInventory={getRecInventory}
        />
      </Modal>

      <Modal
        open={isRejectModalOpen}
        onCancel={() => {
          setIsRejectModalOpen(false);
        }}
        footer={null}
        title="Reject Issue Request Note"
      >
        <Form
          name="admin-reject-issue-request-form"
          autoComplete="off"
          onFinish={(values) => {
            rejectIssueRequest(values);
          }}
          layout="vertical"
        >
          <Form.Item
            label="Notes"
            name="AdminRejectNotes"
            rules={[
              {
                required: true,
                message: "Please input your note!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
