import "../../styles/DeviceRegistration/DeviceRegistration.css";
import "../../styles/DeviceRegistration/SupportingDocuments.css";
import "../../styles/General.css";

import {
  Form,
  Row,
  Col,
  Space,
  Button,
  message,
  Upload,
  Skeleton,
  Tooltip,
  Flex,
} from "antd";

import { useState, useEffect } from "react";
import { BACKEND_URL, INDONESIAN_LOCALE } from "../../config";
import { fetchHelper } from "../../lib/fetch";

import { UploadOutlined } from "@ant-design/icons";
import { InfoCircleOutlined } from "@ant-design/icons";

import SampleNemCert from "../../assets/SampleNemCert.jpeg";

export function SupportingDocuments(props) {
  const { form, onFinish, onPrevClick, deviceInfoData } = props;

  const language = localStorage.getItem("language");

  const [loadingLetterOfDeclaration, setLoadingLetterOfDeclaration] =
    useState(true);
  const [
    loadingPhysicalRenewableEnergyPlantPhotos,
    setLoadingPhysicalRenewableEnergyPlantPhotos,
  ] = useState(true);
  const [loadingSingleLineDiagram, setLoadingSingleLineDiagram] =
    useState(true);
  const [loadingGeneratingLicense, setLoadingGeneratingLicense] =
    useState(true);
  const [loadingNemContract, setLoadingNemContract] = useState(true);
  const [loadingNemCertificate, setLoadingNemCertificate] = useState(true);

  const [
    letterOfDeclarationBackendFileList,
    setLetterOfDeclarationBackendFileList,
  ] = useState([]);
  const [
    uploadingLetterOfDeclarationFile,
    setUploadingLetterOfDeclarationFile,
  ] = useState(null);

  const [
    physicalPhysicalRenewableEnergyPlantPhotosBackendFileList,
    setPhysicalPhysicalRenewableEnergyPlantPhotosBackendFileList,
  ] = useState([]);
  const [
    uploadingPhysicalRenewableEnergyPlantPhotosFile,
    setUploadingPhysicalRenewableEnergyPlantPhotosFile,
  ] = useState(null);

  const [
    singleLineDiagramBackendFileList,
    setSingleLineDiagramBackendFileList,
  ] = useState([]);
  const [uploadingSingleLineDiagramFile, setUploadingSingleLineDiagramFile] =
    useState(null);

  const [
    generatingLicenseBackendFileList,
    setGeneratingLicenseBackendFileList,
  ] = useState([]);
  const [uploadingGeneratingLicenseFile, setUploadingGeneratingLicenseFile] =
    useState(null);

  const [nemContractBackendFileList, setNemContractBackendFileList] = useState(
    []
  );
  const [uploadingNemContractFile, setUploadingNemContractFile] =
    useState(null);

  const [nemCertificateBackendFileList, setNemCertificateBackendFileList] =
    useState([]);
  const [uploadingNemCertificateFile, setUploadingNemCertificateFile] =
    useState(null);

  const [templateFile, setTemplateFile] = useState(null); // url for template file

  // username of user currently logged in, to be passed to backend to retrieve organisation id
  const currentUser = JSON.parse(sessionStorage.getItem("loginData"))?.username;

  // ---------------------------- Upload Letter of Declaration related functions -----------------------------------

  const letterOfDeclarationFileList = letterOfDeclarationBackendFileList.map(
    (element) => {
      return {
        uid: element.ID,
        name: element.Name,
        status: element.error ? "error" : "done",
        url: element.URL,
      };
    }
  );
  if (uploadingLetterOfDeclarationFile !== null) {
    // To show upload progress
    letterOfDeclarationFileList.unshift(uploadingLetterOfDeclarationFile);
  }

  function getLetterOfDeclarationFileList() {
    let params = {
      current_user: currentUser,
      device_name: deviceInfoData["Device Name"],
    };

    let url_param = new URLSearchParams(params);

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setLetterOfDeclarationBackendFileList(data);
    };

    fetchHelper(
      `${BACKEND_URL}/letterOfDeclarationUpload?${url_param}`,
      cb,
      setLoadingLetterOfDeclaration,
      "Letter of Declaration Upload",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function uploadLetterOfDeclarationFile(file) {
    setLoadingLetterOfDeclaration(true);
    let forms = new FormData();

    forms.append("file", file);
    forms.append("session_username", currentUser);
    forms.append("device_name", deviceInfoData["Device Name"]);

    // Use XmlHttpRequest to be able to show progress
    // The alternative would be to use axios
    const xhr = new XMLHttpRequest();
    xhr.open("POST", BACKEND_URL + "/letterOfDeclarationUpload");

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        const percent = (e.loaded / e.total) * 100;
        setUploadingLetterOfDeclarationFile({
          name: file.name,
          uid: -1,
          status: "uploading",
          percent: percent,
          url: "",
        });
      }
    };

    xhr.onload = function () {
      if (xhr.status === 200) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil memasukkan data"
            : "Successfully inserted data"
        );
        setUploadingLetterOfDeclarationFile(null);
        getLetterOfDeclarationFileList();
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal memasukkan data. Tipe file tidak didukung"
            : "Failed to insert data. File type is not supported."
        );
        setUploadingLetterOfDeclarationFile(null);
        setLoadingLetterOfDeclaration(false);
      }
    };

    xhr.send(forms);
  }

  const customLetterOfDeclarationRequest = async ({
    file,
    onSuccess,
    onError,
  }) => {
    try {
      // Your custom logic for uploading the file
      const response = uploadLetterOfDeclarationFile(file);

      // Check for successful upload
      if (response.status === 200) {
        onSuccess();
      } else {
        onError("Upload failed");
      }
    } catch (error) {
      onError(error.message || "Upload failed");
    }
  };

  // ---------------------------- Upload Physical Renewable Energy Plant Photos related functions -----------------------------------

  const physicalRenewableEnergyPlantPhotosFileList =
    physicalPhysicalRenewableEnergyPlantPhotosBackendFileList.map((element) => {
      return {
        uid: element.ID,
        name: element.Name,
        status: element.error ? "error" : "done",
        url: element.URL,
      };
    });
  if (uploadingPhysicalRenewableEnergyPlantPhotosFile !== null) {
    // To show upload progress
    physicalRenewableEnergyPlantPhotosFileList.unshift(
      uploadingPhysicalRenewableEnergyPlantPhotosFile
    );
  }

  function getPhysicalRenewableEnergyPlantPhotosFileList() {
    let params = {
      current_user: currentUser,
      device_name: deviceInfoData["Device Name"],
    };

    let url_param = new URLSearchParams(params);

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setPhysicalPhysicalRenewableEnergyPlantPhotosBackendFileList(data);
    };

    fetchHelper(
      `${BACKEND_URL}/physicalRenewableEnergyPlantPhotosUpload?${url_param}`,
      cb,
      setLoadingPhysicalRenewableEnergyPlantPhotos,
      "Physical Renewable Energy Plant Photos Upload",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function uploadPhysicalRenewableEnergyPlantPhotosFile(file) {
    setLoadingPhysicalRenewableEnergyPlantPhotos(true);
    let forms = new FormData();

    forms.append("file", file);
    forms.append("session_username", currentUser);
    forms.append("device_name", deviceInfoData["Device Name"]);

    // Use XmlHttpRequest to be able to show progress
    // The alternative would be to use axios
    const xhr = new XMLHttpRequest();
    xhr.open("POST", BACKEND_URL + "/physicalRenewableEnergyPlantPhotosUpload");

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        const percent = (e.loaded / e.total) * 100;
        setUploadingPhysicalRenewableEnergyPlantPhotosFile({
          name: file.name,
          uid: -1,
          status: "uploading",
          percent: percent,
          url: "",
        });
      }
    };

    xhr.onload = function () {
      if (xhr.status === 200) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil memasukkan data"
            : "Successfully inserted data"
        );
        setUploadingPhysicalRenewableEnergyPlantPhotosFile(null);
        getPhysicalRenewableEnergyPlantPhotosFileList();
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal memasukkan data. Tipe file tidak didukung"
            : "Failed to insert data. File type is not supported."
        );
        setUploadingPhysicalRenewableEnergyPlantPhotosFile(null);
        setLoadingPhysicalRenewableEnergyPlantPhotos(false);
      }
    };

    xhr.send(forms);
  }

  const customPhysicalRenewableEnergyPlantPhotosRequest = async ({
    file,
    onSuccess,
    onError,
  }) => {
    try {
      // Your custom logic for uploading the file
      const response = uploadPhysicalRenewableEnergyPlantPhotosFile(file);

      // Check for successful upload
      if (response.status === 200) {
        onSuccess();
      } else {
        onError("Upload failed");
      }
    } catch (error) {
      onError(error.message || "Upload failed");
    }
  };

  // ---------------------------- Upload Single Line Diagram related functions -----------------------------------

  const singleLineDiagramFileList = singleLineDiagramBackendFileList.map(
    (element) => {
      return {
        uid: element.ID,
        name: element.Name,
        status: element.error ? "error" : "done",
        url: element.URL,
      };
    }
  );
  if (uploadingSingleLineDiagramFile !== null) {
    // To show upload progress
    singleLineDiagramFileList.unshift(uploadingSingleLineDiagramFile);
  }

  function getSingleLineDiagramFileList() {
    let params = {
      current_user: currentUser,
      device_name: deviceInfoData["Device Name"],
    };

    let url_param = new URLSearchParams(params);

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setSingleLineDiagramBackendFileList(data);
    };

    fetchHelper(
      `${BACKEND_URL}/singleLineDiagramUpload?${url_param}`,
      cb,
      setLoadingSingleLineDiagram,
      "Single Line Diagram Upload",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function uploadSingleLineDiagramFile(file) {
    setLoadingSingleLineDiagram(true);
    let forms = new FormData();

    forms.append("file", file);
    forms.append("session_username", currentUser);
    forms.append("device_name", deviceInfoData["Device Name"]);

    // Use XmlHttpRequest to be able to show progress
    // The alternative would be to use axios
    const xhr = new XMLHttpRequest();
    xhr.open("POST", BACKEND_URL + "/singleLineDiagramUpload");

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        const percent = (e.loaded / e.total) * 100;
        setUploadingSingleLineDiagramFile({
          name: file.name,
          uid: -1,
          status: "uploading",
          percent: percent,
          url: "",
        });
      }
    };

    xhr.onload = function () {
      if (xhr.status === 200) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil memasukkan data"
            : "Successfully inserted data"
        );
        setUploadingSingleLineDiagramFile(null);
        getSingleLineDiagramFileList();
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal memasukkan data. Tipe file tidak didukung"
            : "Failed to insert data. File type is not supported."
        );
        setUploadingSingleLineDiagramFile(null);
        setLoadingSingleLineDiagram(false);
      }
    };

    xhr.send(forms);
  }

  const customSingleLineDiagramRequest = async ({
    file,
    onSuccess,
    onError,
  }) => {
    try {
      // Your custom logic for uploading the file
      const response = uploadSingleLineDiagramFile(file);

      // Check for successful upload
      if (response.status === 200) {
        onSuccess();
      } else {
        onError("Upload failed");
      }
    } catch (error) {
      onError(error.message || "Upload failed");
    }
  };

  // ---------------------------- Upload Generating License related functions -----------------------------------

  const generatingLicenseFileList = generatingLicenseBackendFileList.map(
    (element) => {
      return {
        uid: element.ID,
        name: element.Name,
        status: element.error ? "error" : "done",
        url: element.URL,
      };
    }
  );
  if (uploadingGeneratingLicenseFile !== null) {
    // To show upload progress
    generatingLicenseFileList.unshift(uploadingGeneratingLicenseFile);
  }

  function getGeneratingLicenseFileList() {
    let params = {
      current_user: currentUser,
      device_name: deviceInfoData["Device Name"],
    };

    let url_param = new URLSearchParams(params);

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setGeneratingLicenseBackendFileList(data);
    };

    fetchHelper(
      `${BACKEND_URL}/generatingLicenseUpload?${url_param}`,
      cb,
      setLoadingGeneratingLicense,
      "Generating License Upload",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function uploadGeneratingLicenseFile(file) {
    setLoadingGeneratingLicense(true);
    let forms = new FormData();

    forms.append("file", file);
    forms.append("session_username", currentUser);
    forms.append("device_name", deviceInfoData["Device Name"]);

    // Use XmlHttpRequest to be able to show progress
    // The alternative would be to use axios
    const xhr = new XMLHttpRequest();
    xhr.open("POST", BACKEND_URL + "/generatingLicenseUpload");

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        const percent = (e.loaded / e.total) * 100;
        setUploadingGeneratingLicenseFile({
          name: file.name,
          uid: -1,
          status: "uploading",
          percent: percent,
          url: "",
        });
      }
    };

    xhr.onload = function () {
      if (xhr.status === 200) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil memasukkan data"
            : "Successfully inserted data"
        );
        setUploadingGeneratingLicenseFile(null);
        getGeneratingLicenseFileList();
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal memasukkan data. Tipe file tidak didukung"
            : "Failed to insert data. File type is not supported."
        );
        setUploadingGeneratingLicenseFile(null);
        setLoadingGeneratingLicense(false);
      }
    };

    xhr.send(forms);
  }

  const customGeneratingLicenseRequest = async ({
    file,
    onSuccess,
    onError,
  }) => {
    try {
      // Your custom logic for uploading the file
      const response = uploadGeneratingLicenseFile(file);

      // Check for successful upload
      if (response.status === 200) {
        onSuccess();
      } else {
        onError("Upload failed");
      }
    } catch (error) {
      onError(error.message || "Upload failed");
    }
  };

  // ---------------------------- Upload Nem Contract related functions -----------------------------------

  const nemContractFileList = nemContractBackendFileList.map((element) => {
    return {
      uid: element.ID,
      name: element.Name,
      status: element.error ? "error" : "done",
      url: element.URL,
    };
  });
  if (uploadingNemContractFile !== null) {
    // To show upload progress
    nemContractFileList.unshift(uploadingNemContractFile);
  }

  function getNemContractFileList() {
    let params = {
      current_user: currentUser,
      device_name: deviceInfoData["Device Name"],
    };

    let url_param = new URLSearchParams(params);

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setNemContractBackendFileList(data);
    };

    fetchHelper(
      `${BACKEND_URL}/nemContractUpload?${url_param}`,
      cb,
      setLoadingNemContract,
      "Nem Contract Upload",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function uploadNemContractFile(file) {
    setLoadingNemContract(true);
    let forms = new FormData();

    forms.append("file", file);
    forms.append("session_username", currentUser);
    forms.append("device_name", deviceInfoData["Device Name"]);

    // Use XmlHttpRequest to be able to show progress
    // The alternative would be to use axios
    const xhr = new XMLHttpRequest();
    xhr.open("POST", BACKEND_URL + "/nemContractUpload");

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        const percent = (e.loaded / e.total) * 100;
        setUploadingNemContractFile({
          name: file.name,
          uid: -1,
          status: "uploading",
          percent: percent,
          url: "",
        });
      }
    };

    xhr.onload = function () {
      if (xhr.status === 200) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil memasukkan data"
            : "Successfully inserted data"
        );
        setUploadingNemContractFile(null);
        getNemContractFileList();
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal memasukkan data. Tipe file tidak didukung"
            : "Failed to insert data. File type is not supported."
        );
        setUploadingNemContractFile(null);
        setLoadingNemContract(false);
      }
    };

    xhr.send(forms);
  }

  const customNemContractRequest = async ({ file, onSuccess, onError }) => {
    try {
      // Your custom logic for uploading the file
      const response = uploadNemContractFile(file);

      // Check for successful upload
      if (response.status === 200) {
        onSuccess();
      } else {
        onError("Upload failed");
      }
    } catch (error) {
      onError(error.message || "Upload failed");
    }
  };

  // ---------------------------- Upload Nem Certificate related functions -----------------------------------

  const nemCertificateFileList = nemCertificateBackendFileList.map(
    (element) => {
      return {
        uid: element.ID,
        name: element.Name,
        status: element.error ? "error" : "done",
        url: element.URL,
      };
    }
  );
  if (uploadingNemCertificateFile !== null) {
    // To show upload progress
    nemCertificateFileList.unshift(uploadingNemCertificateFile);
  }

  function getNemCertificateFileList() {
    let params = {
      current_user: currentUser,
      device_name: deviceInfoData["Device Name"],
    };

    let url_param = new URLSearchParams(params);

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setNemCertificateBackendFileList(data);
    };

    fetchHelper(
      `${BACKEND_URL}/nemCertificateUpload?${url_param}`,
      cb,
      setLoadingNemCertificate,
      "Nem Certificate Upload",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function uploadNemCertificateFile(file) {
    setLoadingNemCertificate(true);
    let forms = new FormData();

    forms.append("file", file);
    forms.append("session_username", currentUser);
    forms.append("device_name", deviceInfoData["Device Name"]);

    // Use XmlHttpRequest to be able to show progress
    // The alternative would be to use axios
    const xhr = new XMLHttpRequest();
    xhr.open("POST", BACKEND_URL + "/nemCertificateUpload");

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        const percent = (e.loaded / e.total) * 100;
        setUploadingNemCertificateFile({
          name: file.name,
          uid: -1,
          status: "uploading",
          percent: percent,
          url: "",
        });
      }
    };

    xhr.onload = function () {
      if (xhr.status === 200) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil memasukkan data"
            : "Successfully inserted data"
        );
        setUploadingNemCertificateFile(null);
        getNemCertificateFileList();
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal memasukkan data. Tipe file tidak didukung"
            : "Failed to insert data. File type is not supported."
        );
        setUploadingNemCertificateFile(null);
        setLoadingNemCertificate(false);
      }
    };

    xhr.send(forms);
  }

  const customNemCertificateRequest = async ({ file, onSuccess, onError }) => {
    try {
      // Your custom logic for uploading the file
      const response = uploadNemCertificateFile(file);

      // Check for successful upload
      if (response.status === 200) {
        onSuccess();
      } else {
        onError("Upload failed");
      }
    } catch (error) {
      onError(error.message || "Upload failed");
    }
  };

  // ------------------------- Delete function for all files -----------------------------------

  function deleteFile(file) {
    // set loading when file is being deleted
    setLoadingLetterOfDeclaration(true);
    setLoadingPhysicalRenewableEnergyPlantPhotos(true);
    setLoadingSingleLineDiagram(true);
    setLoadingGeneratingLicense(true);
    setLoadingNemContract(true);
    setLoadingNemCertificate(true);

    fetch(BACKEND_URL + "/supportingDocumentUpload/" + file.uid, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    }).then((response) => {
      if (response.status === 200) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil menghapus data"
            : "Successfully deleted data"
        );
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal menghapus data"
            : "Failed to delete data"
        );
      }
      getLetterOfDeclarationFileList();
      getPhysicalRenewableEnergyPlantPhotosFileList();
      getSingleLineDiagramFileList();
      getGeneratingLicenseFileList();
      getNemContractFileList();
      getNemCertificateFileList();
    });
  }

  // This API call is to generate template for Letter of Declaration
  function generateTemplate() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        device_name: deviceInfoData["Device Name"]
          ? deviceInfoData["Device Name"]
          : "",
        device_address: deviceInfoData["Device Address"]
          ? deviceInfoData["Device Address"]
          : "",
        city: deviceInfoData["City"] ? deviceInfoData["City"] : "",
        postcode: deviceInfoData["Postcode"] ? deviceInfoData["Postcode"] : "",
        state: deviceInfoData["State"] ? deviceInfoData["State"] : "",
        country: deviceInfoData["Country"] ? deviceInfoData["Country"] : "",
        device_capacity_value: deviceInfoData["Capacity"]["Capacity Value"]
          ? deviceInfoData["Capacity"]["Capacity Value"]
          : "",
        device_capacity_unit: deviceInfoData["Capacity"]["Capacity Unit"]
          ? deviceInfoData["Capacity"]["Capacity Unit"]
          : "",
        effective_registration_date: deviceInfoData[
          "Effective Registration Date"
        ]
          ? deviceInfoData["Effective Registration Date"]
          : "",
        is_device_owner: deviceInfoData["Is Device Owner"]
          ? deviceInfoData["Is Device Owner"]
          : "",
        device_owner: deviceInfoData["Device Owner"]
          ? deviceInfoData["Device Owner"]
          : "",
        current_user: currentUser,
      }),
    };

    const cb = (data) => {
      setTemplateFile(data.url);
    };

    fetchHelper(
      BACKEND_URL + "/generateTemplate",
      cb,
      () => {},
      "Generate Letter of Declaration Template",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    getLetterOfDeclarationFileList();
    getPhysicalRenewableEnergyPlantPhotosFileList();
    getSingleLineDiagramFileList();
    getGeneratingLicenseFileList();
    getNemContractFileList();
    getNemCertificateFileList();
    generateTemplate();
  }, []);

  return (
    <Form
      form={form}
      onFinish={() => {
        if (letterOfDeclarationFileList.length === 0) {
          message.error(
            language === INDONESIAN_LOCALE
              ? "Silahkan upload dokumen pendukung"
              : "Please upload letter of declaration"
          );
        }

        if (physicalRenewableEnergyPlantPhotosFileList.length === 0) {
          message.error(
            language === INDONESIAN_LOCALE
              ? "Silahkan upload foto pembangkit energi terbarukan fisik"
              : "Please upload physical renewable energy plant photos"
          );
        }

        if (singleLineDiagramFileList.length === 0) {
          message.error(
            language === INDONESIAN_LOCALE
              ? "Silahkan upload Diagram Garis Tunggal (Single Line Diagram)"
              : "Please upload single line diagram"
          );
        }

        if (generatingLicenseFileList.length === 0) {
          message.error(
            language === INDONESIAN_LOCALE
              ? "Silahkan upload Izin Pembangkitan Listrik"
              : "Please upload generating license"
          );
        }

        if (
          letterOfDeclarationFileList.length !== 0 &&
          physicalRenewableEnergyPlantPhotosFileList.length !== 0 &&
          singleLineDiagramFileList.length !== 0 &&
          generatingLicenseFileList.length !== 0
        ) {
          onFinish();
        }
      }}
      className="device-registration-form-container"
      layout="vertical"
      scrollToFirstError
      autoComplete="off" // !important -> so that previously entered (and cleared) value is not remembered
    >
      <div
        id="device-registration-supporting-documents"
        className="device-registration-outer-container"
      >
        <div className="device-registration-content-container">
          <Row className="device-registration-content-container-row-layout">
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <h3 className="device-registration-h3-style">
                {language === INDONESIAN_LOCALE
                  ? "Daftarkan Perangkat Energi Terbarukan Baru"
                  : "Register New Renewable Energy Device"}
              </h3>
              <div className="device-registration-horizontal-line"></div>
              <h2 className="device-registration-h2-style">
                {language === INDONESIAN_LOCALE
                  ? "Dokumen Pendukung"
                  : "Supporting Documents"}
              </h2>
              <br />

              {/* Display the uploading form items when all the files have been uploaded or fetched from backend */}
              <div
                style={{
                  display:
                    !loadingLetterOfDeclaration &&
                    !loadingPhysicalRenewableEnergyPlantPhotos &&
                    !loadingSingleLineDiagram &&
                    !loadingGeneratingLicense &&
                    !loadingNemContract &&
                    !loadingNemCertificate
                      ? "block"
                      : "none",
                }}
              >
                <div className="device-registration-2-row-layout">
                  <div className="device-registration-2-wording-container">
                    <Flex gap={"4px"}>
                      <h4>
                        {language === INDONESIAN_LOCALE
                          ? "Surat Pernyataan"
                          : "Letter of Declaration"}
                      </h4>
                      <Tooltip
                        title={
                          language === INDONESIAN_LOCALE
                            ? "Anda hanya dapat mengunggah maksimal 1 surat pernyataan. Jika anda ingin mengunggah berkas lain, hapus berkas yang lama, baru anda dapat mengunggah berkas yang baru."
                            : "You can only upload a maximum of 1 letter of declaration. If you want to upload another file, please delete the old one then only you can upload the new one."
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Flex>
                    <h5>
                      {language === INDONESIAN_LOCALE
                        ? "Unduh templat"
                        : "Download template"}
                      <a href={templateFile}>
                        {language === INDONESIAN_LOCALE ? " di sini" : " here"}
                      </a>
                      {language === INDONESIAN_LOCALE
                        ? "; Sertakan kop surat dan tanda tangan perwakilan perusahaan"
                        : "; Include company letterhead and signature"}
                    </h5>
                  </div>
                  <div className="device-registration-2-upload-btn-container">
                    <Upload
                      name="file"
                      className="device-registration-2-upload-btn"
                      fileList={letterOfDeclarationFileList}
                      customRequest={customLetterOfDeclarationRequest}
                      onRemove={deleteFile}
                      onChange={(info) => {
                        console.log(info.file);
                        if (info.file.status !== "uploading") {
                          console.log(info.file, info.fileList);
                        }
                        if (info.file.status === "done") {
                          message.success(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file berhasil diunggah`
                              : `${info.file.name} file uploaded successfully`
                          );
                        } else if (info.file.status === "error") {
                          message.error(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file gagal diunggah`
                              : `${info.file.name} file upload failed.`
                          );
                        }
                      }}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        disabled={
                          uploadingLetterOfDeclarationFile !== null ||
                          letterOfDeclarationFileList.length === 1
                        }
                      >
                        {language === INDONESIAN_LOCALE
                          ? "Klik untuk Unggah"
                          : "Click to Upload"}
                      </Button>
                    </Upload>
                  </div>
                </div>

                <div className="device-registration-2-row-layout">
                  <div className="device-registration-2-wording-container">
                    <Flex gap={"4px"}>
                      <h4>
                        {language === INDONESIAN_LOCALE
                          ? "Foto Pembangkit Energi Terbarukan Fisik"
                          : "Physical Renewable Energy Plant Photos"}
                      </h4>
                      <Tooltip
                        title={
                          language === INDONESIAN_LOCALE
                            ? "Anda perlu mengunggah minimal 3 foto, yang meliputi foto pemandangan satelit dari Google Map, foto sistem pembangkit listrik, dan foto pembalik daya (inverter) / generator. Anda hanya dapat mengunggah maksimal 15 foto. Jika Anda melebihi batas dan ingin mengunggah file lain, harap hapus yang lama, baru Anda dapat mengunggah yang baru."
                            : "You need to upload a minimum of 3 photos, which includes satelite view photo from Google Map, system photo and inverter / generator photo. You can only upload a maximum of 15 photos. If you exceed the limit and want to upload another file, please delete the old one then only you can upload the new one."
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Flex>
                    <h5>
                      {language === INDONESIAN_LOCALE
                        ? `Foto tampilan satelit dari Google Map, foto sistem pembangkit listrik dan foto inverter / generator`
                        : `Satelite view photos from Google Map, system photos
                      and inverter / generator photos`}
                    </h5>
                  </div>
                  <div className="device-registration-2-upload-btn-container">
                    <Upload
                      name="file"
                      className="device-registration-2-upload-btn"
                      fileList={physicalRenewableEnergyPlantPhotosFileList}
                      customRequest={
                        customPhysicalRenewableEnergyPlantPhotosRequest
                      }
                      onRemove={deleteFile}
                      onChange={(info) => {
                        if (info.file.status !== "uploading") {
                          console.log(info.file, info.fileList);
                        }
                        if (info.file.status === "done") {
                          message.success(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file berhasil diunggah`
                              : `${info.file.name} file uploaded successfully`
                          );
                        } else if (info.file.status === "error") {
                          message.error(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file gagal diunggah`
                              : `${info.file.name} file upload failed.`
                          );
                        }
                      }}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        disabled={
                          uploadingPhysicalRenewableEnergyPlantPhotosFile !==
                            null ||
                          physicalRenewableEnergyPlantPhotosFileList.length ===
                            15
                        }
                      >
                        {language === INDONESIAN_LOCALE
                          ? "Klik untuk Unggah"
                          : "Click to Upload"}
                      </Button>
                    </Upload>
                  </div>
                </div>

                <div className="device-registration-2-row-layout">
                  <div className="device-registration-2-wording-container">
                    <Flex gap={"4px"}>
                      <h4>
                        {language === INDONESIAN_LOCALE
                          ? "Diagram Garis Tunggal (Single Line Diagram) (Single Line Diagram)"
                          : "Single Line Diagram"}
                      </h4>
                      <Tooltip
                        title={
                          language === INDONESIAN_LOCALE
                            ? "Anda hanya dapat mengunggah maksimal 5 diagram. Jika Anda melebihi batas dan ingin mengunggah file lain, harap hapus yang lama, baru Anda dapat mengunggah yang baru."
                            : "You can only upload a maximum of 5 diagrams. If you exceed the limit and want to upload another file, please delete the old one then only you can upload the new one."
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Flex>
                    <h5>
                      {language === INDONESIAN_LOCALE
                        ? `Skema Listrik yang menunjukkan konektivitas dengan Grid Supply dan Generator RE`
                        : `Electrical Schematic showing connectivity with Grid Supply
                      and RE Generator`}
                    </h5>
                  </div>
                  <div className="device-registration-2-upload-btn-container">
                    <Upload
                      name="file"
                      className="device-registration-2-upload-btn"
                      fileList={singleLineDiagramFileList}
                      customRequest={customSingleLineDiagramRequest}
                      onRemove={deleteFile}
                      onChange={(info) => {
                        if (info.file.status !== "uploading") {
                          console.log(info.file, info.fileList);
                        }
                        if (info.file.status === "done") {
                          message.success(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file berhasil diunggah`
                              : `${info.file.name} file uploaded successfully`
                          );
                        } else if (info.file.status === "error") {
                          message.error(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file gagal diunggah`
                              : `${info.file.name} file upload failed.`
                          );
                        }
                      }}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        disabled={
                          uploadingSingleLineDiagramFile !== null ||
                          singleLineDiagramFileList.length === 5
                        }
                      >
                        {language === INDONESIAN_LOCALE
                          ? "Klik untuk Unggah"
                          : "Click to Upload"}
                      </Button>
                    </Upload>
                  </div>
                </div>

                <div className="device-registration-2-row-layout">
                  <div className="device-registration-2-wording-container">
                    <Flex gap={"4px"}>
                      <h4>
                        {language === INDONESIAN_LOCALE
                          ? "Izin Pembangkitan Listrik"
                          : "Generating License"}{" "}
                      </h4>
                      <Tooltip
                        title={
                          language === INDONESIAN_LOCALE
                            ? "Anda hanya dapat mengunggah maksimal 1 lisensi. Jika Anda melebihi batas dan ingin mengunggah file lain, harap hapus yang lama, baru Anda dapat mengunggah yang baru."
                            : "You can only upload a maximum of 1 generating license. If you want to upload another file, please delete the old one then only you can upload the new one."
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Flex>
                    <h5>
                      {language === INDONESIAN_LOCALE
                        ? "Oleh Pihak Berwenang"
                        : "By Suruhanjaya Tenaga"}
                    </h5>
                  </div>
                  <div className="device-registration-2-upload-btn-container">
                    <Upload
                      name="file"
                      className="device-registration-2-upload-btn"
                      fileList={generatingLicenseFileList}
                      customRequest={customGeneratingLicenseRequest}
                      onRemove={deleteFile}
                      onChange={(info) => {
                        if (info.file.status !== "uploading") {
                          console.log(info.file, info.fileList);
                        }
                        if (info.file.status === "done") {
                          message.success(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file berhasil diunggah`
                              : `${info.file.name} file uploaded successfully`
                          );
                        } else if (info.file.status === "error") {
                          message.error(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file gagal diunggah`
                              : `${info.file.name} file upload failed.`
                          );
                        }
                      }}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        disabled={
                          uploadingGeneratingLicenseFile !== null ||
                          generatingLicenseFileList.length === 1
                        }
                      >
                        {language === INDONESIAN_LOCALE
                          ? "Klik untuk Unggah"
                          : "Click to Upload"}
                      </Button>
                    </Upload>
                  </div>
                </div>

                <div className="device-registration-2-row-layout">
                  <div className="device-registration-2-wording-container">
                    <Flex gap={"4px"}>
                      <h4>
                        {language === INDONESIAN_LOCALE
                          ? "Kontrak NEM (jika ada)"
                          : "NEM Contract (if available)"}{" "}
                      </h4>
                      <Tooltip
                        title={
                          language === INDONESIAN_LOCALE
                            ? "Anda hanya dapat mengunggah maksimal 1 kontrak NEM. Jika Anda melebihi batas dan ingin mengunggah file lain, harap hapus yang lama, baru Anda dapat mengunggah yang baru."
                            : "You can only upload a maximum of 1 NEM Contract. If you want to upload another file, please delete the old one then only you can upload the new one."
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Flex>
                    <h5>
                      {language === INDONESIAN_LOCALE
                        ? "Ini wajib untuk aset NEM"
                        : "This is compulsory for NEM asset"}
                    </h5>
                  </div>
                  <div className="device-registration-2-upload-btn-container">
                    <Upload
                      name="file"
                      className="device-registration-2-upload-btn"
                      fileList={nemContractFileList}
                      customRequest={customNemContractRequest}
                      onRemove={deleteFile}
                      onChange={(info) => {
                        if (info.file.status !== "uploading") {
                          console.log(info.file, info.fileList);
                        }
                        if (info.file.status === "done") {
                          message.success(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file berhasil diunggah`
                              : `${info.file.name} file uploaded successfully`
                          );
                        } else if (info.file.status === "error") {
                          message.error(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file gagal diunggah`
                              : `${info.file.name} file upload failed.`
                          );
                        }
                      }}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        disabled={
                          uploadingNemContractFile !== null ||
                          nemContractFileList.length === 1
                        }
                      >
                        {language === INDONESIAN_LOCALE
                          ? "Klik untuk Unggah"
                          : "Click to Upload"}
                      </Button>
                    </Upload>
                  </div>
                </div>

                <div className="device-registration-2-row-layout">
                  <div className="device-registration-2-wording-container">
                    <Flex gap={"4px"}>
                      <h4>
                        {language === INDONESIAN_LOCALE
                          ? "Sertifikat NEM (jika ada)"
                          : "NEM Certificate (if available)"}{" "}
                      </h4>
                      <Tooltip
                        title={
                          <div>
                            <h5>
                              {language === INDONESIAN_LOCALE
                                ? `Untuk melihat contoh Sertifikat NEM, Anda dapat mengeklik `
                                : `To have a look at the sample NEM Certificate, you
                              can click `}
                              <a
                                href={SampleNemCert}
                                download="sample_sare_cert.jpeg"
                              >
                                {language === INDONESIAN_LOCALE
                                  ? "Sertifikat NEM"
                                  : "NEM Certificate"}
                              </a>{" "}
                              {language === INDONESIAN_LOCALE
                                ? `untuk mengunduh berkas contoh. Anda hanya dapat mengunggah maksimal 1 Sertifikat NEM. Jika Anda ingin mengunggah berkas lain, hapus berkas lama, baru Anda dapat mengunggah berkas baru.`
                                : `to download the sample file. You can only upload a
                              maximum of 1 NEM Certificate. If you want to
                              upload another file, please delete the old one
                              then only you can upload the new one.`}
                            </h5>
                          </div>
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Flex>
                  </div>
                  <div className="device-registration-2-upload-btn-container">
                    <Upload
                      name="file"
                      className="device-registration-2-upload-btn"
                      fileList={nemCertificateFileList}
                      customRequest={customNemCertificateRequest}
                      onRemove={deleteFile}
                      onChange={(info) => {
                        if (info.file.status !== "uploading") {
                          console.log(info.file, info.fileList);
                        }
                        if (info.file.status === "done") {
                          message.success(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file berhasil diunggah`
                              : `${info.file.name} file uploaded successfully`
                          );
                        } else if (info.file.status === "error") {
                          message.error(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file gagal diunggah`
                              : `${info.file.name} file upload failed.`
                          );
                        }
                      }}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        disabled={
                          uploadingNemCertificateFile !== null ||
                          nemCertificateFileList.length === 1
                        }
                      >
                        {language === INDONESIAN_LOCALE
                          ? "Klik untuk Unggah"
                          : "Click to Upload"}
                      </Button>
                    </Upload>
                  </div>
                </div>

                <Form.Item>
                  <Space className="device-registration-btn-layout">
                    <Button
                      className="device-registration-prev-btn-style"
                      onClick={onPrevClick}
                    >
                      {language === INDONESIAN_LOCALE
                        ? "Sebelumnya"
                        : "Previous"}
                    </Button>
                    {/* <Button className="device-registration-delete-draft-btn-style">
                    Delete Draft
                  </Button>
                  <Button className="device-registration-save-draft-btn-style">
                    Save Draft
                  </Button> */}
                    <Tooltip
                      title={
                        physicalRenewableEnergyPlantPhotosFileList.length < 3
                          ? language === INDONESIAN_LOCALE
                            ? "Anda harus mengunggah minimal 3 foto pembangkit energi terbarukan fisik."
                            : "You must upload at least 3 physical renewable energy plant photos."
                          : ""
                      }
                    >
                      <Button
                        className="device-registration-next-btn-style"
                        htmlType="submit"
                        disabled={
                          physicalRenewableEnergyPlantPhotosFileList.length < 3
                        }
                      >
                        {language === INDONESIAN_LOCALE ? "Berikutnya" : "Next"}
                      </Button>
                    </Tooltip>
                  </Space>
                </Form.Item>
              </div>

              {/* Display loading when any of the files have not been uploaded or fetched from backend */}
              <div
                style={{
                  display:
                    loadingLetterOfDeclaration ||
                    loadingPhysicalRenewableEnergyPlantPhotos ||
                    loadingSingleLineDiagram ||
                    loadingGeneratingLicense ||
                    loadingNemContract ||
                    loadingNemCertificate
                      ? "block"
                      : "none",
                }}
              >
                <Skeleton
                  active={true}
                  paragraph={{ rows: 10 }}
                  style={{ padding: "2rem", maxWidth: "70%", margin: "auto" }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Form>
  );
}
