import "../styles/Login.css";
import { Row, Card, Button, Form, Input, Divider, Flex } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/REVEAI.png";
import { fetchHelper } from "../lib/fetch";
import { BACKEND_URL, INDONESIAN_LOCALE } from "../config";

const LOGIN_URL = BACKEND_URL + "/loginUser";

function Login() {
  let navigate = useNavigate();

  const language = localStorage.getItem("language");

  const [isLogin, setIsLogin] = useState(false);
  const [login_processing, setLoginProcessing] = useState(false);
  const [loginError, setLoginError] = useState({
    status: false,
    msg: "",
  });

  const onFinish = (values) => {
    login(values);
  };
  const onFinishFailed = (errorInfo) => {
    setLoginProcessing(false);
    console.log("Failed:", errorInfo);
  };
  const showLoading = () => {
    setLoginProcessing(true);
  };

  // const login = () => {
  //   setLoginProcessing(false);
  //   localStorage.clear();
  //   navigate("/deviceregistration");
  // };

  function login(user_inputs) {
    // do not clear everything as need to keep language
    localStorage.removeItem("is_malaysian");
    localStorage.removeItem("is_not_malaysian");

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(user_inputs),
    };

    const fetchErrorCallback = () => {
      setLoginError({
        status: true,
        msg: "Server Error",
      });
    };
    const fetchDataErrorCallback = (data) => {
      setLoginError({
        status: true,
        msg: data.error,
      });
    };
    const fetchSucessCallback = (data) => {
      setIsLogin(true);
      setLoginError({
        status: false,
        msg: "",
      });

      // DATA FORMAT
      //  - login_status
      //  - username
      sessionStorage.setItem("loginData", JSON.stringify(data));

      navigate("/home", { replace: true });
    };
    fetchHelper(
      LOGIN_URL,
      fetchSucessCallback,
      setLoginProcessing,
      "Login",
      fetchErrorCallback,
      fetchDataErrorCallback,
      requestOptions,
      false
    );
  }

  const onSignUpClick = () => {
    // do not clear everything as need to keep language
    localStorage.removeItem("is_malaysian");
    localStorage.removeItem("is_not_malaysian");

    navigate("/signup");
  };

  return (
    <div className="login-card-container">
      <Card className="login-card">
        <div className="logo-image-div">
          <img src={Logo} style={{ width: "90%" }} alt="logo"></img>
        </div>
        <Form
          className="login-form"
          layout="vertical"
          name="login"
          requiredMark={false}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          loading={String(login_processing)}
          onSubmitCapture={showLoading}
        >
          <Form.Item
            label={
              language === INDONESIAN_LOCALE ? "Name pengguna" : "Username"
            }
            name="username"
            rules={[
              {
                required: true,
                message:
                  language === INDONESIAN_LOCALE
                    ? "Silahkan masukkan nama pengguna"
                    : "Please input your username!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={language === INDONESIAN_LOCALE ? "Kata sandi" : "Password"}
            name="password"
            rules={[
              {
                required: true,
                message:
                  language === INDONESIAN_LOCALE
                    ? "Silahkan masukkan kata sandi"
                    : "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Button
              className="login-button"
              block="true"
              type="primary"
              htmlType="submit"
              loading={login_processing}
            >
              {language === INDONESIAN_LOCALE ? "Masuk" : "Log In"}
            </Button>
          </Form.Item>
        </Form>
        {loginError.status ? (
          <Row className="error-bar">{loginError.msg}</Row>
        ) : (
          <></>
        )}
        <Divider style={{ color: "#bababa", margin: 0 }}>
          {language === INDONESIAN_LOCALE ? "atau" : "or"}
        </Divider>
        <Button
          className="signup-button"
          type="primary"
          block
          onClick={onSignUpClick}
        >
          {language === INDONESIAN_LOCALE ? "Daftar" : "Sign Up"}
        </Button>
        <Flex justify="center">
          <Button type="link" onClick={() => navigate("/language-selection")}>
            {language === INDONESIAN_LOCALE
              ? "Ganti Bahasa"
              : "Change Language"}
          </Button>
        </Flex>
      </Card>
    </div>
  );
}

export default Login;
