import "../../styles/Form.css";
import { Form, Button, Select, Input } from "antd";
import { FORM_REQUIRED, BACKEND_URL } from "../../config";
import { useState, useEffect } from "react";
import { fetchHelper } from "../../lib/fetch";

export function IndividualUserInfoForm({ form, onFinish }) {
  const [designationList, setDesignationList] = useState([]);
  const [designationSelection, setDesignationSelection] = useState();
  const [nationalityList, setNationalityList] = useState([]);
  const [nationalitySelection, setNationalitySelection] = useState();

  const genderOptions = [
    { value: "MALE", label: "MALE" },
    { value: "FEMALE", label: "FEMALE" },
  ];

  function getDesignationSelection() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setDesignationList(data.designationData);
      setDesignationSelection(data.designationData[0]["value"]);
    };

    fetchHelper(
      BACKEND_URL + "/getDesignationSelection",
      cb,
      () => {},
      "Get Designation Selection",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getNationalitySelection() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setNationalityList(data.nationalityData);
      setNationalitySelection(data.nationalityData[0]["value"]);
    };

    fetchHelper(
      BACKEND_URL + "/getNationalitySelection",
      cb,
      () => {},
      "Get Nationality Selection",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  const checUserIdExists = async (rule, value, callback) => {
    try {
      const response = await fetch(BACKEND_URL + "/checkUniqueUserId", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ Id: value }),
      });

      const data = await response.json();

      if (!data.is_unique && JSON.parse(localStorage.getItem("is_malaysian"))) {
        return Promise.reject(
          new Error("IC already exists. Please use another IC.")
        );
      } else if (
        !data.is_unique &&
        JSON.parse(localStorage.getItem("is_not_malaysian"))
      ) {
        return Promise.reject(
          new Error("ID already exists. Please use another ID.")
        );
      } else {
        return Promise.resolve();
      }
    } catch (error) {
      callback(error.message);
    }
  };

  useEffect(() => {
    getDesignationSelection();
  }, []);

  useEffect(() => {
    getNationalitySelection();
  }, []);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      name="registerInfo"
      scrollToFirstError
      autoComplete="off" // !important -> so that previously entered (and cleared) value is not remembered
    >
      <Form.Item
        name="Designation Id"
        label={"Title"}
        rules={[{ required: FORM_REQUIRED, message: "Please select title." }]}
      >
        <Select
          placeholder="select your title"
          value={designationSelection}
          options={designationList}
          onChange={(value, option) => {
            setDesignationSelection(value);
            form.setFieldValue("Designation", option.label);
          }}
        />
      </Form.Item>
      <Form.Item name="Designation" hidden={true}></Form.Item>
      <Form.Item
        name="First Name"
        label={"First Name"}
        className="form-double-col-1"
        rules={[
          { required: FORM_REQUIRED, message: "Please input your first name." },
        ]}
      >
        <Input
          placeholder="input your first name"
          onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
        />
      </Form.Item>
      <Form.Item
        name="Last Name"
        label={"Last Name"}
        className="form-double-col-2"
        rules={[
          { required: FORM_REQUIRED, message: "Please input your last name." },
        ]}
      >
        <Input
          placeholder="input your last name"
          onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
        />
      </Form.Item>
      <Form.Item
        name="Gender"
        label={"Gender"}
        rules={[{ required: FORM_REQUIRED, message: "Please select gender." }]}
      >
        <Select
          placeholder="select your gender"
          options={genderOptions}
        ></Select>
      </Form.Item>
      <Form.Item
        name="Nationality Id"
        label={"Nationality"}
        rules={[
          { required: FORM_REQUIRED, message: "Please select nationality." },
        ]}
      >
        <Select
          placeholder="select your nationality"
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          value={nationalitySelection}
          options={nationalityList}
          onChange={(value, option) => {
            setNationalitySelection(value);
            form.setFieldValue("Nationality", option.label);
            if (option.label === "MALAYSIAN") {
              localStorage.setItem("is_malaysian", true);
              localStorage.setItem("is_not_malaysian", false);
            } else {
              localStorage.setItem("is_malaysian", false);
              localStorage.setItem("is_not_malaysian", true);
            }
          }}
        />
      </Form.Item>
      <Form.Item name="Nationality" hidden={true}></Form.Item>

      {JSON.parse(localStorage.getItem("is_malaysian")) && (
        <Form.Item
          name="IC"
          label={`IC`}
          validateTrigger="onBlur" // validate after finishing input
          rules={[
            {
              pattern: /^\d{12}$/,
              message: `Please enter a valid IC No without dash '-'`,
            },
            {
              required: FORM_REQUIRED,
              message: "Please input your IC.",
            },
            {
              validator: checUserIdExists,
            },
          ]}
          style={{
            display: JSON.parse(localStorage.getItem("is_malaysian"))
              ? "block"
              : "none",
          }}
        >
          <Input placeholder="input your ic without dash '-'" />
        </Form.Item>
      )}

      {JSON.parse(localStorage.getItem("is_not_malaysian")) && (
        <Form.Item
          name="ID"
          label={`ID`}
          validateTrigger="onBlur" // validate after finishing input
          rules={[
            {
              required: FORM_REQUIRED,
              message: "Please input your ID.",
            },
            {
              validator: checUserIdExists,
            },
          ]}
          style={{
            display: JSON.parse(localStorage.getItem("is_not_malaysian"))
              ? "block"
              : "none",
          }}
        >
          <Input
            placeholder="input your id"
            onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
          />
        </Form.Item>
      )}

      <Form.Item
        name="Email"
        label={"Email"}
        className="form-double-col-1"
        validateTrigger="onBlur" // validate after finishing input
        rules={[
          {
            type: "email",
            message: "Please input a valid E-mail.",
          },
          {
            required: FORM_REQUIRED,
            message: "Please input your E-mail.",
          },
        ]}
      >
        <Input
          placeholder="input your email"
          onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
        />
      </Form.Item>
      <Form.Item
        name="Mobile No"
        label={"Mobile No"}
        className="form-double-col-2"
        validateTrigger="onBlur" // validate after finishing input
        rules={[
          {
            pattern: /^\d{7,20}$/,
            message:
              "Please enter a valid mobile number with correct length and without any symbols.",
          },
          {
            required: FORM_REQUIRED,
            message: "Please input your Mobile Number.",
          },
        ]}
      >
        <Input placeholder="input your phone number" />
      </Form.Item>
      <Form.Item style={{ textAlign: "right" }}>
        <Button
          style={{ background: "white", color: "black" }}
          htmlType="submit"
        >
          Next
        </Button>
      </Form.Item>
    </Form>
  );
}
