import "../styles/LoadingPage.css";

import { Skeleton } from "antd";

function LandingPage() {
  return (
    <>
      <Skeleton
        className="loading-page-style"
        active={true}
        paragraph={{ rows: 10 }}
        style={{ height: "845px", display: "flex", alignItems: "center" }}
      />
    </>
  );
}

export default LandingPage;
