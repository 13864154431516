import "../../styles/Form.css";
import { Form, Button, Select, Input } from "antd";
import { FORM_REQUIRED, BACKEND_URL, INDONESIAN_LOCALE } from "../../config";
import { useState, useEffect } from "react";
import { fetchHelper } from "../../lib/fetch";

const checkOrganisationUserEmailExists = async (rule, value, callback) => {
  try {
    const response = await fetch(
      BACKEND_URL + "/checkUniqueOrganisationUserEmail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ OrganisationUserEmail: value }),
      }
    );

    const data = await response.json();

    if (!data.is_unique) {
      return Promise.reject(
        new Error("Email already exists. Please use another email.")
      );
    } else {
      return Promise.resolve();
    }
  } catch (error) {
    callback(error.message);
  }
};

export function OrganisationUserInfoForm({ form, onFinish, onPrevClick }) {
  const language = localStorage.getItem("language");

  const [designationList, setDesignationList] = useState([]);
  const [designationSelection, setDesignationSelection] = useState();

  function getDesignationSelection() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setDesignationList(data.designationData);
      setDesignationSelection(data.designationData[0]["value"]);
    };

    fetchHelper(
      BACKEND_URL + "/getDesignationSelection",
      cb,
      () => {},
      "Get Designation Selection",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    getDesignationSelection();
  }, []);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      name="registerInfo"
      scrollToFirstError
      autoComplete="off" // !important -> so that previously entered (and cleared) value is not remembered
    >
      <Form.Item
        name="Designation Id"
        label={language === INDONESIAN_LOCALE ? "Sebutan" : "Title"}
        rules={[
          {
            required: FORM_REQUIRED,
            message:
              language === INDONESIAN_LOCALE
                ? "Silahkan pilih judul."
                : "Please select title.",
          },
        ]}
      >
        <Select
          placeholder={
            language === INDONESIAN_LOCALE ? "pilih judul" : "select your title"
          }
          value={designationSelection}
          options={designationList}
          onChange={(value, option) => {
            setDesignationSelection(value);
            form.setFieldValue("Designation", option.label);
          }}
        />
      </Form.Item>

      <Form.Item name="Designation" hidden={true}></Form.Item>

      <Form.Item
        name="First Name"
        label={language === INDONESIAN_LOCALE ? "Nama Depan" : "First Name"}
        className="form-double-col-1"
        rules={[
          {
            required: FORM_REQUIRED,
            message:
              language === INDONESIAN_LOCALE
                ? "Silahkan masukkan nama depan."
                : "Please input your first name.",
          },
        ]}
      >
        <Input
          placeholder={
            language === INDONESIAN_LOCALE
              ? "masukkan nama depan"
              : "input your first name"
          }
          onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
        />
      </Form.Item>
      <Form.Item
        name="Last Name"
        label={language === INDONESIAN_LOCALE ? "Nama Belakang" : "Last Name"}
        className="form-double-col-2"
        rules={[
          {
            required: FORM_REQUIRED,
            message:
              language === INDONESIAN_LOCALE
                ? "Silahkan masukkan nama belakang."
                : "Please input your last name.",
          },
        ]}
      >
        <Input
          placeholder={
            language === INDONESIAN_LOCALE
              ? "masukkan nama belakang"
              : "input your last name"
          }
          onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
        />
      </Form.Item>

      <Form.Item
        name="Position"
        label={language === INDONESIAN_LOCALE ? "Jabatan" : "Position"}
        rules={[
          {
            required: FORM_REQUIRED,
            message:
              language === INDONESIAN_LOCALE
                ? "Silahkan masukkan posisi."
                : "Please input your position.",
          },
        ]}
      >
        <Input
          placeholder={
            language === INDONESIAN_LOCALE
              ? "masukkan posisi"
              : "input your position"
          }
          onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
        />
      </Form.Item>

      <Form.Item
        name="Email"
        label={language === INDONESIAN_LOCALE ? "Email" : "Email"}
        className="form-double-col-1"
        validateTrigger="onBlur" // validate after finishing input
        rules={[
          {
            type: "email",
            message:
              language === INDONESIAN_LOCALE
                ? "Silahkan masukkan email yang sah."
                : "Please input a valid E-mail.",
          },
          {
            required: FORM_REQUIRED,
            message:
              language === INDONESIAN_LOCALE
                ? "Silahkan masukkan email."
                : "Please input your E-mail.",
          },
          {
            validator: checkOrganisationUserEmailExists,
          },
        ]}
      >
        <Input
          placeholder={
            language === INDONESIAN_LOCALE
              ? "masukkan email"
              : "input your email"
          }
          onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
        />
      </Form.Item>
      <Form.Item
        name="Mobile No"
        label={language === INDONESIAN_LOCALE ? "Nomor Telepon" : "Mobile No"}
        className="form-double-col-2"
        validateTrigger="onBlur" // validate after finishing input
        rules={[
          {
            pattern: /^\d{7,20}$/,
            message:
              language === INDONESIAN_LOCALE
                ? "Silahkan masukkan nomor telepon yang sah."
                : "Please enter a valid mobile number with correct length and without any symbols.",
          },
          {
            required: FORM_REQUIRED,
            message:
              language === INDONESIAN_LOCALE
                ? "Silahkan masukkan nomor telepon."
                : "Please input your Mobile Number.",
          },
        ]}
      >
        <Input
          placeholder={
            language === INDONESIAN_LOCALE
              ? "masukkan nomor telepon"
              : "input your phone number"
          }
        />
      </Form.Item>

      <Form.Item style={{ textAlign: "right" }}>
        <Button
          style={{ background: "white", color: "black", marginRight: "5px" }}
          onClick={onPrevClick}
        >
          {language === INDONESIAN_LOCALE ? "Sebelumnya" : "Previous"}
        </Button>
        <Button
          style={{ background: "white", color: "black" }}
          htmlType="submit"
        >
          {language === INDONESIAN_LOCALE ? "Berikutnya" : "Next"}
        </Button>
      </Form.Item>
    </Form>
  );
}
