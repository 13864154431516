import "../../styles/DeviceRegistration/DeviceRegistration.css";
import "../../styles/DeviceRegistration/SupportingDocuments.css";
import "../../styles/General.css";

import {
  Form,
  Row,
  Col,
  Space,
  Button,
  message,
  Upload,
  Skeleton,
  Tooltip,
  Flex,
  Input,
} from "antd";

import { useState, useEffect } from "react";
import { BACKEND_URL, INDONESIAN_LOCALE, FORM_REQUIRED } from "../../config";
import { fetchHelper } from "../../lib/fetch";

import {
  UploadOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import FuelConsumptionStatementTemplate from "../../assets/FuelConsumptionStatement.xlsx";
import Guidance from "../../assets/METER_ID.jpeg";

export function OtherMeteringEvidence(props) {
  const {
    form,
    onFinish,
    onPrevClick,
    evidence,
    refreshForm,
    deviceOtherMeteringEvidenceData,
    deviceInfoData,
  } = props;

  const language = localStorage.getItem("language");

  // username of user currently logged in, to be passed to backend to retrieve organisation id
  const currentUser = JSON.parse(sessionStorage.getItem("loginData"))?.username;

  const [disabled, setDisabled] = useState(true);

  const [loadingMeterEvidence, setLoadingMeterEvidence] = useState(true);
  const [loadingFuelConsumptionStatement, setLoadingFuelConsumptionStatement] =
    useState(true);

  const [meterEvidenceBackendFileList, setMeterEvidenceBackendFileList] =
    useState([]);
  const [uploadingMeterEvidenceFile, setUploadingMeterEvidenceFile] =
    useState(null);

  const [
    fuelConsumptionStatementBackendFileList,
    setFuelConsumptionStatementBackendFileList,
  ] = useState([]);
  const [
    uploadingFuelConsumptionStatementFile,
    setUploadingFuelConsumptionStatementFile,
  ] = useState(null);

  /** This function is to check whether device name contains value of not.
   * If it does not, show error.
   * If it does, call saveDraft function to save draft in database.
   */
  function checkDeviceNameEmpty() {
    if (
      deviceInfoData["Device Name"] === undefined ||
      deviceInfoData["Device Name"] === ""
    ) {
      message.error(
        language === INDONESIAN_LOCALE
          ? "Anda harus memastikan nama perangkat di halaman Informasi Perangkat tidak kosong untuk menyimpan draf Anda"
          : "You must make sure device name in Device Information page is not empty in order to save your draft"
      );
    } else {
      saveDraft();
    }
  }

  /** This function is to save draft in database */
  function saveDraft() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        other_metering_evidence_meter_brand: form.getFieldValue("Meter Brand") // if form.getFieldValue("Username") is truthy (i.e. not undefined, null, 0, false, or an empty string), pass the value to backend; otherwise, pass an empty string
          ? form.getFieldValue("Meter Brand")
          : "",
        username: currentUser,
        device_name: deviceInfoData["Device Name"]
          ? deviceInfoData["Device Name"]
          : "",
        other_metering_evidence_meter_ID_list: form.getFieldValue(
          "Meter ID List"
        )
          ? form.getFieldValue("Meter ID List")
          : [],
      }),
    };
    const cb = (data) => {
      if (data.status) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Draf telah disimpan"
            : "Draft has been saved"
        );
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal menyimpan draf"
            : "Failed to save draft"
        );
      }
    };
    fetchHelper(
      BACKEND_URL + "/saveOtherMeteringEvidenceDraft",
      cb,
      () => {},
      "Save Other Metering Evidence Draft",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getAnalyticsPlatform(DeviceId) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        DeviceId: DeviceId,
        EvidenceSource: evidence["Metering Source"],
      }),
    };

    const cb = (data) => {
      if ("error" in data) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Gagal mendapat data sistem pemautauan pembalik daya surya"
            : data.error
        );
      } else {
        data["Meter ID List"] = JSON.parse(data["Meter ID List"]);
        form.setFieldsValue(data);
      }
    };

    fetchHelper(
      BACKEND_URL + "/getAnalyticsPlatform",
      cb,
      () => {},
      "Get Analytics Platform",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    var queryString = window.location.search;
    var queryStringWithoutQuestionMark = queryString.substring(1);

    if (
      queryStringWithoutQuestionMark.length != 0 &&
      props.refreshForm === false
    ) {
      getAnalyticsPlatform(queryStringWithoutQuestionMark);
    }
  }, []);

  // this useEffect is to ensure values of form items retained when next or previous button is clicked
  useEffect(() => {
    form.setFieldsValue(deviceOtherMeteringEvidenceData);
  }, []);

  // ---------------------------- Upload Meter Evidence related functions -----------------------------------

  const meterEvidenceFileList = meterEvidenceBackendFileList.map((element) => {
    return {
      uid: element.ID,
      name: element.Name,
      status: element.error ? "error" : "done",
      url: element.URL,
    };
  });
  if (uploadingMeterEvidenceFile !== null) {
    // To show upload progress
    meterEvidenceFileList.unshift(uploadingMeterEvidenceFile);
  }

  function getMeterEvidenceFileList() {
    let params = {
      current_user: currentUser,
      device_name: deviceInfoData["Device Name"],
    };

    let url_param = new URLSearchParams(params);

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setMeterEvidenceBackendFileList(data);
    };

    fetchHelper(
      `${BACKEND_URL}/otherMeterEvidenceUpload?${url_param}`,
      cb,
      setLoadingMeterEvidence,
      "Meter Evidence Upload",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function uploadMeterEvidenceFile(file) {
    setLoadingMeterEvidence(true);
    let forms = new FormData();

    forms.append("file", file);
    forms.append("session_username", currentUser);
    forms.append("device_name", deviceInfoData["Device Name"]);

    // Use XmlHttpRequest to be able to show progress
    // The alternative would be to use axios
    const xhr = new XMLHttpRequest();
    xhr.open("POST", BACKEND_URL + "/otherMeterEvidenceUpload");

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        const percent = (e.loaded / e.total) * 100;
        setUploadingMeterEvidenceFile({
          name: file.name,
          uid: -1,
          status: "uploading",
          percent: percent,
          url: "",
        });
      }
    };

    xhr.onload = function () {
      if (xhr.status === 200) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil memasukkan data"
            : "Successfully inserted data"
        );
        setUploadingMeterEvidenceFile(null);
        getMeterEvidenceFileList();
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal memasukkan data. Tipe file tidak didukung"
            : "Failed to insert data. File type is not supported."
        );
        setUploadingMeterEvidenceFile(null);
        setLoadingMeterEvidence(false);
      }
    };

    xhr.send(forms);
  }

  const customMeterEvidenceRequest = async ({ file, onSuccess, onError }) => {
    try {
      // Your custom logic for uploading the file
      const response = uploadMeterEvidenceFile(file);

      // Check for successful upload
      if (response.status === 200) {
        onSuccess();
      } else {
        onError("Upload failed");
      }
    } catch (error) {
      onError(error.message || "Upload failed");
    }
  };

  // ---------------------------- Upload Fuel Consumption Statement related functions -----------------------------------

  const fuelConsumptionStatementFileList =
    fuelConsumptionStatementBackendFileList.map((element) => {
      return {
        uid: element.ID,
        name: element.Name,
        status: element.error ? "error" : "done",
        url: element.URL,
      };
    });
  if (uploadingFuelConsumptionStatementFile !== null) {
    // To show upload progress
    fuelConsumptionStatementFileList.unshift(
      uploadingFuelConsumptionStatementFile
    );
  }

  function getFuelConsumptionStatementFileList() {
    let params = {
      current_user: currentUser,
      device_name: deviceInfoData["Device Name"],
    };

    let url_param = new URLSearchParams(params);

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setFuelConsumptionStatementBackendFileList(data);
    };

    fetchHelper(
      `${BACKEND_URL}/fuelConsumptionStatementUpload?${url_param}`,
      cb,
      setLoadingFuelConsumptionStatement,
      "Fuel Consumption Statement Upload",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function uploadFuelConsumptionStatementFile(file) {
    setLoadingFuelConsumptionStatement(true);
    let forms = new FormData();

    forms.append("file", file);
    forms.append("session_username", currentUser);
    forms.append("device_name", deviceInfoData["Device Name"]);

    // Use XmlHttpRequest to be able to show progress
    // The alternative would be to use axios
    const xhr = new XMLHttpRequest();
    xhr.open("POST", BACKEND_URL + "/fuelConsumptionStatementUpload");

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        const percent = (e.loaded / e.total) * 100;
        setUploadingFuelConsumptionStatementFile({
          name: file.name,
          uid: -1,
          status: "uploading",
          percent: percent,
          url: "",
        });
      }
    };

    xhr.onload = function () {
      if (xhr.status === 200) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil memasukkan data"
            : "Successfully inserted data"
        );
        setUploadingFuelConsumptionStatementFile(null);
        getFuelConsumptionStatementFileList();
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal memasukkan data. Tipe file tidak didukung"
            : "Failed to insert data. File type is not supported."
        );
        setUploadingFuelConsumptionStatementFile(null);
        setLoadingFuelConsumptionStatement(false);
      }
    };

    xhr.send(forms);
  }

  const customFuelConsumptionStatementRequest = async ({
    file,
    onSuccess,
    onError,
  }) => {
    try {
      // Your custom logic for uploading the file
      const response = uploadFuelConsumptionStatementFile(file);

      // Check for successful upload
      if (response.status === 200) {
        onSuccess();
      } else {
        onError("Upload failed");
      }
    } catch (error) {
      onError(error.message || "Upload failed");
    }
  };

  // ------------------------- Delete function for all files -----------------------------------

  function deleteFile(file) {
    // set loading when file is being deleted
    setLoadingMeterEvidence(true);
    setLoadingFuelConsumptionStatement(true);

    fetch(BACKEND_URL + "/supportingDocumentUpload/" + file.uid, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    }).then((response) => {
      if (response.status === 200) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil menghapus data"
            : "Successfully deleted data"
        );
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal menghapus data"
            : "Failed to delete data"
        );
      }
      getMeterEvidenceFileList();
      getFuelConsumptionStatementFileList();
    });
  }
  useEffect(() => {
    getMeterEvidenceFileList();
    getFuelConsumptionStatementFileList();
  }, []);

  return (
    <Form
      form={form}
      onFinish={(values) => {
        if (meterEvidenceFileList.length === 0) {
          message.error(
            language === INDONESIAN_LOCALE
              ? "Silahkan upload bukti meteran"
              : "Please upload meter evidence"
          );
        }

        if (meterEvidenceFileList.length !== 0) {
          onFinish(values);
        }
      }}
      className="device-registration-form-container"
      layout="vertical"
      scrollToFirstError
      autoComplete="off" // !important -> so that previously entered (and cleared) value is not remembered
    >
      <div
        id="device-registration-supporting-documents"
        className="device-registration-outer-container"
      >
        <div className="device-registration-content-container">
          <Row className="device-registration-content-container-row-layout">
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <h3 className="device-registration-h3-style">
                {language === INDONESIAN_LOCALE
                  ? "Daftarkan Perangkat Energi Terbarukan Baru"
                  : "Register New Renewable Energy Device"}
              </h3>
              <div className="device-registration-horizontal-line"></div>
              <h2 className="device-registration-h2-style">
                {language === INDONESIAN_LOCALE
                  ? "Bukti Pengukuran yang Lain"
                  : "Other Metering Evidence"}
              </h2>
              <br />

              {/* Display the uploading form items when all the files have been uploaded or fetched from backend */}
              <div
                style={{
                  display:
                    !loadingMeterEvidence && !loadingFuelConsumptionStatement
                      ? "block"
                      : "none",
                }}
              >
                <div className="device-registration-2-row-layout">
                  <div className="device-registration-2-wording-container">
                    <Flex gap={"4px"}>
                      <h4>
                        {language === INDONESIAN_LOCALE
                          ? "Bukti Meteran"
                          : "Meter Evidence"}
                      </h4>
                      <Tooltip
                        title={
                          language === INDONESIAN_LOCALE
                            ? "Anda hanya dapat mengunggah maksimal 3 dokemen. Jika Anda melebihi batas dan ingin mengunggah file lain, harap hapus yang lama, baru Anda dapat mengunggah yang baru."
                            : "You can only upload a maximum of 3 documents. If you exceed the limit and want to upload another file, please delete the old one then only you can upload the new one."
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Flex>
                    <h5>
                      {language === INDONESIAN_LOCALE
                        ? "Unggah foto angka meteran atau dokumen pendukung yang menyebut angka meteran untuk energi listrik yang telah dibangkitkan."
                        : "Upload a photo of the meter numbers or supporting documents that state the meter numbers for the electrical energy that has been generated."}
                    </h5>
                  </div>
                  <div className="device-registration-2-upload-btn-container">
                    <Upload
                      name="file"
                      className="device-registration-2-upload-btn"
                      fileList={meterEvidenceFileList}
                      customRequest={customMeterEvidenceRequest}
                      onRemove={deleteFile}
                      onChange={(info) => {
                        console.log(info.file);
                        if (info.file.status !== "uploading") {
                          console.log(info.file, info.fileList);
                        }
                        if (info.file.status === "done") {
                          message.success(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file berhasil diunggah`
                              : `${info.file.name} file uploaded successfully`
                          );
                        } else if (info.file.status === "error") {
                          message.error(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file gagal diunggah`
                              : `${info.file.name} file upload failed.`
                          );
                        }
                      }}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        disabled={
                          uploadingMeterEvidenceFile !== null ||
                          meterEvidenceFileList.length === 3
                        }
                      >
                        {language === INDONESIAN_LOCALE
                          ? "Klik untuk Unggah"
                          : "Click to Upload"}
                      </Button>
                    </Upload>
                  </div>
                </div>

                <div className="device-registration-2-row-layout">
                  <div className="device-registration-2-wording-container">
                    <Flex gap={"4px"}>
                      <h4>
                        {language === INDONESIAN_LOCALE
                          ? "Pernyataan Konsumsi Bahan Bakar (hanya untuk sistem bioenergi yang menggunakan lebih dari satu jenis bahan bakar)"
                          : "Fuel Consumption Statement (only for Bioenergy multi-fuelled)"}
                      </h4>
                      <Tooltip
                        title={
                          language === INDONESIAN_LOCALE
                            ? `Anda hanya dapat mengunggah maksimal 1 template Fuel Consumption Statement. 
                            Jika anda ingin mengunggah berkas lain, hapus berkas yang lama, baru anda dapat mengunggah berkas yang baru.`
                            : `You can only upload a maximum of 1 Fuel Consumption Statement. 
                            If you want to upload another file, please delete the old one then only you can upload the new one.`
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Flex>
                    <h5>
                      {language === INDONESIAN_LOCALE ? (
                        <>
                          Jika sistem bioenergi menggunakan lebih dari satu
                          jenis bahan bakar, isilah{" "}
                          <a
                            href={FuelConsumptionStatementTemplate}
                            download="FuelConsumptionStatementTemplate.xlsx"
                          >
                            template Fuel Consumption Statement
                          </a>{" "}
                          dan unggah kembali.
                        </>
                      ) : (
                        <>
                          If the bioenergy system uses more than one type of
                          fuel, fill out the{" "}
                          <a
                            href={FuelConsumptionStatementTemplate}
                            download="FuelConsumptionStatementTemplate.xlsx"
                          >
                            Fuel Consumption Statement template
                          </a>{" "}
                          and upload it again.
                        </>
                      )}
                    </h5>
                  </div>
                  <div className="device-registration-2-upload-btn-container">
                    <Upload
                      name="file"
                      className="device-registration-2-upload-btn"
                      fileList={fuelConsumptionStatementFileList}
                      customRequest={customFuelConsumptionStatementRequest}
                      onRemove={deleteFile}
                      onChange={(info) => {
                        if (info.file.status !== "uploading") {
                          console.log(info.file, info.fileList);
                        }
                        if (info.file.status === "done") {
                          message.success(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file berhasil diunggah`
                              : `${info.file.name} file uploaded successfully`
                          );
                        } else if (info.file.status === "error") {
                          message.error(
                            language === INDONESIAN_LOCALE
                              ? `${info.file.name} file gagal diunggah`
                              : `${info.file.name} file upload failed.`
                          );
                        }
                      }}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        disabled={
                          uploadingFuelConsumptionStatementFile !== null ||
                          fuelConsumptionStatementFileList.length === 1
                        }
                      >
                        {language === INDONESIAN_LOCALE
                          ? "Klik untuk Unggah"
                          : "Click to Upload"}
                      </Button>
                    </Upload>
                  </div>
                </div>

                <Form.Item
                  className="device-registration-long-form-item-style"
                  label={
                    language === INDONESIAN_LOCALE
                      ? "Merek Meteran"
                      : "Meter Brand"
                  }
                  name="Meter Brand"
                  tooltip={{
                    title:
                      language === INDONESIAN_LOCALE
                        ? "Silakan masukkan merek meteran yang menghitung jumlah energi listrik yang dibangkitkan."
                        : "Please input meter brand that calculates the amount of electrical energy generated.",
                    icon: <InfoCircleOutlined />,
                  }}
                  rules={[
                    {
                      required: FORM_REQUIRED,
                      message:
                        language === INDONESIAN_LOCALE
                          ? "Silakan masukkan merek meteran yang menghitung jumlah energi listrik yang dibangkitkan."
                          : "Please input meter brand that calculates the amount of electrical energy generated.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.List name="Meter ID List">
                  {(fields, { add, remove }) => (
                    <div
                      style={{
                        display: "flex",
                        rowGap: 16,
                        flexDirection: "column",
                        marginBottom: "20px",
                      }}
                    >
                      {fields.map((field) => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Form.Item
                            style={{ width: "97%" }}
                            label={
                              language === INDONESIAN_LOCALE
                                ? `ID Meter / Pencatat Data (Data Logger) ${
                                    field.name + 1
                                  }`
                                : `Meter / Logger ID ${field.name + 1}`
                            }
                            name={[field.name, "Meter ID"]}
                            rules={[
                              {
                                required: FORM_REQUIRED,
                                message:
                                  language === INDONESIAN_LOCALE
                                    ? "Silakan masukkan ID meter / pencatat data (data logger) Anda."
                                    : "Please input your meter / logger ID.",
                              },
                            ]}
                            tooltip={{
                              overlayClassName: "custom-meter-id-tooltip",
                              title: (
                                <div>
                                  <p>
                                    {language === INDONESIAN_LOCALE
                                      ? `Silakan masukkan ID meter / pencatat data (data logger) Anda.`
                                      : `Please refer to the picture to find where is
                                  your meter / logger ID.`}
                                  </p>
                                  <img
                                    src={Guidance}
                                    alt="meter id guidance"
                                    className="meter-id-guidance-photo"
                                  />
                                  <h5>
                                    {language === INDONESIAN_LOCALE
                                      ? `Unduh contoh `
                                      : `Download sample `}
                                    <a href={Guidance} download="sample.jpeg">
                                      {language === INDONESIAN_LOCALE
                                        ? `di sini`
                                        : `here`}
                                    </a>
                                    .
                                  </h5>
                                </div>
                              ),
                              icon: <InfoCircleOutlined />,
                            }}
                          >
                            <Input />
                          </Form.Item>
                          <Tooltip title="You may click on the button to remove Meter / Logger ID.">
                            <CloseOutlined
                              style={{ marginLeft: "10px" }}
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          </Tooltip>
                        </div>
                      ))}
                      <Button type="dashed" onClick={() => add()} block>
                        {language === INDONESIAN_LOCALE
                          ? `+ Tambah ID Meter / Pencatat Data (Data Logger)`
                          : `+ Add Meter / Logger ID`}
                      </Button>
                    </div>
                  )}
                </Form.List>

                {/* update disable state based on whether there is at least 1 meter id */}
                <Form.Item noStyle shouldUpdate>
                  {() =>
                    form.getFieldValue("Meter ID List")
                      ? form.getFieldValue("Meter ID List").length !== 0
                        ? form.getFieldValue("Meter ID List")[0]
                          ? setDisabled(false)
                          : setDisabled(true)
                        : setDisabled(true)
                      : setDisabled(true)
                  }
                </Form.Item>

                <Form.Item>
                  <Space className="device-registration-btn-layout">
                    <Button
                      className="device-registration-prev-btn-style"
                      onClick={onPrevClick}
                    >
                      {language === INDONESIAN_LOCALE
                        ? "Sebelumnya"
                        : "Previous"}
                    </Button>

                    {/* <Button className="device-registration-delete-draft-btn-style">
                    Delete Draft
                  </Button> */}

                    <Button
                      className="device-registration-save-draft-btn-style"
                      onClick={checkDeviceNameEmpty}
                    >
                      {language === INDONESIAN_LOCALE
                        ? "Simpan Draf"
                        : "Save Draft"}
                    </Button>

                    <Tooltip
                      title={
                        disabled
                          ? language === INDONESIAN_LOCALE
                            ? "Anda harus manghantar minimal 1 meter / ID logger."
                            : "You must submit at least 1 meter / logger ID."
                          : ""
                      }
                    >
                      <Button
                        className="device-registration-next-btn-style"
                        htmlType="submit"
                        disabled={disabled}
                      >
                        {language === INDONESIAN_LOCALE
                          ? "Dikirimkan"
                          : "Submit"}
                      </Button>
                    </Tooltip>
                  </Space>
                </Form.Item>
              </div>

              {/* Display loading when any of the files have not been uploaded or fetched from backend */}
              <div
                style={{
                  display:
                    loadingMeterEvidence || loadingFuelConsumptionStatement
                      ? "block"
                      : "none",
                }}
              >
                <Skeleton
                  active={true}
                  paragraph={{ rows: 10 }}
                  style={{ padding: "2rem", maxWidth: "70%", margin: "auto" }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Form>
  );
}
