import "../styles/DeviceRegistration/DeviceRegistration.css";
import "../styles/General.css";
import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import { Form, message } from "antd";

import { DeviceInfo } from "../components/device_registration/DeviceInfo";
import { SupportingDocuments } from "../components/device_registration/SupportingDocuments";
import { SupportingDocumentsPPA } from "../components/device_registration/SupportingDocumentsPPA";
import { MeteringEvidence } from "../components/device_registration/MeteringEvidence";
import { AnalyticPlatform } from "../components/device_registration/AnalyticPlatform";
import { SolarInverterMonitoringSystem } from "../components/device_registration/SolarInverterMonitoringSystem";
import { SARE } from "../components/device_registration/SARE";
import { OtherMeteringEvidence } from "../components/device_registration/OtherMeteringEvidence";

import { BACKEND_URL } from "../config";
import { fetchHelper } from "../lib/fetch";

function DeviceRegistration() {
  const navigate = useNavigate();

  // username of user currently logged in, to be passed to backend to retrieve organisation user id
  const currentUser = JSON.parse(sessionStorage.getItem("loginData"))?.username;

  const [current, setCurrent] = useState(0);

  const [deviceInfoForm] = Form.useForm();
  const [deviceSupportingDocumentsForm] = Form.useForm();
  const [deviceSupportingDocumentsPPAForm] = Form.useForm();
  const [deviceMeteringEvidenceForm] = Form.useForm();
  const [deviceReveaiAnalyticPlatformForm] = Form.useForm();
  const [deviceSolarInverterMonitoringPlatformForm] = Form.useForm();
  const [deviceSareForm] = Form.useForm();
  const [deviceOtherMeteringEvidenceForm] = Form.useForm();

  const [deviceInfoData, setDeviceInfoData] = useState();
  const [deviceSupportingDocumentsData, setDeviceSupportingDocumentsData] =
    useState();
  const [
    deviceSupportingDocumentsPPAData,
    setDeviceSupportingDocumentsPPAData,
  ] = useState();
  const [deviceMeteringEvidenceData, setDeviceMeteringEvidenceData] =
    useState();
  const [
    deviceReveaiAnalyticPlatformData,
    setDeviceReveaiAnalyticPlatformData,
  ] = useState();
  const [
    deviceSolarInverterMonitoringPlatformData,
    setDeviceSolarInverterMonitoringPlatformData,
  ] = useState();
  const [deviceSareData, setDeviceSareData] = useState();
  const [deviceOtherMeteringEvidenceData, setDeviceOtherMeteringEvidenceData] =
    useState();

  // savedDeviceName is the saved draft device name in db
  const [savedDeviceName, setSavedDeviceName] = useState("");

  // deviceId is used to keep device id retrieved from db (be it either the device is newly being registered by the user or draft device in db)
  const [deviceId, setDeviceId] = useState(0);

  // this country code is used to set state selection list
  // default country code will be Malaysia (MY)
  const [countryCode, setCountryCode] = useState("MY");

  // deviceFuelSelected is used to set device technology list
  const [deviceFuelSelected, setDeviceFuelSelected] = useState();
  // subset of device technology list (change according to device fuel selection)
  const [deviceTechnologyList, setDeviceTechnologyList] = useState([]);
  // device technology list retrieved from database
  const [allDeviceTechnologyList, setAllDeviceTechnologyList] = useState([]);

  const [deviceInfoDraftSaved, setDeviceInfoDraftSaved] = useState(false);

  // commissioning date for installing solar panel - it is input by user and used to calculate effective registration date
  const [commissioningDate, setCommissioningDate] = useState(null);

  // Last Issuance Date with another agent - it is input by user and used to calculate effective registration date
  const [lastRecContractDate, setLastRecContractDate] = useState(null);

  // determine if refresh form is required
  const [refreshForm_device_info, setRefreshFrom_device_form] = useState(false);
  const [refreshForm_metering_evidence, setRefreshFrom_metering_evidence] =
    useState(false);
  const [
    refreshForm_solar_inverter_monitoring,
    setRefreshFormSolarInverterMonitoring,
  ] = useState(false);
  const [refreshForm_analyticPlatform, setRefreshFormAnalyticPlatform] =
    useState(false);
  const [
    refreshFormOtherMeteringEvidence,
    setRefreshFormOtherMeteringEvidence,
  ] = useState(false);

  useEffect(() => {
    if (refreshForm_device_info === false) {
      setRefreshFrom_device_form(true);
    }
  }, [refreshForm_device_info]);

  useEffect(() => {
    if (refreshForm_metering_evidence === "Running") {
      setRefreshFrom_metering_evidence(true);
    }
  }, [refreshForm_metering_evidence]);

  useEffect(() => {
    if (refreshForm_solar_inverter_monitoring === "Running") {
      setRefreshFormSolarInverterMonitoring(true);
    }
  }, [refreshForm_solar_inverter_monitoring]);

  useEffect(() => {
    if (refreshForm_analyticPlatform === "Running") {
      setRefreshFormAnalyticPlatform(true);
    }
  }, [refreshForm_analyticPlatform]);

  useEffect(() => {
    if (refreshFormOtherMeteringEvidence === "Running") {
      setRefreshFormOtherMeteringEvidence(true);
    }
  }, [refreshFormOtherMeteringEvidence]);

  const steps = [
    {
      title: "Device Info",
      content: (
        <DeviceInfo
          form={deviceInfoForm}
          value={deviceInfoData} // pass the value back so that form items can be initialised to the previously input values
          refreshForm={refreshForm_device_info}
          onFinish={(values) => {
            setDeviceInfoData(values);
            setCurrent((prev) => prev + 1);
          }}
          savedDeviceName={savedDeviceName} // savedDeviceName is the saved draft device name in db
          setSavedDeviceName={setSavedDeviceName}
          deviceId={deviceId} // id of the savedDeviceName
          setDeviceId={setDeviceId}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          deviceFuelSelected={deviceFuelSelected}
          setDeviceFuelSelected={setDeviceFuelSelected}
          deviceTechnologyList={deviceTechnologyList}
          setDeviceTechnologyList={setDeviceTechnologyList}
          allDeviceTechnologyList={allDeviceTechnologyList}
          setAllDeviceTechnologyList={setAllDeviceTechnologyList}
          deviceInfoDraftSaved={deviceInfoDraftSaved}
          setDeviceInfoDraftSaved={setDeviceInfoDraftSaved}
          commissioningDate={commissioningDate}
          setCommissioningDate={setCommissioningDate}
          lastRecContractDate={lastRecContractDate}
          setLastRecContractDate={setLastRecContractDate}
        />
      ),
    },
    {
      title: "Supporting Documents",
      content: (
        <SupportingDocuments
          form={deviceSupportingDocumentsForm}
          onFinish={(values) => {
            setDeviceSupportingDocumentsData(values);
            if (deviceInfoData !== undefined) {
              if (deviceInfoData["Device Ownership"] === 2) {
                // if device ownership is PPA
                setCurrent((prev) => prev + 1); // set the next page to be supporting doc for PPA
              } else {
                setCurrent((prev) => prev + 2); // else set the next page to be metering evidence selection
              }
            } else {
              setCurrent((prev) => prev + 2); // by default set the next page to be metering evidence selection
            }
          }}
          onPrevClick={() => setCurrent((prev) => prev - 1)}
          deviceInfoData={deviceInfoData !== undefined ? deviceInfoData : ""}
        />
      ),
    },
    {
      title: "Supporting Documents for PPA",
      content: (
        <SupportingDocumentsPPA
          form={deviceSupportingDocumentsPPAForm}
          onFinish={(values) => {
            setDeviceSupportingDocumentsPPAData(values);
            setCurrent((prev) => prev + 1);
          }}
          onPrevClick={() => setCurrent((prev) => prev - 1)}
          deviceInfoData={deviceInfoData !== undefined ? deviceInfoData : ""}
        />
      ),
    },
    {
      title: "Metering Evidence",
      content: (
        <MeteringEvidence
          refreshForm={refreshForm_metering_evidence}
          form={deviceMeteringEvidenceForm}
          value={deviceMeteringEvidenceData} // pass the value back so that form items can be initialised to the previously input values
          onFinish={(values) => {
            setDeviceMeteringEvidenceData(values);
            setCurrent((prev) => prev + 1);
            setRefreshFrom_metering_evidence("Running");
          }}
          onPrevClick={() => {
            setRefreshFrom_metering_evidence("Running");
            if (deviceInfoData !== undefined) {
              if (deviceInfoData["Device Ownership"] === 2) {
                // if device ownership is PPA
                setCurrent((prev) => prev - 1); // set the previous page to be supporting doc for PPA
              } else {
                setCurrent((prev) => prev - 2); // else set the previous page to be supporting doc for general doc
              }
            } else {
              setCurrent((prev) => prev - 2); // by default set the previous page to be supporting doc for general doc
            }
          }}
          deviceInfoData={deviceInfoData !== undefined ? deviceInfoData : ""} // pass this value so that device name can be retrieved
        />
      ),
    },
    {
      title: deviceMeteringEvidenceData
        ? deviceMeteringEvidenceData["Metering Source"] === 1
          ? "REVEAI Analytic Platform"
          : deviceMeteringEvidenceData["Metering Source"] === 2
          ? "Solar Inverter Monitoring System"
          : deviceMeteringEvidenceData["Metering Source"] === 3
          ? "SARE"
          : "Other"
        : "",
      content: deviceMeteringEvidenceData ? (
        deviceMeteringEvidenceData["Metering Source"] === 1 ? (
          <AnalyticPlatform
            refreshForm={refreshForm_analyticPlatform}
            form={deviceReveaiAnalyticPlatformForm}
            value={deviceReveaiAnalyticPlatformData}
            evidence={deviceMeteringEvidenceData}
            onFinish={(values) => {
              setRefreshFormAnalyticPlatform("Running");
              setDeviceReveaiAnalyticPlatformData(values);
            }}
            onPrevClick={() => {
              setRefreshFormAnalyticPlatform("Running");
              setCurrent((prev) => prev - 1);
            }}
            deviceInfoData={deviceInfoData !== undefined ? deviceInfoData : ""} // pass this value so that device name can be retrieved
          />
        ) : deviceMeteringEvidenceData["Metering Source"] === 2 ? (
          <SolarInverterMonitoringSystem
            refreshForm={refreshForm_solar_inverter_monitoring}
            form={deviceSolarInverterMonitoringPlatformForm}
            evidence={deviceMeteringEvidenceData}
            value={deviceSolarInverterMonitoringPlatformData}
            onFinish={(values) => {
              setRefreshFormSolarInverterMonitoring("Running");
              setDeviceSolarInverterMonitoringPlatformData(values);
            }}
            onPrevClick={() => {
              setRefreshFormSolarInverterMonitoring("Running");
              setCurrent((prev) => prev - 1);
            }}
            deviceInfoData={deviceInfoData !== undefined ? deviceInfoData : ""} // pass this value so that device name can be retrieved
          />
        ) : deviceMeteringEvidenceData["Metering Source"] === 3 ? (
          <SARE
            form={deviceSareForm}
            value={deviceSareData}
            onFinish={(values) => {
              setDeviceSareData(values);
              addDeviceInfo();
            }}
            onPrevClick={() => setCurrent((prev) => prev - 1)}
            deviceInfoData={deviceInfoData !== undefined ? deviceInfoData : ""}
          />
        ) : (
          <OtherMeteringEvidence
            refreshForm={refreshFormOtherMeteringEvidence}
            form={deviceOtherMeteringEvidenceForm}
            evidence={deviceMeteringEvidenceData}
            value={deviceOtherMeteringEvidenceData}
            onFinish={(values) => {
              setRefreshFormOtherMeteringEvidence("Running");
              setDeviceOtherMeteringEvidenceData(values);
            }}
            onPrevClick={() => {
              setRefreshFormOtherMeteringEvidence("Running");
              setCurrent((prev) => prev - 1);
            }}
            deviceInfoData={deviceInfoData !== undefined ? deviceInfoData : ""}
          />
        )
      ) : (
        ""
      ),
    },
  ];

  function addDeviceInfo() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        device_name: deviceInfoData["Device Name"],
        device_address: deviceInfoData["Device Address"],
        city: deviceInfoData["City"],
        postcode: deviceInfoData["Postcode"],
        state: deviceInfoData["State"],
        country: deviceInfoData["Country"],
        device_location_longitude: deviceInfoData["Device Location Longitude"],
        device_location_latitude: deviceInfoData["Device Location Latitude"],
        device_fuel: deviceInfoData["Device Fuel"],
        device_technology: deviceInfoData["Device Technology"],
        device_capacity_in_watt:
          deviceInfoData["Capacity"]["Capacity Unit"] === "kW" ||
          deviceInfoData["Capacity"]["Capacity Unit"] == "kWac"
            ? parseFloat(deviceInfoData["Capacity"]["Capacity Value"]) * 1000
            : parseFloat(deviceInfoData["Capacity"]["Capacity Value"]) *
              1000000,
        device_ownership: deviceInfoData["Device Ownership"],
        device_commissioning_date:
          deviceInfoData["Commissioning Date"].format("YYYY-MM-DD"),
        device_last_issuance_date: deviceInfoData["Last Issuance Date"]
          ? deviceInfoData["Last Issuance Date"].format("YYYY-MM-DD")
          : "", // if there is value for last issuance date, store the value, else store empty string
        device_effective_registration_date: deviceInfoData[
          "Effective Registration Date"
        ]
          ? deviceInfoData["Effective Registration Date"]
          : "",
        is_device_owner: deviceInfoData["Is Device Owner"],
        device_owner: deviceInfoData["Device Owner"]
          ? deviceInfoData["Device Owner"]
          : "",
        is_captive_consumer: deviceInfoData["Is Captive Consumer"],
        captive_consumer: deviceInfoData["Captive Consumer"]
          ? deviceInfoData["Captive Consumer"]
          : "",

        device_metering_evidence: deviceMeteringEvidenceData["Metering Source"],

        reveai_username: deviceReveaiAnalyticPlatformData
          ? deviceReveaiAnalyticPlatformData["Username"]
            ? deviceReveaiAnalyticPlatformData["Username"]
            : ""
          : "",
        reveai_device_id: deviceReveaiAnalyticPlatformData
          ? deviceReveaiAnalyticPlatformData["Device ID"]
            ? deviceReveaiAnalyticPlatformData["Device ID"]
            : ""
          : "",

        solar_inverter_monitoring_system_website:
          deviceSolarInverterMonitoringPlatformData
            ? deviceSolarInverterMonitoringPlatformData["Website"]
              ? deviceSolarInverterMonitoringPlatformData["Website"]
              : ""
            : "",
        solar_inverter_monitoring_system_username:
          deviceSolarInverterMonitoringPlatformData
            ? deviceSolarInverterMonitoringPlatformData["Username"]
              ? deviceSolarInverterMonitoringPlatformData["Username"]
              : ""
            : "",
        solar_inverter_monitoring_system_password:
          deviceSolarInverterMonitoringPlatformData
            ? deviceSolarInverterMonitoringPlatformData["Password"]
              ? deviceSolarInverterMonitoringPlatformData["Password"]
              : ""
            : "",
        solar_inverter_monitoring_system_plant_name:
          deviceSolarInverterMonitoringPlatformData
            ? deviceSolarInverterMonitoringPlatformData["Plant Name"]
              ? deviceSolarInverterMonitoringPlatformData["Plant Name"]
              : ""
            : "",
        meter_ID_list: deviceSolarInverterMonitoringPlatformData
          ? deviceSolarInverterMonitoringPlatformData["Meter ID List"]
            ? deviceSolarInverterMonitoringPlatformData["Meter ID List"]
            : []
          : [],

        other_metering_evidence_meter_brand: deviceOtherMeteringEvidenceData
          ? deviceOtherMeteringEvidenceData["Meter Brand"]
            ? deviceOtherMeteringEvidenceData["Meter Brand"]
            : ""
          : "",
        other_metering_evidence_meter_ID_list: deviceOtherMeteringEvidenceData
          ? deviceOtherMeteringEvidenceData["Meter ID List"]
            ? deviceOtherMeteringEvidenceData["Meter ID List"]
            : []
          : [],

        username: currentUser,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success("All the information you submitted has been saved.");
        navigate("/deviceregistrationconfirmation", { replace: true });
        sendSubmitNotification();
      } else {
        message.error(data.error);
      }
    };

    fetchHelper(
      BACKEND_URL + "/addDeviceInfo",
      cb,
      () => {},
      "Add Device Info",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function sendSubmitNotification() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        username: currentUser,
      }),
    };

    const cb = () => {};

    fetchHelper(
      BACKEND_URL + "/sendSubmitNotification",
      cb,
      () => {},
      "Send Submit Notification",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  // useEffect(() => {
  //   console.log(deviceInfoData);
  // }, [deviceInfoData]);

  // useEffect(() => {
  //   if (deviceInfoData !== undefined) {
  //     console.log(deviceInfoData["Capacity"]["Capacity Value"]);
  //   }
  // }, [deviceInfoData]);

  // useEffect(() => {
  //   if (deviceMeteringEvidenceData !== undefined) {
  //     console.log(deviceMeteringEvidenceData["Metering Source"]);
  //   }
  // }, [deviceMeteringEvidenceData]);

  useEffect(() => {
    // call addDeviceInfo function only if deviceReveaiAnalyticPlatformData state changes and if it's not undefined
    // the if statement is to make sure no error thrown as at the beginning, deviceReveaiAnalyticPlatformData will be undefined
    if (deviceReveaiAnalyticPlatformData !== undefined) {
      addDeviceInfo();
    }
  }, [deviceReveaiAnalyticPlatformData]);

  useEffect(() => {
    // call addDeviceInfo function only if deviceSolarInverterMonitoringPlatformData state changes and if it's not undefined
    // the if statement is to make sure no error thrown as at the beginning, deviceSolarInverterMonitoringPlatformData will be undefined
    if (deviceSolarInverterMonitoringPlatformData !== undefined) {
      addDeviceInfo();
    }
  }, [deviceSolarInverterMonitoringPlatformData]);

  useEffect(() => {
    // call addDeviceInfo function only if deviceOtherMeteringEvidenceData state changes and if it's not undefined
    // the if statement is to make sure no error thrown as at the beginning, deviceOtherMeteringEvidenceData will be undefined
    if (deviceOtherMeteringEvidenceData !== undefined) {
      addDeviceInfo();
    }
  }, [deviceOtherMeteringEvidenceData]);

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return <div>{steps[current].content}</div>;
}

export default DeviceRegistration;
