import "../../styles/Form.css";
import { Form, Button, Input, Select } from "antd";
import { useState, useEffect } from "react";
import { FORM_REQUIRED, BACKEND_URL, INDONESIAN_LOCALE } from "../../config";

const checkOrganisationRegistrationNumExists = async (
  rule,
  value,
  callback
) => {
  try {
    const response = await fetch(
      BACKEND_URL + "/checkUniqueOrganisationRegistrationNum",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          OrganisationRegistrationNum: value,
        }),
      }
    );

    const data = await response.json();

    if (!data.is_unique) {
      return Promise.reject(
        new Error(
          "Organisation registration number already exists. Please try another organisation registration number."
        )
      );
    } else {
      return Promise.resolve();
    }
  } catch (error) {
    callback(error.message);
  }
};

export function OrganisationInfoForm({
  form,
  onFinish,
  countryCode,
  setCountryCode,
}) {
  const language = localStorage.getItem("language");

  const [countries, setCountries] = useState([]); // country list used for country selection
  const [states, setStates] = useState([]); // state list used for state selection

  // country api from online source
  var config = {
    cUrl: "https://api.countrystatecity.in/v1/countries",
    ckey: "M1dwVXhSTlh3QmQycFBkQXp0S053NFdLSnhZWThUeDV6dUhsQm9VYQ==",
  };

  const loadCountries = async () => {
    try {
      const response = await fetch(config.cUrl, {
        headers: {
          "X-CSCAPI-KEY": config.ckey,
        },
      });
      const data = await response.json();
      setCountries(data);
    } catch (error) {
      console.error("Error loading countries:", error);
    }
  };

  const loadStates = async () => {
    try {
      const response = await fetch(`${config.cUrl}/${countryCode}/states`, {
        headers: {
          "X-CSCAPI-KEY": config.ckey,
        },
      });
      const data = await response.json();
      setStates(data);
    } catch (error) {
      console.error("Error loading states:", error);
    }
  };

  // this useEffect is to get country selection at the beginning
  useEffect(() => {
    loadCountries();
  }, []);

  // this useEffect is used to set state list when country code changes and decide whether to disable state selection or not
  // if country has not been selected, state selection will be disabled
  // if country has been selected, state selection will be enabled
  useEffect(() => {
    loadStates();
  }, [countryCode]);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      name="registerInfo"
      scrollToFirstError
      autoComplete="off" // !important -> so that previously entered (and cleared) value is not remembered
    >
      <Form.Item
        name="Organisation Name"
        label={
          language === INDONESIAN_LOCALE
            ? "Nama Organisasi"
            : "Organisation Name"
        }
        className="form-double-col-1"
        rules={[
          {
            required: FORM_REQUIRED,
            message:
              language === INDONESIAN_LOCALE
                ? "Silahkan masukkan nama organisasi."
                : "Please input your Organisation Name.",
          },
        ]}
      >
        <Input
          placeholder={
            language === INDONESIAN_LOCALE
              ? "masukkan nama organisasi"
              : "input your organisation name"
          }
          onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
        />
      </Form.Item>

      <Form.Item
        name="Organisation Registration Number"
        label={
          language === INDONESIAN_LOCALE
            ? "Nomor Induk Berusaha"
            : "Organisation Registration Number"
        }
        className="form-double-col-2"
        validateTrigger="onBlur" // validate after finishing input
        rules={[
          {
            required: FORM_REQUIRED,
            message:
              language === INDONESIAN_LOCALE
                ? "Silahkan masukkan nomor pendaftaran organisasi."
                : "Please input your Organisation Registration Number.",
          },
          {
            validator: checkOrganisationRegistrationNumExists,
          },
        ]}
      >
        <Input
          placeholder={
            language === INDONESIAN_LOCALE
              ? "masukkan nomor pendaftaran organisasi"
              : "input your organisation registration number"
          }
          onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
        />
      </Form.Item>

      <Form.Item
        name="Address Line 1"
        label={
          language === INDONESIAN_LOCALE
            ? "Alamat Organisasi Baris 1"
            : "Address Line 1"
        }
        className="form-double-col-1"
        rules={[
          {
            required: FORM_REQUIRED,
            message:
              language === INDONESIAN_LOCALE
                ? "Silahkan masukkan alamat organisasi."
                : `Please input your Organisation's Address.`,
          },
        ]}
      >
        <Input
          placeholder={
            language === INDONESIAN_LOCALE
              ? "masukkan alamat organisasi"
              : "input your organisation address"
          }
          onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
        />
      </Form.Item>

      <Form.Item
        name="Address Line 2"
        label={
          language === INDONESIAN_LOCALE
            ? "Alamat Organisasi Baris 2"
            : "Address Line 2"
        }
        className="form-double-col-2"
      >
        <Input
          placeholder={
            language === INDONESIAN_LOCALE
              ? "masukkan alamat organisasi"
              : "input your organisation address"
          }
          onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
        />
      </Form.Item>

      <Form.Item
        name="City"
        label={language === INDONESIAN_LOCALE ? "Kota" : "City"}
        className="form-double-col-1"
        rules={[
          {
            required: FORM_REQUIRED,
            message:
              language === INDONESIAN_LOCALE
                ? "Silahkan masukkan kota organisasi."
                : `Please input your Organistion's City.`,
          },
        ]}
      >
        <Input
          placeholder={
            language === INDONESIAN_LOCALE ? "masukkan kota" : "input city"
          }
          onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
        />
      </Form.Item>

      <Form.Item
        name="Postcode"
        label={language === INDONESIAN_LOCALE ? "Kode Pos" : "Postcode"}
        className="form-double-col-2"
        rules={[
          {
            required: FORM_REQUIRED,
            message:
              language === INDONESIAN_LOCALE
                ? "Silahkan masukkan poskod organisasi."
                : `Please input your Organistion's Postcode.`,
          },
        ]}
      >
        <Input
          placeholder={
            language === INDONESIAN_LOCALE
              ? "masukkan poskod"
              : "input postcode"
          }
          onInput={(e) => (e.target.value = e.target.value.toUpperCase())} //postcode for some country consists of alphabets, eg Canada
        />
      </Form.Item>

      <Form.Item
        name="State"
        label={language === INDONESIAN_LOCALE ? "Provinsi" : "State"}
        className="form-double-col-1"
        rules={[
          {
            required: FORM_REQUIRED,
            message:
              language === INDONESIAN_LOCALE
                ? "Silahkan pilih negeri organisasi."
                : `Please select your Organistion's State.`,
          },
        ]}
      >
        <Select
          placeholder={
            language === INDONESIAN_LOCALE ? "pilih negeri" : "select a state"
          }
          showSearch
          options={states.map((state) => ({
            label: state.name.toUpperCase(),
            value: state.name.toUpperCase(),
            key: state.iso2,
          }))}
        ></Select>
      </Form.Item>

      <Form.Item
        name="Country"
        label={language === INDONESIAN_LOCALE ? "Negara" : "Country"}
        initialValue="MALAYSIA"
        className="form-double-col-2"
        rules={[
          {
            required: FORM_REQUIRED,
            message:
              language === INDONESIAN_LOCALE
                ? "Silahkan pilih negara organisasi."
                : `Please select your Organistion's Country.`,
          },
        ]}
      >
        <Select
          placeholder={
            language === INDONESIAN_LOCALE ? "pilih negara" : "select a country"
          }
          showSearch
          onChange={(_, value) => {
            // _ is value itself, value is label, value, key
            setCountryCode(value["key"]);
            form.setFieldValue("State", null);
          }}
          options={countries.map((country) => ({
            label: country.name.toUpperCase(),
            value: country.name.toUpperCase(),
            key: country.iso2,
          }))}
        ></Select>
      </Form.Item>

      <Form.Item style={{ textAlign: "right" }}>
        <Button
          style={{ background: "white", color: "black" }}
          htmlType="submit"
        >
          {language === INDONESIAN_LOCALE ? "Berikutnya" : "Next"}
        </Button>
      </Form.Item>
    </Form>
  );
}
