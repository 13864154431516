import "../../styles/DeviceRegistration/DeviceRegistration.css";
import "../../styles/DeviceRegistration/SolarInverterMonitoringSystem.css";
import "../../styles/General.css";

import { Form, Input, Row, Col, Space, Button, message, Tooltip } from "antd";

import { useEffect, useState } from "react";
import { FORM_REQUIRED, BACKEND_URL, INDONESIAN_LOCALE } from "../../config";
import { InfoCircleOutlined, CloseOutlined } from "@ant-design/icons";

import { fetchHelper } from "../../lib/fetch";
import Guidance from "../../assets/METER_ID.jpeg";

export function SolarInverterMonitoringSystem(props) {
  const {
    form,
    onFinish,
    onPrevClick,
    evidence,
    refreshForm,
    deviceSolarInverterMonitoringPlatformData,
    deviceInfoData,
  } = props;

  const language = localStorage.getItem("language");

  // username of user currently logged in, to be passed to backend to retrieve organisation user id
  const currentUser = JSON.parse(sessionStorage.getItem("loginData"))?.username;

  const [disabled, setDisabled] = useState(true);

  /** This function is to check whether device name contains value of not.
   * If it does not, show error.
   * If it does, call saveDraft function to save draft in database.
   */
  function checkDeviceNameEmpty() {
    if (
      deviceInfoData["Device Name"] === undefined ||
      deviceInfoData["Device Name"] === ""
    ) {
      message.error(
        language === INDONESIAN_LOCALE
          ? "Anda harus memastikan nama perangkat di halaman Informasi Perangkat tidak kosong untuk menyimpan draf Anda"
          : "You must make sure device name in Device Information page is not empty in order to save your draft"
      );
    } else {
      saveDraft();
    }
  }

  /** This function is to save draft in database */
  function saveDraft() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        solar_inverter_monitoring_system_website: form.getFieldValue("Website") // if form.getFieldValue("Username") is truthy (i.e. not undefined, null, 0, false, or an empty string), pass the value to backend; otherwise, pass an empty string
          ? form.getFieldValue("Website")
          : "",
        solar_inverter_monitoring_system_username: form.getFieldValue(
          "Username"
        )
          ? form.getFieldValue("Username")
          : "",
        solar_inverter_monitoring_system_password: form.getFieldValue(
          "Password"
        ) // if form.getFieldValue("Username") is truthy (i.e. not undefined, null, 0, false, or an empty string), pass the value to backend; otherwise, pass an empty string
          ? form.getFieldValue("Password")
          : "",
        solar_inverter_monitoring_system_plant_name: form.getFieldValue(
          "Plant Name"
        ) // if form.getFieldValue("Username") is truthy (i.e. not undefined, null, 0, false, or an empty string), pass the value to backend; otherwise, pass an empty string
          ? form.getFieldValue("Plant Name")
          : "",
        username: currentUser,
        device_name: deviceInfoData["Device Name"]
          ? deviceInfoData["Device Name"]
          : "",
        meter_ID_list: form.getFieldValue("Meter ID List")
          ? form.getFieldValue("Meter ID List")
          : [],
      }),
    };
    const cb = (data) => {
      if (data.status) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Draf telah disimpan"
            : "Draft has been saved"
        );
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal menyimpan draf"
            : "Failed to save draft"
        );
      }
    };
    fetchHelper(
      BACKEND_URL + "/saveSolarInverterMonitoringSystemDraft",
      cb,
      () => {},
      "Save Solar Inverter Monitoring System Draft",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getAnalyticsPlatform(DeviceId) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        DeviceId: DeviceId,
        EvidenceSource: evidence["Metering Source"],
      }),
    };

    const cb = (data) => {
      if ("error" in data) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Gagal mendapat data sistem pemautauan pembalik daya surya"
            : data.error
        );
      } else {
        data["Meter ID List"] = JSON.parse(data["Meter ID List"]);
        form.setFieldsValue(data);
      }
    };

    fetchHelper(
      BACKEND_URL + "/getAnalyticsPlatform",
      cb,
      () => {},
      "Get Analytics Platform",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    var queryString = window.location.search;
    var queryStringWithoutQuestionMark = queryString.substring(1);

    if (
      queryStringWithoutQuestionMark.length != 0 &&
      props.refreshForm === false
    ) {
      getAnalyticsPlatform(queryStringWithoutQuestionMark);
    }
  }, []);

  // this useEffect is to ensure values of form items retained when next or previous button is clicked
  useEffect(() => {
    form.setFieldsValue(deviceSolarInverterMonitoringPlatformData);
  }, []);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      className="device-registration-form-container"
      layout="vertical"
      scrollToFirstError
      autoComplete="off"
    >
      <div className="device-registration-outer-container">
        <div className="device-registration-content-container">
          <Row className="device-registration-content-container-row-layout">
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <h3 className="device-registration-h3-style">
                {language === INDONESIAN_LOCALE
                  ? "Daftarkan Perangkat Energi Terbarukan Baru"
                  : "Register New Renewable Energy Device"}
              </h3>
              <div className="device-registration-horizontal-line"></div>
              <h2 className="device-registration-h2-style">
                {language === INDONESIAN_LOCALE
                  ? "Sistem Pemantauan Pembalik Daya Surya"
                  : "Solar Inverter Monitoring System"}
              </h2>
              <br />

              <Form.Item
                className="device-registration-long-form-item-style"
                label={language === INDONESIAN_LOCALE ? "Situs Web" : "Website"}
                name="Website"
                tooltip={{
                  title:
                    language === INDONESIAN_LOCALE
                      ? "Silakan masukkan situs web sistem pemantauan pembalik daya surya yang Anda gunakan."
                      : "Please input the website of the solar inverter monitoring system you use.",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: FORM_REQUIRED,
                    message:
                      language === INDONESIAN_LOCALE
                        ? "Silakan masukkan situs web sistem pemantauan pembalik daya surya yang Anda gunakan."
                        : "Please input the website of the solar inverter monitoring system you use.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="device-registration-long-form-item-style"
                label={
                  language === INDONESIAN_LOCALE ? "Nama Pengguna" : "Username"
                }
                name="Username"
                tooltip={{
                  title:
                    language === INDONESIAN_LOCALE
                      ? "Silakan masukkan nama pengguna untuk situs web sistem pemantauan pembalik daya surya yang Anda gunakan."
                      : "Please input your username for the website of the solar inverter monitoring system you use.",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: FORM_REQUIRED,
                    message:
                      language === INDONESIAN_LOCALE
                        ? "Silakan masukkan nama pengguna untuk situs web sistem pemantauan pembalik daya surya yang Anda gunakan."
                        : "Please input your username for the website of the solar inverter monitoring system you use.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="device-registration-long-form-item-style"
                label={
                  language === INDONESIAN_LOCALE
                    ? "Kata Sandi (hanya untuk penggunaan bukti pengukuran)"
                    : "Password (strictly for metering evident use only)"
                }
                name="Password"
                tooltip={{
                  title:
                    language === INDONESIAN_LOCALE
                      ? "Silakan masukkan kata sandi untuk situs web sistem pemantauan pembalik daya surya yang Anda gunakan. Kata sandi ini hanya untuk penggunaan bukti pengukuran."
                      : "Please input your password for the website of the solar inverter monitoring system you use. The password is strictly for metering evident use only.",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: FORM_REQUIRED,
                    message:
                      language === INDONESIAN_LOCALE
                        ? "Silakan masukkan kata sandi untuk situs web sistem pemantauan pembalik daya surya yang Anda gunakan. Kata sandi ini hanya untuk penggunaan bukti pengukuran."
                        : "Please input your password for the website of the solar inverter monitoring system you use.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="device-registration-long-form-item-style"
                label={
                  language === INDONESIAN_LOCALE ? "Nama Pabrik" : "Plant Name"
                }
                name="Plant Name"
                tooltip={{
                  title:
                    language === INDONESIAN_LOCALE
                      ? "Silakan masukkan nama pabrik jika ada lebih dari satu pabrik di akun Anda."
                      : "Please input your plant name if there is more than one plant under the account.",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input />
              </Form.Item>

              <Form.List name="Meter ID List">
                {(fields, { add, remove }) => (
                  <div
                    style={{
                      display: "flex",
                      rowGap: 16,
                      flexDirection: "column",
                      marginBottom: "20px",
                    }}
                  >
                    {fields.map((field) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Form.Item
                          style={{ width: "97%" }}
                          label={
                            language === INDONESIAN_LOCALE
                              ? `ID Meter / Pencatat Data (Data Logger) ${
                                  field.name + 1
                                }`
                              : `Meter / Logger ID ${field.name + 1}`
                          }
                          name={[field.name, "Meter ID"]}
                          rules={[
                            {
                              required: FORM_REQUIRED,
                              message:
                                language === INDONESIAN_LOCALE
                                  ? "Silakan masukkan ID meter / pencatat data (data logger) Anda."
                                  : "Please input your meter / logger ID.",
                            },
                          ]}
                          tooltip={{
                            overlayClassName: "custom-meter-id-tooltip",
                            title: (
                              <div>
                                <p>
                                  {language === INDONESIAN_LOCALE
                                    ? `Silakan masukkan ID meter / pencatat data (data logger) Anda.`
                                    : `Please refer to the picture to find where is
                                  your meter / logger ID.`}
                                </p>
                                <img
                                  src={Guidance}
                                  alt="meter id guidance"
                                  className="meter-id-guidance-photo"
                                />
                                <h5>
                                  {language === INDONESIAN_LOCALE
                                    ? `Unduh contoh `
                                    : `Download sample `}
                                  <a href={Guidance} download="sample.jpeg">
                                    {language === INDONESIAN_LOCALE
                                      ? `di sini`
                                      : `here`}
                                  </a>
                                  .
                                </h5>
                              </div>
                            ),
                            icon: <InfoCircleOutlined />,
                          }}
                        >
                          <Input />
                        </Form.Item>
                        <Tooltip title="You may click on the button to remove Meter / Logger ID.">
                          <CloseOutlined
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Tooltip>
                      </div>
                    ))}
                    <Button type="dashed" onClick={() => add()} block>
                      {language === INDONESIAN_LOCALE
                        ? `+ Tambah ID Meter / Pencatat Data (Data Logger)`
                        : `+ Add Meter / Logger ID`}
                    </Button>
                  </div>
                )}
              </Form.List>

              {/* update disable state based on whether there is at least 1 meter id */}
              <Form.Item noStyle shouldUpdate>
                {() =>
                  form.getFieldValue("Meter ID List")
                    ? form.getFieldValue("Meter ID List").length !== 0
                      ? form.getFieldValue("Meter ID List")[0]
                        ? setDisabled(false)
                        : setDisabled(true)
                      : setDisabled(true)
                    : setDisabled(true)
                }
              </Form.Item>

              <Form.Item>
                <Space className="device-registration-btn-layout">
                  <Button
                    className="device-registration-prev-btn-style"
                    onClick={onPrevClick}
                  >
                    Previous
                  </Button>
                  {/* <Button className="device-registration-delete-draft-btn-style">
                    Delete Draft
                  </Button> */}
                  <Button
                    className="device-registration-save-draft-btn-style"
                    onClick={checkDeviceNameEmpty}
                  >
                    {language === INDONESIAN_LOCALE
                      ? "Simpan Draf"
                      : "Save Draft"}
                  </Button>
                  <Tooltip
                    title={
                      disabled
                        ? language === INDONESIAN_LOCALE
                          ? "Anda harus manghantar minimal 1 meter / ID logger."
                          : "You must submit at least 1 meter / logger ID."
                        : ""
                    }
                  >
                    <Button
                      className="device-registration-next-btn-style"
                      htmlType="submit"
                      disabled={disabled}
                    >
                      {language === INDONESIAN_LOCALE ? "Dikirimkan" : "Submit"}
                    </Button>
                  </Tooltip>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    </Form>
  );
}
