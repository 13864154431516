import "../../styles/DeviceManagement.css";
import { useState, useEffect } from "react";
import { BACKEND_URL, FORM_REQUIRED } from "../../config";
import { fetchHelper } from "../../lib/fetch";

import {
  Form,
  Button,
  Input,
  Space,
  DatePicker,
  InputNumber,
  message,
} from "antd";

import dayjs from "dayjs";

export function AdminIssueRequestForm(props) {
  const {
    adminIssueRequestForm,
    deviceSelect,
    isAdminIssueRequestModalOpen,
    setIsAdminIssueRequestModalOpen,
    initialValues,
    mode,
    getRecRecordTableData,
    getPieChartData,
    getRecInventory,
    getYearFilter,
  } = props;

  const [loading, setLoading] = useState(false);

  function addIssueRequestAdmin(rec_record) {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        device_id: deviceSelect,
        start_date: rec_record.StartDate.format("YYYY-MM-DD HH:mm:ss"),
        end_date: rec_record.EndDate.format("YYYY-MM-DD HH:mm:ss"),
        period_production_in_MWh: rec_record.PeriodProductionInMWh,
        rec_notes: rec_record.RecNotes ? rec_record.RecNotes : "",
      }),
    };

    const cb = (data) => {
      if (data.status === true) {
        message.success("Add issue request successfully.");
        adminIssueRequestForm.resetFields();
        setIsAdminIssueRequestModalOpen(false);
        getRecRecordTableData();
        getPieChartData();
        getRecInventory();
        getYearFilter();
      } else {
        message.error("Fail to add issue request.");
      }
    };

    fetchHelper(
      BACKEND_URL + "/device_management/addIssueRequestAdmin",
      cb,
      setLoading,
      "Admin Add Issue Request",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function editIssueRequestAdmin(rec_record) {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        rec_record_id: initialValues[0].ID,
        start_date: rec_record.StartDate.format("YYYY-MM-DD HH:mm:ss"),
        end_date: rec_record.EndDate.format("YYYY-MM-DD HH:mm:ss"),
        period_production_in_MWh: rec_record.PeriodProductionInMWh,
        rec_notes: rec_record.RecNotes ? rec_record.RecNotes : "",
      }),
    };

    const cb = (data) => {
      if (data.status === true) {
        message.success("Save edited issue request successfully.");
        adminIssueRequestForm.resetFields();
        setIsAdminIssueRequestModalOpen(false);
        getRecRecordTableData();
        getPieChartData();
        getRecInventory();
        getYearFilter();
      } else {
        message.error("Fail to save edited issue request.");
      }
    };

    fetchHelper(
      BACKEND_URL + "/device_management/editIssueRequestAdmin",
      cb,
      setLoading,
      "Admin Edit Issue Request",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  const validateDates = ({ getFieldValue }) => ({
    validator(_, value) {
      const startDate = getFieldValue("StartDate");
      const endDate = getFieldValue("EndDate");

      if (!startDate || !endDate) {
        return Promise.resolve();
      }

      if (endDate.isBefore(startDate)) {
        return Promise.reject(
          new Error("End date must be later than start date!")
        );
      }

      if (startDate.year() !== endDate.year()) {
        return Promise.reject(
          new Error("Start date and end date must be within the same year!")
        );
      }

      return Promise.resolve();
    },
  });

  const validateSixDecimalPlaces = (_, value) => {
    if (value !== undefined && value !== null) {
      const decimalPlaces = value.toString().split(".")[1];
      if (decimalPlaces && decimalPlaces.length > 6) {
        return Promise.reject(
          new Error("The value can only have a maximum of 6 decimal places.")
        );
      }
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (mode === "edit") {
      if (initialValues.length) {
        initialValues[0].StartDate = dayjs(initialValues[0].StartDate);
        initialValues[0].EndDate = dayjs(initialValues[0].EndDate);
        adminIssueRequestForm.setFieldsValue(initialValues[0]);
      }
    }
  }, [mode, initialValues]);

  return (
    <Form
      form={adminIssueRequestForm}
      name="admin-issue-request-form"
      onFinish={(values) => {
        if (mode === "add") {
          addIssueRequestAdmin(values);
        } else {
          editIssueRequestAdmin(values);
        }
      }}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item
        label="Start Date"
        name="StartDate"
        rules={[
          {
            required: FORM_REQUIRED,
            message: "Please select start date!",
          },
          validateDates,
        ]}
      >
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        label="End Date"
        name="EndDate"
        rules={[
          {
            required: FORM_REQUIRED,
            message: "Please select end date!",
          },
          validateDates,
        ]}
      >
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        label="Period Production"
        name="PeriodProductionInMWh"
        rules={[
          {
            required: FORM_REQUIRED,
            message: "Please input period production!",
          },
          {
            validator: validateSixDecimalPlaces,
          },
        ]}
      >
        <InputNumber addonAfter={"MWh"} style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item label="Notes" name="RecNotes">
        <Input style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item>
        <Space style={{ float: "right" }}>
          <Button
            onClick={() => {
              setIsAdminIssueRequestModalOpen(false);
              adminIssueRequestForm.resetFields();
            }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
