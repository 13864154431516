import "../../styles/SignUp.css";
import { BACKEND_URL } from "../../config";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Form, message } from "antd";
import Logo from "../../assets/REVEAI.png";
import { CustomSteps } from "../../components/Steps";
import { IndividualUserInfoForm } from "../../components/signup/IndividualUserInfoForm";
import { UserNamePasswordForm } from "../../components/signup/UserNamePasswordForm";
import { SignUpConfirmation } from "../../components/signup/SignUpConfirmation";
import { fetchHelper } from "../../lib/fetch";

function IndividualSignUp() {
  const navigate = useNavigate();

  const [current, setCurrent] = useState(0);

  const [userInfoForm] = Form.useForm();
  const [userNamePasswordForm] = Form.useForm();

  const [userInfoData, setUserInfoData] = useState();
  const [loginDetailsData, setLoginDetailsData] = useState();

  const steps = [
    {
      title: "User Info",
      content: (
        <IndividualUserInfoForm
          form={userInfoForm}
          onFinish={(values) => {
            setUserInfoData(values);
            setCurrent((prev) => prev + 1);
          }}
        />
      ),
    },
    {
      title: "Login Details",
      content: (
        <UserNamePasswordForm
          form={userNamePasswordForm}
          onFinish={(values) => {
            setLoginDetailsData(values);
            setCurrent((prev) => prev + 1);
          }}
          onPrevClick={() => setCurrent((prev) => prev - 1)}
        />
      ),
    },
    {
      title: "Confirmation",
      content: (
        <SignUpConfirmation
          data={[
            {
              title: "User Info",
              value: userInfoData,
            },
            {
              title: "Login Details",
              value: loginDetailsData,
            },
          ]}
          onConfirmClick={() => {
            confirmAddUserInfoModel();
            insertEmailMessage();
          }}
          onPrevClick={() => setCurrent((prev) => prev - 1)}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  function confirmAddUserInfoModel() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        DesignationId: userInfoData["Designation Id"],
        FirstName: userInfoData["First Name"],
        LastName: userInfoData["Last Name"],
        Gender: userInfoData["Gender"],
        NationalityId: userInfoData["Nationality Id"],
        Id:
          userInfoData["Nationality Id"] === 108 //NationalityId=108 is Malaysian
            ? userInfoData["IC"]
            : userInfoData["ID"],
        Email: userInfoData["Email"],
        MobileNo: userInfoData["Mobile No"],
        Username: loginDetailsData["Username"],
        Password: loginDetailsData["Password"],
      }),
    };

    const cb = (data) => {
      if (data.status) {
        navigate("/signup/verification", { replace: true });
      } else {
        message.error(data.error);
      }
    };

    fetchHelper(
      BACKEND_URL + "/addUserInfo",
      cb,
      () => {},
      "Add User Info",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /* API to insert required info to EmailMessage table */
  const insertEmailMessage = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        Email: userInfoData["Email"],
        FirstName: userInfoData["First Name"],
        LastName: userInfoData["Last Name"],
      }),
    };

    const cb = () => {};

    fetchHelper(
      BACKEND_URL + "/insertEmailMessage",
      cb,
      () => {},
      "Insert Email Message",
      () => {},
      () => {},
      requestOptions,
      true
    );
  };

  return (
    <div className="signup-content-grid">
      <div className="logo-image-div signup-top-margin">
        <img src={Logo} style={{ width: "60%" }} alt="signup-logo"></img>
      </div>

      <div className="signup-step-container">
        <CustomSteps current={current} items={items} />
      </div>

      <div className="signup-form-container signup-bottom-margin">
        {steps[current].content}
      </div>
    </div>
  );
}

export default IndividualSignUp;
