import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Divider, Button } from "antd";
import Logo from "../../assets/REVEAI.png";
import { INDONESIAN_LOCALE } from "../../config";

function EmailVerification() {
  let navigate = useNavigate();

  const language = localStorage.getItem("language");
  const [notificationType, setNotificationType] = useState("emailed");

  const textContainerStyle = {
    display: "flex",
    flexDirection: "column",
    lineHeight: 1.5,
    alignItems: "center",
  };
  const titleStyle = {
    fontWeight: 600,
    fontSize: 24,
  };

  const textStyle = {
    fontWeight: 400,
    fontSize: 20,
  };

  return (
    <div className="signup-content-grid">
      <div className="logo-image-div" style={{ marginTop: "40%" }}>
        <img
          src={Logo}
          style={{ width: "60%", height: "30%" }}
          alt="logo"
        ></img>
      </div>
      <div style={{ textAlign: "center" }}>
        {notificationType == "emailed" ? (
          <div style={textContainerStyle}>
            {/* <div style={titleStyle}>A verification link has been sent to your email account.</div>
                            <Divider />
                            <div style={textStyle}>Please click on the link that has just been sent to your email account and continue the registration process.</div> */}
            <div style={titleStyle}>
              {language === INDONESIAN_LOCALE
                ? `Anda telah berhasil mendaftar.`
                : `You have signed up successfully.`}
            </div>
            <Divider />
            <div style={textStyle}>
              {language === INDONESIAN_LOCALE
                ? `Kami akan menghubungi Anda segera. Anda dapat menutup jendela ini.`
                : `We will contact you shortly. You may close this window.`}
            </div>
          </div>
        ) : (
          <div style={textContainerStyle}>
            <div style={titleStyle}>
              {language === INDONESIAN_LOCALE
                ? `Terima kasih, akun Anda telah diverifikasi.`
                : `Thank You, your account have been verified.`}
            </div>
            <Button
              type="primary"
              style={{
                height: 50,
                width: 200,
                backgroundColor: "var(--theme-dark-blue",
                margin: "30px",
              }}
              onClick={() => {
                navigate("/login", { replace: true });
              }}
            >
              {language === INDONESIAN_LOCALE
                ? `Masuk ke akun Anda`
                : `Login to your account`}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default EmailVerification;
