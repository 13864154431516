import { Divider } from "antd";
import Logo from "../assets/REVEAI.png";
import { INDONESIAN_LOCALE } from "../config";

function DeviceRegistrationConfirmation() {
  const language = localStorage.getItem("language");

  const textContainerStyle = {
    display: "flex",
    flexDirection: "column",
    lineHeight: 1.5,
    alignItems: "center",
  };
  const titleStyle = {
    fontWeight: 600,
    fontSize: 24,
  };

  const textStyle = {
    fontWeight: 400,
    fontSize: 20,
  };

  return (
    <div className="signup-content-grid">
      <div className="logo-image-div" style={{ marginTop: "40%" }}>
        <img
          src={Logo}
          style={{ width: "60%", height: "35%" }}
          alt="logo"
        ></img>
      </div>
      <div style={{ textAlign: "center" }}>
        <div style={textContainerStyle}>
          <div style={titleStyle}>
            {language === INDONESIAN_LOCALE
              ? `Registrasi Berhasil`
              : `You have successfully submitted your registration documents.`}
          </div>
          <Divider />
          <div style={textStyle}>
            {language === INDONESIAN_LOCALE
              ? `Kami akan melanjutkan dengan registrasi perangkat. 
            Anda dapat menutup jendela ini`
              : `We will proceed with the registration of the device. 
            You may close this window.`}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceRegistrationConfirmation;
