import "../../styles/Login.css";
import Logo from "../../assets/REVEAI.png";
import { Card, Button, Form, Flex, Select } from "antd";
import { useNavigate } from "react-router-dom";
import {
  FORM_REQUIRED,
  INDONESIAN_LOCALE,
  ENGLISH_UK_LOCALE,
} from "../../config";
import { useEffect } from "react";

function LanguageSelection() {
  let navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div className="login-card-container">
      <Card className="login-card">
        <div className="logo-image-div">
          <img src={Logo} style={{ width: "90%" }} alt="logo"></img>
        </div>
        <Form
          layout="vertical"
          name="language-selection-form"
          autoComplete="off"
          onFinish={() => {
            if (localStorage.getItem("language")) {
              navigate("/login", { replace: true });
            }
          }}
          requiredMark={false}
        >
          <Form.Item
            label="Please select your preferred language."
            name="language"
            rules={[
              {
                required: FORM_REQUIRED,
                message: "Please select your preferred language!",
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="label"
              labelRender={(value) => <>{value.label}</>}
              placeholder="Select language"
              options={[
                {
                  value: ENGLISH_UK_LOCALE,
                  label: "English",
                },
                {
                  value: INDONESIAN_LOCALE,
                  label: "Bahasa Indonesia",
                },
              ]}
              onChange={(value) => {
                localStorage.setItem("language", value);
              }}
            />
          </Form.Item>

          <Form.Item>
            <Flex justify="center">
              <Button
                type="primary"
                htmlType="submit"
                className="language-button"
              >
                Next
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export default LanguageSelection;
