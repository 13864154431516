import "../../styles/DeviceManagement.css";
import { UploadOutlined, InfoCircleOutlined } from "@ant-design/icons";
import ScreenshotGuide from "../../assets/Guideline on how to screenshoot.pdf";
import ExportPlantReportGuide from "../../assets/Guideline on to export Plant Report.pdf";
import SampleGenerationReport from "../../assets/sample_generation_report.csv";
import SampleMonthlyPvYield from "../../assets/sample_monthly_pv_yield.xlsx";
import SampleScreenshot from "../../assets/sample_screenshot.pdf";
import { useState, useEffect, useRef } from "react";
import { BACKEND_URL, FORM_REQUIRED, INDONESIAN_LOCALE } from "../../config";
import { fetchHelper } from "../../lib/fetch";
import { displayErrorNotification } from "../../ui/common/notification";

import {
  Form,
  Button,
  Input,
  Space,
  Tooltip,
  DatePicker,
  InputNumber,
  Upload,
  message,
} from "antd";

import dayjs from "dayjs";

export function UserIssueRequestForm(props) {
  const {
    userIssueRequestForm,
    deviceSelect,
    isUserIssueRequestModalOpen,
    setIsUserIssueRequestModalOpen,
    initialValues,
    mode,
    getRecRecordTableData,
    getPieChartData,
    getRecInventory,
    selectedRecordId,
    getYearFilter,
  } = props;

  const language = localStorage.getItem("language");

  const [loading, setLoading] = useState(false);

  const [deviceOwnership, setDeviceOwnership] = useState("1");

  const [loadingGenerationReport, setLoadingGenerationReport] = useState(false);
  const [
    loadingGenerationReportScreenshot,
    setLoadingGenerationReportScreenshot,
  ] = useState(false);
  const [loadingMonthlyPvYield, setLoadingMonthlyPvYield] = useState(false);
  const [loadingSareBills, setLoadingSareBills] = useState(false);

  const [generationReportBackendFileList, setGenerationReportBackendFileList] =
    useState([]);
  const [uploadingGenerationReportFile, setUploadingGenerationReportFile] =
    useState(null);

  const [
    generationReportScreenshotBackendFileList,
    setGenerationReportScreenshotBackendFileList,
  ] = useState([]);
  const [
    uploadingGenerationReportScreenshotFile,
    setUploadingGenerationReportScreenshotFile,
  ] = useState(null);

  const [monthlyPvYieldBackendFileList, setMonthlyPvYieldBackendFileList] =
    useState([]);
  const [uploadingMonthlyPvYieldFile, setUploadingMonthlyPvYieldFile] =
    useState(null);

  const [sareBillsBackendFileList, setSareBillsBackendFileList] = useState([]);
  const [uploadingSareBillsFile, setUploadingSareBillsFile] = useState(null);

  // Initialise the ref with an empty array
  const uploadFileListRef = useRef([]);

  // Function to add an id to uploadFileListRef list
  const addToList = (id) => {
    // Check if the id already exists in the list
    const exists = uploadFileListRef.current.some((listId) => listId === id);

    if (!exists) {
      // If it does not exist, add the id to the list
      uploadFileListRef.current.push(id);
      // console.log("After adding, Current list:", uploadFileListRef.current);
    }
  };

  // Function to remove an id from uploadFileListRef list
  const removeFromList = (id) => {
    uploadFileListRef.current = uploadFileListRef.current.filter(
      (i) => i !== id
    );
    // console.log("After removing, Current list:", uploadFileListRef.current);
  };

  const beforeUpload = (file) => {
    const isLimit20MB = file.size / 1024 / 1024 < 20;
    if (!isLimit20MB) {
      message.error(
        language === INDONESIAN_LOCALE
          ? "Ukuran file tidak boleh lebih dari 20MB!"
          : "File size must smaller than 20MB!"
      );
    }
    return isLimit20MB;
  };

  // ---------------------------- Upload Generation Report related functions -----------------------------------

  const generationReportFileList = generationReportBackendFileList.map(
    (element) => {
      return {
        uid: element.ID,
        name: element.Name,
        status: element.error ? "error" : "done",
        url: element.URL,
      };
    }
  );
  if (uploadingGenerationReportFile !== null) {
    // To show upload progress
    generationReportFileList.unshift(uploadingGenerationReportFile);
  }

  function getGenerationReportFileList() {
    let params = {
      device_id: deviceSelect,
      rec_record_id: selectedRecordId,
    };

    let url_param = new URLSearchParams(params);

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setGenerationReportBackendFileList(data);
      if (data.length > 0) {
        addToList(data[data.length - 1].ID);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/device_management/generationReportUpload?${url_param}`,
      cb,
      setLoadingGenerationReport,
      "Generation Report Upload",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function uploadGenerationReportFile(file) {
    setLoadingGenerationReport(true);
    let forms = new FormData();

    forms.append("file", file);
    forms.append("device_id", deviceSelect);
    forms.append("rec_record_id", selectedRecordId);

    // Use XmlHttpRequest to be able to show progress
    // The alternative would be to use axios
    const xhr = new XMLHttpRequest();
    xhr.open("POST", BACKEND_URL + "/device_management/generationReportUpload");

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        const percent = (e.loaded / e.total) * 100;
        setUploadingGenerationReportFile({
          name: file.name,
          uid: -1,
          status: "uploading",
          percent: percent,
          url: "",
        });
      }
    };

    xhr.onload = function () {
      if (xhr.status === 200) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil memasukkan data"
            : "Successfully inserted data"
        );
        setUploadingGenerationReportFile(null);
        getGenerationReportFileList();
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal memasukkan data. Tipe file tidak didukung."
            : "Failed to insert data. File type is not supported."
        );
        setUploadingGenerationReportFile(null);
        setLoadingGenerationReport(false);
      }
    };

    xhr.send(forms);
  }

  const customGenerationReportRequest = async ({
    file,
    onSuccess,
    onError,
  }) => {
    try {
      // Your custom logic for uploading the file
      const response = uploadGenerationReportFile(file);

      // Check for successful upload
      if (response.status === 200) {
        onSuccess();
      } else {
        onError("Upload failed");
      }
    } catch (error) {
      onError(error.message || "Upload failed");
    }
  };

  // ---------------------------- Upload Generation Report Screenshot related functions -----------------------------------

  const generationReportScreenshotFileList =
    generationReportScreenshotBackendFileList.map((element) => {
      return {
        uid: element.ID,
        name: element.Name,
        status: element.error ? "error" : "done",
        url: element.URL,
      };
    });
  if (uploadingGenerationReportScreenshotFile !== null) {
    // To show upload progress
    generationReportScreenshotFileList.unshift(
      uploadingGenerationReportScreenshotFile
    );
  }

  function getGenerationReportScreenshotFileList() {
    let params = {
      device_id: deviceSelect,
      rec_record_id: selectedRecordId,
    };

    let url_param = new URLSearchParams(params);

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setGenerationReportScreenshotBackendFileList(data);
      if (data.length > 0) {
        addToList(data[data.length - 1].ID);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/device_management/generationReportScreenshotUpload?${url_param}`,
      cb,
      setLoadingGenerationReportScreenshot,
      "Generation Report Screenshot Upload",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function uploadGenerationReportScreenshotFile(file) {
    setLoadingGenerationReportScreenshot(true);
    let forms = new FormData();

    forms.append("file", file);
    forms.append("device_id", deviceSelect);
    forms.append("rec_record_id", selectedRecordId);

    // Use XmlHttpRequest to be able to show progress
    // The alternative would be to use axios
    const xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      BACKEND_URL + "/device_management/generationReportScreenshotUpload"
    );

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        const percent = (e.loaded / e.total) * 100;
        setUploadingGenerationReportScreenshotFile({
          name: file.name,
          uid: -1,
          status: "uploading",
          percent: percent,
          url: "",
        });
      }
    };

    xhr.onload = function () {
      if (xhr.status === 200) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil memasukkan data"
            : "Successfully inserted data"
        );
        setUploadingGenerationReportScreenshotFile(null);
        getGenerationReportScreenshotFileList();
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal memasukkan data. Tipe file tidak didukung."
            : "Failed to insert data. File type is not supported."
        );
        setUploadingGenerationReportScreenshotFile(null);
        setLoadingGenerationReportScreenshot(false);
      }
    };

    xhr.send(forms);
  }

  const customGenerationReportScreenshotRequest = async ({
    file,
    onSuccess,
    onError,
  }) => {
    try {
      // Your custom logic for uploading the file
      const response = uploadGenerationReportScreenshotFile(file);

      // Check for successful upload
      if (response.status === 200) {
        onSuccess();
      } else {
        onError("Upload failed");
      }
    } catch (error) {
      onError(error.message || "Upload failed");
    }
  };

  // ---------------------------- Upload Monthly PV Yield related functions -----------------------------------

  const monthlyPvYieldFileList = monthlyPvYieldBackendFileList.map(
    (element) => {
      return {
        uid: element.ID,
        name: element.Name,
        status: element.error ? "error" : "done",
        url: element.URL,
      };
    }
  );
  if (uploadingMonthlyPvYieldFile !== null) {
    // To show upload progress
    monthlyPvYieldFileList.unshift(uploadingMonthlyPvYieldFile);
  }

  function getMonthlyPvYieldFileList() {
    let params = {
      device_id: deviceSelect,
      rec_record_id: selectedRecordId,
    };

    let url_param = new URLSearchParams(params);

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setMonthlyPvYieldBackendFileList(data);
      if (data.length > 0) {
        addToList(data[data.length - 1].ID);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/device_management/monthlyPvYieldUpload?${url_param}`,
      cb,
      setLoadingMonthlyPvYield,
      "Monthly PV Yield Upload",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function uploadMonthlyPvYieldFile(file) {
    setLoadingMonthlyPvYield(true);
    let forms = new FormData();

    forms.append("file", file);
    forms.append("device_id", deviceSelect);
    forms.append("rec_record_id", selectedRecordId);

    // Use XmlHttpRequest to be able to show progress
    // The alternative would be to use axios
    const xhr = new XMLHttpRequest();
    xhr.open("POST", BACKEND_URL + "/device_management/monthlyPvYieldUpload");

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        const percent = (e.loaded / e.total) * 100;
        setUploadingMonthlyPvYieldFile({
          name: file.name,
          uid: -1,
          status: "uploading",
          percent: percent,
          url: "",
        });
      }
    };

    xhr.onload = function () {
      if (xhr.status === 200) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil memasukkan data"
            : "Successfully inserted data"
        );
        setUploadingMonthlyPvYieldFile(null);
        getMonthlyPvYieldFileList();
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal memasukkan data. Tipe file tidak didukung."
            : "Failed to insert data. File type is not supported."
        );
        setUploadingMonthlyPvYieldFile(null);
        setLoadingMonthlyPvYield(false);
      }
    };

    xhr.send(forms);
  }

  const customMonthlyPvYieldRequest = async ({ file, onSuccess, onError }) => {
    try {
      // Your custom logic for uploading the file
      const response = uploadMonthlyPvYieldFile(file);

      // Check for successful upload
      if (response.status === 200) {
        onSuccess();
      } else {
        onError("Upload failed");
      }
    } catch (error) {
      onError(error.message || "Upload failed");
    }
  };

  // ---------------------------- Upload SARE Bills related functions -----------------------------------

  const sareBillsFileList = sareBillsBackendFileList.map((element) => {
    return {
      uid: element.ID,
      name: element.Name,
      status: element.error ? "error" : "done",
      url: element.URL,
    };
  });
  if (uploadingSareBillsFile !== null) {
    // To show upload progress
    sareBillsFileList.unshift(uploadingSareBillsFile);
  }

  function getSareBillsFileList() {
    let params = {
      device_id: deviceSelect,
      rec_record_id: selectedRecordId,
    };

    let url_param = new URLSearchParams(params);

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setSareBillsBackendFileList(data);
      if (data.length > 0) {
        addToList(data[data.length - 1].ID);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/device_management/sareBillsUpload?${url_param}`,
      cb,
      setLoadingSareBills,
      "SARE Bills Upload",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function uploadSareBillsFile(file) {
    setLoadingSareBills(true);
    let forms = new FormData();

    forms.append("file", file);
    forms.append("device_id", deviceSelect);
    forms.append("rec_record_id", selectedRecordId);

    // Use XmlHttpRequest to be able to show progress
    // The alternative would be to use axios
    const xhr = new XMLHttpRequest();
    xhr.open("POST", BACKEND_URL + "/device_management/sareBillsUpload");

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        const percent = (e.loaded / e.total) * 100;
        setUploadingSareBillsFile({
          name: file.name,
          uid: -1,
          status: "uploading",
          percent: percent,
          url: "",
        });
      }
    };

    xhr.onload = function () {
      if (xhr.status === 200) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil memasukkan data"
            : "Successfully inserted data"
        );
        setUploadingSareBillsFile(null);
        getSareBillsFileList();
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal memasukkan data. Tipe file tidak didukung."
            : "Failed to insert data. File type is not supported."
        );
        setUploadingSareBillsFile(null);
        setLoadingSareBills(false);
      }
    };

    xhr.send(forms);
  }

  const customSareBillsRequest = async ({ file, onSuccess, onError }) => {
    try {
      // Your custom logic for uploading the file
      const response = uploadSareBillsFile(file);

      // Check for successful upload
      if (response.status === 200) {
        onSuccess();
      } else {
        onError("Upload failed");
      }
    } catch (error) {
      onError(error.message || "Upload failed");
    }
  };

  // ------------------------- Delete function for all files -----------------------------------

  function deleteFile(file) {
    // set loading when file is being deleted
    setLoadingGenerationReport(true);
    setLoadingGenerationReportScreenshot(true);
    setLoadingMonthlyPvYield(true);
    setLoadingSareBills(true);

    fetch(BACKEND_URL + "/issueRequestDocumentsUpload/" + file.uid, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 401) {
          // set to redirect after 3 seconds.
          setTimeout(() => {
            window.location = "/login";
          }, 3000);
        }

        if (response.ok) return response.json();
        else {
          throw new Error(
            `[${"Delete File"}] Error ${response.status} (${
              response.statusText
            }).`
          );
        }
      })
      .then((data) => {
        if ("error" in data) {
          displayErrorNotification(
            "Delete File",
            language === INDONESIAN_LOCALE
              ? "Gagal menghapus file"
              : "Fail to delete file"
          );
        } else {
          message.success(
            language === INDONESIAN_LOCALE
              ? "Berhasil menghapus data"
              : "Successfully deleted data"
          );
          removeFromList(data.ID);
          getGenerationReportFileList();
          getGenerationReportScreenshotFileList();
          getMonthlyPvYieldFileList();
          getSareBillsFileList();
        }

        setLoadingGenerationReport(false);
        setLoadingGenerationReportScreenshot(false);
        setLoadingMonthlyPvYield(false);
        setLoadingSareBills(false);
      });
  }

  function addIssueRequestUser(rec_record) {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        device_id: deviceSelect,
        start_date: rec_record.StartDate.format("YYYY-MM-DD HH:mm:ss"),
        end_date: rec_record.EndDate.format("YYYY-MM-DD HH:mm:ss"),
        period_production_in_MWh: rec_record.PeriodProductionInMWh,
        rec_notes: rec_record.RecNotes ? rec_record.RecNotes : "",
        upload_files_ids: uploadFileListRef.current,
      }),
    };

    const cb = (data) => {
      if (data.status === true) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil tambah permintaan"
            : data.message
        );
        userIssueRequestForm.resetFields();
        setIsUserIssueRequestModalOpen(false);
        getRecRecordTableData();
        getPieChartData();
        getRecInventory();
        getYearFilter();
        uploadFileListRef.current = [];
        getGenerationReportFileList();
        getGenerationReportScreenshotFileList();
        getMonthlyPvYieldFileList();
        getSareBillsFileList();
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal tambah permintaan"
            : data.message
        );
      }
    };

    fetchHelper(
      BACKEND_URL + "/device_management/addIssueRequestUser",
      cb,
      setLoading,
      "User Add Issue Request",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function editIssueRequestUser(rec_record) {
    console.log(rec_record);
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        rec_record_id: initialValues[0].ID,
        start_date: rec_record.StartDate.format("YYYY-MM-DD HH:mm:ss"),
        end_date: rec_record.EndDate.format("YYYY-MM-DD HH:mm:ss"),
        period_production_in_MWh: rec_record.PeriodProductionInMWh,
        rec_notes: rec_record.RecNotes ? rec_record.RecNotes : "",
      }),
    };

    const cb = (data) => {
      if (data.status === true) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil menyunting permintaan"
            : "Save edited issue request successfully."
        );
        userIssueRequestForm.resetFields();
        setIsUserIssueRequestModalOpen(false);
        getRecRecordTableData();
        getPieChartData();
        getRecInventory();
        getYearFilter();
        uploadFileListRef.current = [];
        getGenerationReportFileList();
        getGenerationReportScreenshotFileList();
        getMonthlyPvYieldFileList();
        getSareBillsFileList();
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal menyunting permintaan"
            : "Fail to save edited issue request."
        );
      }
    };

    fetchHelper(
      BACKEND_URL + "/device_management/editIssueRequestUser",
      cb,
      setLoading,
      "User Edit Issue Request",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function saveIssueRequestDraftUser() {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        device_id: deviceSelect,
        rec_record_id: selectedRecordId,
        start_date: userIssueRequestForm.getFieldValue("StartDate")
          ? userIssueRequestForm.getFieldValue("StartDate").format("YYYY-MM-DD")
          : "",
        end_date: userIssueRequestForm.getFieldValue("EndDate")
          ? userIssueRequestForm.getFieldValue("EndDate").format("YYYY-MM-DD")
          : "",
        period_production_in_MWh: userIssueRequestForm.getFieldValue(
          "PeriodProductionInMWh"
        )
          ? userIssueRequestForm.getFieldValue("PeriodProductionInMWh")
          : 0,
        rec_notes: userIssueRequestForm.getFieldValue("RecNotes")
          ? userIssueRequestForm.getFieldValue("RecNotes")
          : "",
        upload_files_ids: uploadFileListRef.current,
        mode: mode,
      }),
    };

    const cb = (data) => {
      if (data.status === true) {
        message.success(
          language === INDONESIAN_LOCALE
            ? "Berhasil menyimpan draft"
            : data.message
        );
        userIssueRequestForm.resetFields();
        setIsUserIssueRequestModalOpen(false);
        getRecRecordTableData();
        getPieChartData();
        getRecInventory();
        getYearFilter();
        uploadFileListRef.current = [];
        getGenerationReportFileList();
        getGenerationReportScreenshotFileList();
        getMonthlyPvYieldFileList();
        getSareBillsFileList();
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal menyimpan draft"
            : data.message
        );
      }
    };

    fetchHelper(
      BACKEND_URL + "/device_management/saveIssueRequestDraftUser",
      cb,
      setLoading,
      "User Save Issue Request",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getDeviceOwnership() {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        device_id: deviceSelect,
      }),
    };

    const cb = (data) => {
      if (data.status === true) {
        setDeviceOwnership(data.device_ownership);
      } else {
        message.error(
          language === INDONESIAN_LOCALE
            ? "Gagal mendapatkan pemilik perangkat"
            : data.message
        );
      }
    };

    fetchHelper(
      BACKEND_URL + "/device_management/getDeviceOwnership",
      cb,
      setLoading,
      "Get Device Ownership",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  const validateDates = ({ getFieldValue }) => ({
    validator(_, value) {
      const startDate = getFieldValue("StartDate");
      const endDate = getFieldValue("EndDate");

      if (!startDate || !endDate) {
        return Promise.resolve();
      }

      if (endDate.isBefore(startDate)) {
        return Promise.reject(
          new Error("End date must be later than start date!")
        );
      }

      if (startDate.year() !== endDate.year()) {
        return Promise.reject(
          new Error("Start date and end date must be within the same year!")
        );
      }

      return Promise.resolve();
    },
  });

  const validateSixDecimalPlaces = (_, value) => {
    if (value !== undefined && value !== null) {
      const decimalPlaces = value.toString().split(".")[1];
      if (decimalPlaces && decimalPlaces.length > 6) {
        return Promise.reject(
          new Error("The value can only have a maximum of 6 decimal places.")
        );
      }
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (mode === "edit") {
      if (initialValues.length) {
        initialValues[0].StartDate = dayjs(initialValues[0].StartDate);
        initialValues[0].EndDate = dayjs(initialValues[0].EndDate);
        userIssueRequestForm.setFieldsValue(initialValues[0]);
      }
    }
  }, [mode, initialValues]);

  useEffect(() => {
    if (selectedRecordId || selectedRecordId === 0) {
      getGenerationReportFileList();
      getGenerationReportScreenshotFileList();
      getMonthlyPvYieldFileList();
      getSareBillsFileList();
    }
  }, [deviceSelect, selectedRecordId]);

  useEffect(() => {
    if (deviceSelect) {
      getDeviceOwnership();
    }
  }, [deviceSelect]);

  return (
    <Form
      form={userIssueRequestForm}
      name="user-issue-request-form"
      onFinish={(values) => {
        if (deviceOwnership) {
          if (deviceOwnership === "1") {
            if (generationReportFileList.length === 0) {
              message.error(
                language === INDONESIAN_LOCALE
                  ? "Silakan unggah laporan pembuatan."
                  : "Please upload generation report."
              );
            }

            if (generationReportScreenshotFileList.length === 0) {
              message.error(
                language === INDONESIAN_LOCALE
                  ? "Silakan unggah tangkapan layar laporan pembuatan halaman web."
                  : "Please upload generation report screenshot."
              );
            }

            if (
              generationReportFileList.length !== 0 &&
              generationReportScreenshotFileList.length !== 0
            ) {
              if (mode === "add") {
                addIssueRequestUser(values);
              } else {
                editIssueRequestUser(values);
              }
            }
          }

          if (deviceOwnership === "2") {
            if (sareBillsFileList.length === 0) {
              message.error(
                language === INDONESIAN_LOCALE
                  ? "Silakan unggah tagihan SARE."
                  : "Please upload sare bills."
              );
            }

            if (sareBillsFileList.length !== 0) {
              if (mode === "add") {
                addIssueRequestUser(values);
              } else {
                editIssueRequestUser(values);
              }
            }
          }
        }
      }}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item
        label={language === INDONESIAN_LOCALE ? "Tanggal Mulai" : "Start Date"}
        name="StartDate"
        rules={[
          {
            required: FORM_REQUIRED,
            message: "Please select start date!",
          },
          validateDates,
        ]}
      >
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        label={language === INDONESIAN_LOCALE ? "Tanggal Akhir" : "End Date"}
        name="EndDate"
        rules={[
          {
            required: FORM_REQUIRED,
            message: "Please select end date!",
          },
          validateDates,
        ]}
      >
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        label={
          language === INDONESIAN_LOCALE
            ? "Produksi Per Tahun"
            : "Period Production"
        }
        name="PeriodProductionInMWh"
        rules={[
          {
            required: FORM_REQUIRED,
            message: "Please input period production!",
          },
          {
            validator: validateSixDecimalPlaces,
          },
        ]}
      >
        <InputNumber addonAfter={"MWh"} style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        label={language === INDONESIAN_LOCALE ? "Catatan" : "Notes"}
        name="RecNotes"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>

      {deviceOwnership === "1" && (
        <div style={{ marginBottom: "25px" }}>
          <div className="form-item-required">
            <p style={{ color: "red" }}>*&nbsp;</p>
            <p>
              {language === INDONESIAN_LOCALE
                ? "Laporan Pembangkitan Listrik untuk Periode Tersebut"
                : "Generation Report for the Period"}
              &nbsp;
            </p>
            <Tooltip
              title={
                language === INDONESIAN_LOCALE ? (
                  <div>
                    <h5>
                      File CV laporan harian. Lihat{" "}
                      <a
                        href={SampleGenerationReport}
                        download="sample_generation_report.csv"
                      >
                        contoh
                      </a>{" "}
                      di sini. Anda dapat merujuk ke{" "}
                      <a
                        href={ExportPlantReportGuide}
                        download="Guideline on to export Plant Report.pdf"
                      >
                        panduan ini
                      </a>{" "}
                      tentang cara mengunduh laporan. Ukuran file maksimum yang
                      diizinkan adalah 20 MB. Jumlah file maksimum yang dapat
                      Anda unggah adalah 24. Jika jumlah file maksimum telah
                      tercapai, Anda perlu menghapus file yang diunggah dan baru
                      dapat mengunggahnya lagi.
                    </h5>
                  </div>
                ) : (
                  <div>
                    <h5>
                      Cvs file of daily generation report. See{" "}
                      <a
                        href={SampleGenerationReport}
                        download="sample_generation_report.csv"
                      >
                        example
                      </a>{" "}
                      here. You may refer to{" "}
                      <a
                        href={ExportPlantReportGuide}
                        download="Guideline on to export Plant Report.pdf"
                      >
                        this guide
                      </a>{" "}
                      on how to download the report. The maximum file size
                      allowed is 20MB. The maximum number of file you can upload
                      is 24. If the maximum number of file has reached, you need
                      to delete the uploaded file then only you can upload
                      again.
                    </h5>
                  </div>
                )
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
          <Upload
            beforeUpload={beforeUpload}
            name="plant-report-data"
            fileList={generationReportFileList}
            customRequest={customGenerationReportRequest}
            onRemove={deleteFile}
            onChange={(info) => {
              if (info.file.status !== "uploading") {
                console.log(info.file, info.fileList);
              }
              if (info.file.status === "done") {
                message.success(
                  language === INDONESIAN_LOCALE
                    ? `File ${info.file.name} berhasil diunggah`
                    : `${info.file.name} file uploaded successfully`
                );
              } else if (info.file.status === "error") {
                message.error(
                  language === INDONESIAN_LOCALE
                    ? `File ${info.file.name} gagal diunggah`
                    : `${info.file.name} file upload failed.`
                );
              }
            }}
          >
            <Tooltip
              title={
                uploadingGenerationReportFile !== null ||
                generationReportFileList.length === 24 ? (
                  <h5>
                    {language === INDONESIAN_LOCALE
                      ? `Jumlah maksimum file yang dapat Anda unggah adalah 24. Anda perlu melakukannya
                    hapus file yang diupload maka hanya anda yang bisa mengupload lagi.`
                      : `The maximum number of file you can upload is 24. You need to
                    delete the uploaded file then only you can upload again.`}
                  </h5>
                ) : null
              }
            >
              <Button
                style={{ width: "100%" }}
                icon={<UploadOutlined />}
                disabled={
                  uploadingGenerationReportFile !== null ||
                  generationReportFileList.length === 24
                }
              >
                {language === INDONESIAN_LOCALE
                  ? "Klik untuk Unggah"
                  : "Click to Upload"}
              </Button>
            </Tooltip>
          </Upload>
        </div>
      )}

      {deviceOwnership === "1" && (
        <div style={{ marginBottom: "25px" }}>
          <div className="form-item-required">
            <p style={{ color: "red" }}>*&nbsp;</p>
            <p>
              {language === INDONESIAN_LOCALE
                ? "Tangkapan Layar Halaman Web Laporan Pembangkitan Listrik"
                : "Screenshots of Webpage Generation Report"}
              &nbsp;
            </p>
            <Tooltip
              title={
                language === INDONESIAN_LOCALE ? (
                  <div>
                    <h5>
                      Berkas PDF berisi tangkapan layar laporan pembuatan
                      harian. Lihat{" "}
                      <a
                        href={SampleScreenshot}
                        download="sample_screenshot.pdf"
                      >
                        contoh
                      </a>{" "}
                      di sini. Anda dapat merujuk ke{" "}
                      <a
                        href={ScreenshotGuide}
                        download="Guideline on how to screenshoot.pdf"
                      >
                        panduan ini
                      </a>{" "}
                      tentang cara mengambil tangkapan layar. Ukuran berkas
                      maksimum yang diizinkan adalah 20 MB. Jumlah berkas
                      maksimum yang dapat diunggah adalah 24. Jika jumlah berkas
                      maksimum telah tercapai, Anda perlu menghapus berkas yang
                      diunggah, baru kemudian Anda dapat mengunggahnya lagi.
                    </h5>
                  </div>
                ) : (
                  <div>
                    <h5>
                      PDF file of screenshots of daily generation report. See{" "}
                      <a
                        href={SampleScreenshot}
                        download="sample_screenshot.pdf"
                      >
                        example
                      </a>{" "}
                      here. You may refer to{" "}
                      <a
                        href={ScreenshotGuide}
                        download="Guideline on how to screenshoot.pdf"
                      >
                        this guide
                      </a>{" "}
                      on how to take the screenshot. The maximum file size
                      allowed is 20MB. The maximum number of file you can upload
                      is 24. If the maximum number of file has reached, you need
                      to delete the uploaded file then only you can upload
                      again.
                    </h5>
                  </div>
                )
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
          <div>
            <Upload
              beforeUpload={beforeUpload}
              name="plant-report-screenshot"
              fileList={generationReportScreenshotFileList}
              customRequest={customGenerationReportScreenshotRequest}
              onRemove={deleteFile}
              onChange={(info) => {
                if (info.file.status !== "uploading") {
                  console.log(info.file, info.fileList);
                }
                if (info.file.status === "done") {
                  message.success(
                    language === INDONESIAN_LOCALE
                      ? `File ${info.file.name} berhasil diunggah`
                      : `${info.file.name} file uploaded successfully`
                  );
                } else if (info.file.status === "error") {
                  message.error(
                    language === INDONESIAN_LOCALE
                      ? `File ${info.file.name} gagal diunggah`
                      : `${info.file.name} file upload failed.`
                  );
                }
              }}
            >
              <Tooltip
                title={
                  uploadingGenerationReportScreenshotFile !== null ||
                  generationReportScreenshotFileList.length === 24 ? (
                    <h5>
                      {language === INDONESIAN_LOCALE
                        ? `Jumlah maksimum file yang dapat Anda unggah adalah 24. Anda memerlukannya
                      untuk menghapus file yang diunggah maka hanya Anda yang dapat mengunggah
                      lagi.`
                        : `The maximum number of file you can upload is 24. You need
                      to delete the uploaded file then only you can upload
                      again.`}
                    </h5>
                  ) : null
                }
              >
                <Button
                  style={{ width: "100%" }}
                  icon={<UploadOutlined />}
                  disabled={
                    uploadingGenerationReportScreenshotFile !== null ||
                    generationReportScreenshotFileList.length === 24
                  }
                >
                  {language === INDONESIAN_LOCALE
                    ? "Klik untuk Unggah"
                    : "Click to Upload"}
                </Button>
              </Tooltip>
            </Upload>
          </div>
        </div>
      )}

      {deviceOwnership === "1" && (
        <div style={{ marginBottom: "25px" }}>
          <div className="form-item-required">
            <p>
              {language === INDONESIAN_LOCALE
                ? "Hasil Energi PV Bulanan"
                : "Monthly PV Yield Energy"}
              &nbsp;
            </p>
            <Tooltip
              title={
                language === INDONESIAN_LOCALE ? (
                  <div>
                    <h5>
                      File Excel hasil PV bulanan. Anda perlu menghitung hasil
                      secara manual. Lihat{" "}
                      <a
                        href={SampleMonthlyPvYield}
                        download="sample_monthly_pv_yield.xlsx"
                      >
                        contoh
                      </a>{" "}
                      di sini. Ukuran file maksimum yang diizinkan adalah 20MB.
                      Jumlah file maksimum yang dapat Anda unggah adalah 24.
                      Jika jumlah file maksimum telah tercapai, Anda perlu
                      menghapus file yang diunggah, baru kemudian Anda dapat
                      mengunggahnya lagi. Anda tidak diharuskan untuk
                      menyerahkan dokumen ini jika penerbitannya hanya untuk
                      satu bulan.
                    </h5>
                  </div>
                ) : (
                  <div>
                    <h5>
                      Excel file of monthly PV yield. You need to manually
                      calculate the yield. See{" "}
                      <a
                        href={SampleMonthlyPvYield}
                        download="sample_monthly_pv_yield.xlsx"
                      >
                        example
                      </a>{" "}
                      here. The maximum file size allowed is 20MB. The maximum
                      number of file you can upload is 24. If the maximum number
                      of file has reached, you need to delete the uploaded file
                      then only you can upload again. You are not required to
                      submit this document if the issuance is for one month
                      only.
                    </h5>
                  </div>
                )
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
          <div>
            <Upload
              beforeUpload={beforeUpload}
              name="monthly-pv-yield"
              fileList={monthlyPvYieldFileList}
              customRequest={customMonthlyPvYieldRequest}
              onRemove={deleteFile}
              onChange={(info) => {
                if (info.file.status !== "uploading") {
                  console.log(info.file, info.fileList);
                }
                if (info.file.status === "done") {
                  message.success(
                    language === INDONESIAN_LOCALE
                      ? `File ${info.file.name} berhasil diunggah`
                      : `${info.file.name} file uploaded successfully`
                  );
                } else if (info.file.status === "error") {
                  message.error(
                    language === INDONESIAN_LOCALE
                      ? `File ${info.file.name} gagal diunggah`
                      : `${info.file.name} file upload failed.`
                  );
                }
              }}
            >
              <Tooltip
                title={
                  uploadingMonthlyPvYieldFile !== null ||
                  monthlyPvYieldFileList.length === 24 ? (
                    <h5>
                      {language === INDONESIAN_LOCALE
                        ? `Jumlah maksimum file yang dapat Anda unggah adalah 24. Anda memerlukannya
                      untuk menghapus file yang diunggah maka hanya Anda yang dapat mengunggah
                      lagi.`
                        : `The maximum number of file you can upload is 24. You need
                      to delete the uploaded file then only you can upload
                      again.`}
                    </h5>
                  ) : null
                }
              >
                <Button
                  style={{ width: "100%" }}
                  icon={<UploadOutlined />}
                  disabled={
                    uploadingMonthlyPvYieldFile !== null ||
                    monthlyPvYieldFileList.length === 24
                  }
                >
                  {language === INDONESIAN_LOCALE
                    ? "Klik untuk Unggah"
                    : "Click to Upload"}
                </Button>
              </Tooltip>
            </Upload>
          </div>
        </div>
      )}

      {/* deviceOwnership === "2" means device ownership is PPA, if PPA, need to upload SARE bills and not others */}
      {deviceOwnership === "2" && (
        <div style={{ marginBottom: "25px" }}>
          <div className="form-item-required">
            <p style={{ color: "red" }}>*&nbsp;</p>
            <p>
              {language === INDONESIAN_LOCALE ? "Tagihan SARE" : "Sare Bills"}
              &nbsp;
            </p>
            <Tooltip
              title={
                <h5>
                  {language === INDONESIAN_LOCALE
                    ? `Tagihan SARE Terbaru. Ukuran file maksimum yang diperbolehkan adalah 20 MB. Itu
                  maksimal jumlah file yang bisa diupload adalah 12. Jika maksimal
                  jumlah file telah tercapai, Anda perlu menghapus file yang diunggah
                  file maka hanya Anda yang dapat mengunggah lagi.`
                    : `Latest SARE Bills. The maximum file size allowed is 20MB. The
                  maximum number of file you can upload is 12. If the maximum
                  number of file has reached, you need to delete the uploaded
                  file then only you can upload again.`}
                </h5>
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
          <Upload
            beforeUpload={beforeUpload}
            name="sare-bills"
            fileList={sareBillsFileList}
            customRequest={customSareBillsRequest}
            onRemove={deleteFile}
            onChange={(info) => {
              if (info.file.status !== "uploading") {
                console.log(info.file, info.fileList);
              }
              if (info.file.status === "done") {
                message.success(
                  language === INDONESIAN_LOCALE
                    ? `File ${info.file.name} berhasil diunggah`
                    : `${info.file.name} file uploaded successfully`
                );
              } else if (info.file.status === "error") {
                message.error(
                  language === INDONESIAN_LOCALE
                    ? `File ${info.file.name} gagal diunggah`
                    : `${info.file.name} file upload failed.`
                );
              }
            }}
          >
            <Tooltip
              title={
                uploadingSareBillsFile !== null ||
                sareBillsFileList.length === 12 ? (
                  <h5>
                    {language === INDONESIAN_LOCALE
                      ? `Jumlah maksimum file yang dapat Anda unggah adalah 12. Anda perlu melakukannya
                    hapus file yang diupload maka hanya anda yang bisa mengupload lagi.`
                      : `The maximum number of file you can upload is 12. You need to
                    delete the uploaded file then only you can upload again.`}
                  </h5>
                ) : null
              }
            >
              <Button
                style={{ width: "100%" }}
                icon={<UploadOutlined />}
                disabled={
                  uploadingSareBillsFile !== null ||
                  sareBillsFileList.length === 12
                }
              >
                {language === INDONESIAN_LOCALE
                  ? "Klik untuk Unggah"
                  : "Click to Upload"}
              </Button>
            </Tooltip>
          </Upload>
        </div>
      )}

      <Form.Item>
        <Space style={{ float: "right" }}>
          <Button
            onClick={() => {
              setIsUserIssueRequestModalOpen(false);
              userIssueRequestForm.resetFields();

              // delete file if user clicks 'Cancel' button
              if (selectedRecordId === 0) {
                if (uploadFileListRef.current.length !== 0) {
                  for (let i = 0; i < uploadFileListRef.current.length; i++) {
                    deleteFile({ uid: uploadFileListRef.current[i] });
                  }
                }
              }
            }}
          >
            {language === INDONESIAN_LOCALE ? "Batal" : "Cancel"}
          </Button>
          <Button
            onClick={() => {
              saveIssueRequestDraftUser();
            }}
          >
            {language === INDONESIAN_LOCALE ? "Simpan Draf" : "Save as Draft"}
          </Button>
          <Button type="primary" htmlType="submit">
            {language === INDONESIAN_LOCALE ? "Dikirimkan" : "Submit"}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
